.flowing-ui--chat-message-viewer {
  $padding-x: 15px;
  $padding-y: 7px;

  position: absolute;
  z-index: 400;
  inset: 0;
  display: flex;
  background-color: rgba(0,0,0,0.9);
  color: #fff;

  flex-direction: column;

  &__header {
    flex-grow: 0;
    padding: $padding-y $padding-x;
    display: flex;
    justify-content: space-between;

    .title {
      line-height: 1;
    }

    .date-view {
      line-height: 1;
      small {
        line-height: 1;
        font-size: 0.75em;
      }
    }

    .icon-btn {
      color: #fff;
      border: none;
      background-color: transparent;
      padding: 4px 3px ;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;

    padding: $padding-y $padding-x;

    .image-view-container {
      height: 100%;
      width: 100%;
    }

    .original-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__footer {
    flex-grow: 0;
    padding: $padding-y $padding-x;
  }
}