.slick-slider.flowing-ui--main-slick-list {
  .slick-prev, .slick-next {
    z-index: 100;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.07);

    &:focus {
      background-color: #fff;
    }
  }

  .slick-list {
    margin-left: -20px;
    margin-right: -20px;
  }

  .slick-slide {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 1000px) {
    .slick-prev, .slick-next {
      width: 32px;
      height: 32px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    }

    .slick-prev {
      left: -20px !important;
    }

    .slick-next {
      right: -20px !important;
    }
  }
}