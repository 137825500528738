@media (max-width: 740px) {
    .chat-side-bar-dashboard {
        width: 100% !important;
        height: 75vh !important;
        bottom: 0px !important;
        margin-right: 0px !important;
    }

    .chat-side-bar-dashboard .viewContents {
        height: 100%;
    }

    .chat-side-bar-dashboard .answerInfo {
        padding: 20px !important;
    }

}

.chat-side-bar-dashboard .txtArea2 {
    display: block !important;
}