/*========= theme-btn ========*/
.theme-btn {
    background-color: $theme-color-2;
    color: $white;
    font-weight: $fw-medium;
    padding-right: 18px;
    padding-left: 18px;
    line-height: 30px;
    font-size: $fs-15;
    position: relative;

    &:focus {
        @include box-shadow(none);
    }

    &:hover {
        background-color: $theme-color-3;
        color: $white;

        .icon {
            padding-left: 5px;
        }
    }
}

.icon {
    @include transition(0.3s);
}

/*===== theme-btn-gray ======*/
.theme-btn-gray {
    background-color: rgba(128, 137, 150, 0.08);
    color: $theme-color;

    &:hover {
        background-color: rgba(128, 137, 150, 0.15);
        color: $theme-color;
    }
}

.btn-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 45px;
    height: 100%;
    background-color: rgba(128, 137, 150, 0.20);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-btn {
    background-color: rgba(244, 65, 79, 0.08);
    color: $color-10;

    .btn-icon {
        background-color: rgba(244, 65, 79, 0.20);
    }

    &:hover {
        background-color: rgba(244, 65, 79, 0.15);
        color: $color-10;
    }
}

.facebook-btn {
    background-color: rgba(56, 84, 153, 0.08);
    color: $color-8;

    .btn-icon {
        background-color: rgba(56, 84, 153, 0.20);
    }

    &:hover {
        background-color: rgba(56, 84, 153, 0.15);
        color: $color-8;
    }
}

.twitter-btn {
    background-color: rgba(57, 161, 242, 0.08);
    color: $color-9;

    .btn-icon {
        background-color: rgba(57, 161, 242, 0.20);
    }

    &:hover {
        background-color: rgba(57, 161, 242, 0.15);
        color: $color-9;
    }
}

/*===== theme-btn-outline ======*/
.theme-btn-outline {
    background-color: transparent;
    color: $theme-color-2;
    @include box-shadow(none);
    border: 1px solid $theme-color-2;

    &:hover {
        background-color: $theme-color-2;
        border-color: $theme-color-2;
        color: $white;
    }
}

.theme-btn-outline-gray {
    border-color: rgba(127, 136, 151, 0.2);
    color: $theme-color-4;
}

.theme-btn-outline-white {
    border-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.7);
}

/*===== theme-btn-sm ======*/
.theme-btn-sm {
    line-height: 23px;
    padding-right: 14px;
    padding-left: 14px;
    font-size: $fs-14;
}

/*===== theme-btn-white ======*/
.theme-btn-white {
    background-color: $white;
    color: $theme-color;
    @include box-shadow(0 0 5px rgba(0, 0, 0, 0.1));

    &:hover {
        background-color: $theme-color-2;
    }
}

/*==== collapse-btn =====*/
.collapse-btn {
    color: $theme-color-4;
    @include transition(0.3s);

    &.theme-btn-outline {
        color: $theme-color-2;

        &:hover {
            color: $white;
        }
    }

    &:hover {
        color: $theme-color-2;
    }

    &[aria-expanded="true"] {
        .collapse-btn-show {
            display: inline-block;
        }

        .collapse-btn-hide {
            display: none;
        }
    }

    &[aria-expanded="false"] {
        .collapse-btn-show {
            display: none;
        }
    }
}

/*======== btn-text ========*/
.btn-text {
    color: $theme-color-4;
    font-weight: $fw-medium;
    @include transition(0.2s);
    font-size: $fs-15;

    &:hover {
        color: $theme-color-2;

        .icon {
            padding-left: 5px;
        }
    }
}

/*===== btn--group =====*/
.btn--group {
    .btn {
        border-color: rgba(128, 137, 150, 0.3);
        color: $theme-color-4;
        font-size: $fs-15;

        &.focus,
        &:focus {
            @include box-shadow(none);
        }

        &.active {
            background-color: $theme-color-2;
            color: $white;
            border-color: $theme-color-2;
        }

        &:hover:not(.active) {
            background-color: rgba(128, 137, 150, 0.07);
        }
    }
}

.btn[disabled] {
    opacity: .5;
    pointer-events: none;
}

.hover-bg-gray {
    &:hover {
        background-color: rgba(128, 137, 150, 0.1);
    }
}