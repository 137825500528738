.me-modal {
    background-color: white;
    width: 250px;
    height: 140px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #757575;
    border-radius: 5px;
}

.me-modal_content {
    display: block;
    text-align: center;
    margin: 0 50px;
}

.me-modal_content p {
    padding-top: 10px;
}

.pick-image {
    width: 100px;
    margin-top: -60px;
}

.table-custom-styled thead tr {
    background: #fff !important;
}

.table-custom-styled th {
    color: #6c757d !important;
    font-weight: 600;
}

.useList-dropdown .dropdown-toggle {
    width: 100%;
    text-align: left;
    background-color: #fff !important;
    color: #6c757d !important;
    border-radius: 12px;
    box-shadow: none;
    border-color: rgba(127, 136, 151, 0.2);
    padding-left: 14px;
    height: 48px;
}

.useList-dropdown .dropdown-menu {
    width: 100%;
}

.useList-dropdown .dropdown-toggle:hover {
    background-color: #fff;
    color: #6c757d !important;
}

.useList-dropdown .dropdown-toggle:focus {
    background-color: #fff;
    color: #6c757d !important;
}

.useList-dropdown .dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 50%;
}

.useList-dropdown .dropdown {
    width: 100%;
}