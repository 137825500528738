/* dashboard slicker button customizing */
.dashboard-slicker-customize .slick-next {
    color: black;
    background-color: rgb(255 255 255);
    border-radius: 30px;
    /* height: 30px;
    width: 30px; */
    margin-right: -50px;
}

.dashboard-slicker-customize .slick-prev {
    color: black;
    background-color: rgb(255 255 255);
    border-radius: 30px;
    /* height: 30px;
    width: 30px; */
    margin-left: -50px;
}
.slick-prev, 
.slick-next{
    width:60px;
    height:60px;
    background:#fff;
    border-radius: 50%;
}
.slick-prev:before,
.slick-next:before {
    font-family: "FontAwesome";
    font-weight: 400;
    color:#666;
}
.slick-prev{
    left:-65px !important;
}
.slick-next{
    right:-65px !important;
}
.slick-prev:before{
    content:'\f053' !important;
}
.slick-next:before{
    content:'\f054' !important;
}
.slick-prev.slick-disabled, 
.slick-next.slick-disabled,
.slick-prev:hover,
.slick-next:hover{
    background:#fff;
}

@media (min-width: 991px) {
    .slider-customize-height {
        height: 93px !important;
    }
}

@media (max-width: 579px) {
    .slider-customize-height {
        height: 180px;
    }
}

@media (min-width: 580) {
    .slider-customize-height {
        height: 350px;
    }
}

@media (max-width: 768px) {
    .reply-area .noflex a {
        width: 100%;
    }
}