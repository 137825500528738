

.invoice-document {
  position: relative;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 30px;

  box-shadow: 0 0 25px rgba(0,0,0,0.15);

  font-size: 14px;

  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-image: linear-gradient(45deg, #fff 25%, #01a0fc 0, #01a0fc 50%, #fff 0, #fff 75%, #01a0fc 0);
    background-size: 30px 30px;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    z-index: 999;
  }

  &__title {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 24px;
    font-weight: 300;

    b {
      font-weight: 700;
    }
    padding: .5em 0;
  }

  &__box {
    align-items: center;
    justify-content: center;

    display: flex;
    flex-wrap: wrap;

    .article {
      @media (max-width: 768px) {
        flex: 0 0 100%;
      }
    }
  }

  &__detail {
    list-style: none;

    > li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 1px solid #ebebeb;

      padding: 0.2em 0.1em;
      flex-wrap: wrap;

      &:last-of-type {
        border-bottom: none;
      }

      dt {
        color: black;
        font-weight: normal;
        padding-right: 1.5em;
      }
      dd {
        color: #6c757d;
        margin: 0;
      }

      @media (max-width: 400px) {
        dt, dd {
          flex: 0 0 100%;
        }
      }
    }
  }

  .image-area {
    display: flex;
    flex-grow: 0;

    align-items: center;
    justify-content: center;
    padding: 10px 40px;

    > div {
      position: relative;
      min-width: 230px;
      min-height: 230px;
    }
  }

  .information-area {
    flex-grow: 1;
  }
}


.invoice-doc {
  $blit-width: 34px;
  $blit-spacing: 13px;

  border-radius: 8px;
  background-color: #fff;
  padding: 35px 26px;
  box-shadow: 0 1px 0 rgba(0,0,0,0.08);

  &__title {
    display: flex;
    align-items: center;

    .blit {
      width: $blit-width;
      height:29px;
      margin-right: $blit-spacing;
    }

    .title-text {
      font-size: 19px;
    }
  }

  &__info {
    padding-top: 26px;
    padding-left: calc(#{$blit-width} + #{$blit-spacing});

    font-size: 14px;
    color: #090909;

    li {
      &:before {
        content: '-';
        position: absolute;
        left: 0;
      }

      padding-left: 1em;
      position: relative;
    }
  }
}