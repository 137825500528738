.dialog-Message-modal {
  .btn {
    border-color: transparent;
    padding-left: 18px;
    padding-right: 18px;
    line-height: 30px;

    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 300;

    border-radius: 12px;
  }

  .modal-content {
    border-radius: 18px;
  }

  &__close {
    position: absolute;
    top: 0.1em;
    right: 0.5em;
    border: none;
    width: 1em;
    height: 1em;
    padding: 0;
    font-size: 1.5em;
    background-color: transparent;
    cursor: pointer;
  }

  .modal-header {
    border-bottom: none;
    justify-content: center;
  }

  .modal-body {
    text-align: center;
  }

  .modal-footer {
    justify-content: center;
    text-align: center;
    border: none;
  }
}