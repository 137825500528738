.chat-container .message-content {
  .image-container {
    margin: 7px 3px;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    aspect-ratio: var(--v-aspect-ratio, 1);

    &.clickable {
      cursor: pointer;
    }

    img {
      max-width: 210px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .video-screen {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__control-button {
      border: none;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      width: 35px;
      height: 35px;
      line-height: 1;
    }
  }
}