$chat-themes: (
  "default" #fff #cfd9e9 #d7ffd9,
  "green" #4eac6d #e2ebe5 #c0ddc9,
  "light-blue" #50a5f1 #e2eaf2 #c1daf2,
  "indigo" #6153cc #e4e2ee #c5c1e6,
  "pink" #e83e8c #f1e0e8 #f0bdca,
  "grey" #797c8c #e6e6e8 #cdced2
);
.dropdown-item {
  width: 100% !important;
  margin: 0 !important;
  text-align: center !important;
}
.chat-container {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
@media (max-width: 740px) {
  .chat-customize {
  right:0px !important;
  bottom:0px !important;
  width:100% !important;
  height: 100% !important;
    .chat-customized-header:first-child{
      margin-top: 80px;
    }
    .chat-body{
      height: 75vh !important;
    }
    .video_container{
      display: inline;
    }
  }
}
@media(min-width:741px){
  .chat-customize{
    bottom:0px !important;

    right: 10px;
    border-radius: 20px;
  }
}
.ChatOption {
  background: #add9fe;
  padding: 5px 10px;
  color:#222;
}

.chat-body-message-right {
  text-align: right;
}

.chat-righted-text {
  text-align: left;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
}

.chat-lefted-text {
  text-align: left;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
  max-width: 250px;
}
.chat-center-text {
  text-align: center;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
  max-width: 250px;
  color: white;
}
.message-righted {
  margin-right: 5px;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
}

.message-lefted {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
}
.area-winner {
  position: absolute;
  width: 100%;
  height: 230px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding: 20px;
  .winner {
    background: rgb(248, 245, 245);
    height: 100%;
    border-radius: 5px;
    .winner-sticker-header {
      padding-bottom: 0px !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 10px;
      .close-button-chat {
        border-radius: 10px;
        background-color: rgb(233, 227, 227);
        color: black;
      }
      .span-close-style {
        color: white;
      }
    }
    .winner-sticker-container {
      color: rgb(20, 20, 20);
      height: 100%;
      padding: 0px 15px;
      .top-header {
        margin-top: -10px;
        color: black;
      }
      .article-sticker {
        cursor: pointer;
        padding-left: 5px;
      }
    }
  }
}

.chat-body-message-content-text {
  margin-left:10px;
  // margin: 5px;
  // padding: 20px;
  margin-bottom: -15px;
  padding-right: 0;
}

.chat-body-message-content-text .chat-righted-text {
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  font-size: 12px;
  line-height: 15px;
  max-width: 250px;
}

.chat-body-message-content-text .chat-lefted-text {
  background-color: white !important;
  border-radius: 10px;
  padding:12px 14px;
  margin:7px 0 3px;
  font-size: 13px;
  line-height: 15px;
  max-width: 250px;
  color:#222;
  overflow: visible;
}
.chat-body-message-content-text .chat-center-text {
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 80%;
  font-weight: bold;
  line-height: 15px;
  max-width: 250px;
}
.my-message .tool_tip {
  right: 100%;
}

.tool_tip {
  display: inline-block;
  background-color: #757575;
  border-radius: 10px !important;
}

.tool_tip button {
  background-color: #757575;
  border-width: 0px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 10px;
  padding: 5px 15px;
  width: max-content;
}

.avatar .tool_tip button {
  display: grid;
}

.avatar .tool_tip i {
  margin-top: 5px;
}

.other-message .avatar {
  background-color: white;
}

.tool_tip .left {
  padding: 5px 20px;
}

.tool_tip .right {
  padding: 5px 20px;
}

.tool_tip p {
  font-weight: 400 !important;
  color: white;
  font-size: 12px;
  margin: 0px !important;
  padding: 0px !important;
}

.avatar .tool_tip p {
  width: 50px;
}

.tool_tip i {
  color: white;
  font-weight: 400 !important;
  font-size: 25px !important;
}
.chat-lefted-image {
  background-color: transparent;
  img {
    width: 150px;
    height: auto;
    border-radius: 5px;
  }
}

.video-player {
  display: inline-flex;
  align-items: flex-end;
}
.chat-body-message-left {
  .chat-body-message-content-text {
    height: auto;
    display: grid;
  }
  .preview_content {
    float: left;
  }
}
.chat-body-message-right .chat-body-message-content-text {
  text-align: left;
  height: auto;
  display: grid;
  justify-content: right;
  .preview_data_container {
    display: grid;
    justify-content: flex-end;
  }
  .preview_content {
    float: right;
  }
}
@mixin square($size) {
  width: $size;
  height: $size;
}
.dropdown-toggle-button {
  box-shadow: none !important;
  padding: 0px;
  max-width: 40px;
}
.button-bells::after {
  font-size: 20px;
  color: grey;
  border-top: none !important;
  margin-left: 0px;
  margin-right: 0px;
  content: "" !important;
}
.button-dots::after {
  content: "\2807" !important;
  font-size: 20px;
  color: grey;
  border-top: none !important;
  margin-left: 0px;
  margin-right: 0px;
}
.image-and-video {
  padding: 25px;
}
.chat-customize {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 1500;
  width: 369px;
  height: 656px;
  background: #e3ebf2;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  p {
    font-size: 12px;
    line-height: 15px;
  }
  .tool_tip-owner {
    position: absolute;
    right: 0px;
  }
  .tool_tip {
    position: absolute;
    top: -75px;
    background-color: #757575;
    border-radius: 10px !important;
    height: 70px;
    display: flex;
    z-index: 1;
  }
  .tool_tip_right {
    right: 0px;
  }
  .message-content {
    position: relative;
  }
  .avatar-border {
    border: 1px solid #cfcfcf;
  }
  .block-user-content {
    padding: 20px;
  }
  .preview_data_container {
    border-radius: 5px;
    margin-bottom: 0px;
  }
  .viewContents {
    height: auto;
  }
  .preview_content {
    width: 150px;
    display: grid;
    .preview_data {
      width: 150px;
      font-size: 12px;
      line-height: 17px;
      padding: 0.25rem;
      padding: 5px;
      padding-left: 3px;
      padding-right: 3px;
      margin-bottom: 0px;
      border-bottom: 1px solid #eee;
      border-right: 1px solid #eee;
      border-left: 1px solid #eee;
      .title {
        text-align: left;
        color: black;
      }
      .desc {
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #9e9e9e;
      }
      .link {
        text-align: left;
        font-size: 12px;
        color: #9e9e9e;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .file-content {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    .file-container {
      width: 220px;
      text-align: center;
      height: 60px;
      border: 1px solid #eee;
      border-radius: 5px;
      justify-content: left;
      display: flex;
      align-items: center;
      background-color: white;
      .w50 {
        width: 50px;
      }
      span {
        width: 160px;
        line-break: anywhere;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  .dropdown-menu {
    z-index: 1001;
  }
  .owner-badge-image {
    position: absolute;
    top: 48%;
    left: 70%;
    opacity: 0.9;
  }
  @each $theme, $header, $body, $chat in $chat-themes {
    &.#{$theme} {
      .close-button-chat {
        display:inline-block;
        width:15px;
        height:15px;
        padding:0;
      }
      .chat-footer-btn {
        background-color: #d4d4d4;
      }
      .chat-footer-btn.active{
        background-color: $header;
      }
      .chat-container {
        // background: $body;
        background:#e3ebf2;
      }
      .chat-lefted-text {
        background: $chat;
      }
      .chat-center-text {
        background: $header;
        color: white;
      }
      .chat-footer {
        background: $body;
      }
    }
  }
  .chat-body-messages {
    margin-bottom: 70px;
  }

  .media_img {
    .video_container {
      position: relative;
      .video {
        position: absolute;
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        font-size: 20px;
        padding: 10px;
        border-radius: 5px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .spinner-border {
        position: absolute;
        top: 40%;
        left: 39%;
        color: white;
      }
    }
  }

  .chat-body {
    height: 513px;
    overflow-y: scroll;
    overflow-x: hidden;

    .scroll-bottom-button {
      position: absolute;
      z-index: 1;
      right: 15px;
      bottom: 60px;
      padding: 0;
      background-color: rgba(0,0,0,.6);
      box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
      border: none;
      @include square(32px);

      .unseen-count {
        opacity: 0;
        font-size: 12px;
        position: absolute;
        width: 100%;
        top: 1px;
        line-height: 1;

        transition: .3s;
      }

      > i {
        transition: .3s;
      }

      &.display-count {
        .unseen-count {
          opacity: 1;
        }

        > i {
          transform: translateY(3.5px);
        }
      }
    }
  }
  .chat-footer {
    $size: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 93%;
    bottom: 0px;
    padding: 10px 0;

    .chat-footer-icon {
      @include square($size);
      border: 0;
      outline: none;
      background-color: transparent;
      // margin: 0 5px;
      text-align: left;
      margin-right: 5px;
    }
    .chat-footer-btn {
      @include square($size);
      width: 50px;
      border: none;
      outline: none;
      border-radius: 4px;
      color: white;
      margin: 0 5px;
      background:#d4d4d4;
    }
    .chat-footer-btn.active{
      background:#4fa6f1;
    }
    textarea {
      align-self: flex-end;
      flex: 1;
      border: none;
      box-sizing: content-box;
      border-radius: 5px;
      padding: 5px 10px;
      resize: none;
      font-size: 14px;
      line-height: 1.5;
      min-height: 14px * 1.5;
      max-height: 14px * 1.5 * 3;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      margin: 0 5px;
    }
  }
}

.chat-header .dropdown-menu {
  border-radius: 8px;
  border-color: transparent;
  padding: 15px 10px;

  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);

  .dropdown-item {
    font-family: 'Noto Sans KR', sans-serif;
    padding: 12px 20px;
    border-radius: 16px;
    text-align: left !important;
    line-height: 20px;

    &:hover {
      background-color: #01a0fc;
      transition: .3s;
      color: #fff;
    }
  }
}