/*===== icon-element ======*/
.icon-element {
    color: $theme-color;
    font-size: $fs-30;
    width: 60px;
    height: 60px;
    line-height: 60px;
    @include border-radius(100%);
    background-color: $white;
    @include box-shadow(0 0 10px rgba(14, 16, 48, .1));
    text-align: center;
    @include transition(0.3s);
    display: block;
}

.icon-element-lg {
    width: 80px;
    height: 80px;
    line-height: 80px;
}

.icon-element-sm {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: $fs-24;
}

.icon-element-xs {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
}