.room-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  z-index: 1000;
  .room-item-header {
    // margin: 5px;
    justify-content: space-between;
    margin:20px 20px 0 20px;
    div {
      // margin: 2px 5px;
    }
  }
  .room-body {
    margin: 10px 20px 15px;
    div {
      margin-right: 15px;
      // margin-bottom: 10px;
    }
    .ProfileImg {
      position: relative;
    }
    .owner-title-button {
      position: absolute;
      right: 0px;
    }
  }
  .owner-title small {
    width:20px;
    height:20px;
    line-height:20px;
    background:#222;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    text-align: center;
  }
}
