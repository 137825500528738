.flowing-ui--profile-setting-form {
  $theme-color: #2d86eb;
  $inactive-border-color: rgba(128, 137, 150, 0.3);
  $inactive-font-color: #6c727c;

  .logo_profile {
    margin-bottom: 0;

    .image-button {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 34px;
      height: 34px;
      color: #aaa;
      border-radius: 100%;
      background-color: #fff;
      border: 1px solid #ccc;

      font-size: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0;
      margin-bottom: 0;
      cursor: pointer;

      transition: .3s;

      &[disabled] {
        cursor: default;
        background-color: #ccc;
        pointer-events: none;
      }
    }
  }

  .form-control {
    border-color: $inactive-border-color;
  }

  .theme-btn {
    min-height: 50px;
    border-radius: 12px;
  }

  .theme-btn-outline {
    color: #2d86eb;
    background-color: transparent;

    &[disabled] {
      border-color: #ccc;
      background-color: #ebebeb;
    }
  }

  .row {
    margin-left: -3px;
    margin-right: -3px;
  }

  .btn-group, .group-box {
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
      margin-top: 3px;
      margin-bottom: 3px;
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  .btn-group {
    .theme-btn-outline {
      color: $inactive-font-color;
      border-color: $inactive-border-color;

      &:not(.disabled).active {
        border: none;
        background-color: $theme-color;
      }
    }
  }
}