.typing-loader {
  $dot-size: 9px;
  $color: #01a0fc;

  .spinner {
    //text-align: center;
    display: inline-flex;
    border-radius: $dot-size/2;
    padding: $dot-size/3 $dot-size/3;
  }

  .spinner > div {
    width: $dot-size;
    height: $dot-size;
    margin: 0 $dot-size/5;
    background-color: $color;

    border-radius: 100%;
    //display: inline-block;
    -webkit-animation: bounce-dots 1.4s infinite ease-in-out both;
    animation: bounce-dots 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes bounce-dots {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1) }
  }

  @keyframes bounce-dots {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0;
    } 40% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
      }
  }
}