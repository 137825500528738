.emoji-picker-react {
  position: absolute !important;
  z-index: 10000;
  left: 4%;
  top: -550%;
}

.previous-messages-content {
  padding: 0px;
  display: flex;
  justify-content: center;
}
.previous-messages-content .previous-messages-button {
  background: white;
  padding: 0px;
  border: 1px solid cadetblue;
}
.previous-messages-content .previous-messages-button .chat-center-text {
  background: white !important;
  padding: 0px;
  display: flex;
  justify-content: center;
  color: cadetblue !important;
  margin-right: 20px;
  margin-left: 20px;
}
.btn-submit-limits {
  padding: 2px 10px !important;
}
.text-center .chat-body-message-content-text {
  display: grid;
  justify-content: center;
  justify-items: center;
}
.PcBg .phone_over .TackBtnArea{
  position: fixed;
    bottom: 200px;
    left: 50%;
    margin-left: 90px;
    z-index: 99;
    cursor: pointer;
}
.half-vh-height{
  /* height: 52vh !important; */
}