.flowing-ui--chat-modal {
  $font-size: 13px;
  $padding-y: 7px;
  $padding-x: 13px;

  &__wrapper {
    font-size: $font-size;
    background-color: rgba(0,0,0,.7);
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 20px;
  }

  &__base {
    background-color: #fff;
    border-radius: $padding-y*2;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header, &__body, &__footer {
    padding: $padding-y $padding-x;
    line-height: 1.4;
    position: relative;
  }

  &__header {
    //margin-right: -13px;

    .close-btn {
      position: absolute;
      width: 1.3em;
      height: 1.3em;
      line-height: 1;
      border: none;
      background-color: transparent;
      right: $padding-y;
      z-index: 100;
    }
  }
}