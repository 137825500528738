html:root {
  --v-primary-color: #01a0fc;
}

.flowing-ui-form {
  --v-primary-color: #01a0fc;

  .widthAuto {
    width: 100px;
  }

  .CountNum {
    right: 105px;
  }

  .input-group-sm .form-control, .input-group-sm .form-control-sm,  .form-control-sm {
    height: 35px;
    border-radius: 12px;

    &[readonly] {
      pointer-events: none;
    }
  }

  .input-group > .custom-select:not(:last-child),
  .input-group > .form-control:not(:last-child),
  .input-group > .form-control-sm:not(:last-child),
  .input-group-sm > .form-control-sm:not(:last-child),
  .input-group-sm > .form-control:not(:last-child){
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .custom-form-group {
    padding-bottom: 1.6em;
    position: relative;

    margin-bottom: 0 !important;

    .helper-text {
      position: absolute;
      bottom: 0.5em;
      left: 12px;
      font-size: 11px;
    }
  }

  .btn-sm, .btn-group-sm>.btn {
    height: 35px;
  }

  .btn, .btn-sm, .btn-group-sm>.btn {
    border: none;
    border-radius: 12px;
  }

  .btn-primary {
    background-color: var(--v-primary-color, #01a0fc);
  }

  a {
    color: var(--v-primary-color, #01a0fc);
  }
}

.flowing-ui--chat-modal-tab {
  --v-primary-color: #01a0fc;

  .nav {
    border-bottom: 1px solid  var(--v-primary-color, #01a0fc);
    position: relative;
  }

  .nav-item {
    border: 1px solid transparent;
    background-color: rgba(0,0,0,.1);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    border-bottom: none;

    &:not(:first-child) {
      margin-left: 2px;
    }

    &.active {
      border-color: var(--v-primary-color, #01a0fc);
      background-color: #fff;
      color: var(--v-primary-color, #01a0fc);
      font-weight: 700;
      margin-bottom: -1px;
    }
  }
}