@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

html {
  scroll-behavior: smooth;
}

html:root {
  --primary: #01a0fc;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
}

body::before {
  height: auto;
}

.horizontal-layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.horizontal-layout-wrapper > .main-content {
  flex-grow: 1;
}

.addLine {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: #F8FAFB;
  color: #434A54;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
  overflow-y: scroll;
  position: relative;
}

.addLine::before {
  content: '';
  height: 3px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background: #01a0fc;
}

.logo_profile {
  border-radius: 90px;
  display: block;
  background-color: #fff;
  width: 90px;
  height: 90px;
  position: relative;

  box-shadow: 3px 3px 5px rgb(0 0 0 / 20%);
}

.logo_profile .Phonebutton {
  border: 1px solid #ccc;
  border-radius: 50px;
  background: #fff;
  font-size: 22px;
  color: #aaa;
  position: absolute;
  height: 34px;
  width: 34px;
  text-align: center;
  line-height: 32px;
  right: 0;
  cursor: pointer;
  bottom: 0;
  margin-bottom: 0;
  margin-right: -5px;
}

.logo_profile .Phonebutton:focus {
  outline: none;
}

.logo_profile img {
  width: 90px !important;
  height: 90px !important;
  border-radius: 90px;
}

.card-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
  margin-bottom: 5px;
}

.card-title2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
  margin-bottom: 5px;
}

.Copyright {
  color: #999;
  font-size: 12px;
}

.registerGuide {
  font-size: 12px;
  color: #999;
}

.CountNum {
  font-size: 14px;
  color: #999;
  position: absolute;
  z-index: 999;
  right: 134px;
  top: 12px;
}

.auth {
  position: relative;
  display: flex;
  min-height: 100vh !important;
  height: auto;
  align-items: center;
}

.auth .logo {
  position: absolute;
  left: 30px;
  top: 30px;
}

.auth .logo img {
  width: 150px;
}

.auth .card {
  width: 100%;
  max-width: 700px;
  background-color: inherit;
  border: 0;
  border-radius: 0;
  margin: 0 auto;
}

.auth .form-control,
.dataTables_wrapper .dataTables_filter {
  height: 50px;
}

.form-footer .btn {
  font-weight: bold;
  height: 40px;
  /* line-height: 38px; */
}

.auth .card:hover {
  box-shadow: none;
}

.OptionBox {
  border: 5px solid #f3f3f3;
  border-radius: 10px;
  padding: 30px 20px;
}

.auth .card-top {
  background: #fff;
  text-align: left;
  padding: 20px;
  margin-bottom: 0px;
}

.auth .card-top .card-title {
  font-size: 50px;
  color: #333;
}

.auth .card-top .card-title2 {
  font-size: 42px;
  color: #333;
}

.blackColor {
  color: #333 !important;
}

.blackBox {
  border: 1px solid #333;
  border-radius: 10px;
}

.blackBox::placeholder {
  color: #333;
}

.auth .btn {
  border-radius: 30px;
  font-size: 18px;
  line-height: 30px;
}

.auth .input-group-append .btn {
  font-size: 14px;
}

.widthAuto {
  width: 120px;
}

.auth {
  background: #fff;
}

.auth .auth_left .card {
  padding: 0;
  right: 0px;
  /* z-index: 99999; */
  text-align: left;
}

@media only screen and (min-width: 768px) {
  .auth .auth_left {
      padding: 80px 0;
  }
}
.auth .auth_right {
  background: url(../images/loginBG.jpg) no-repeat center;
  background-size: cover;
}

.auth .auth_left {
  width: calc(100% - 650px);
  text-align: center;

}

.auth .auth_right {
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  height: 100vh;
  width: 650px;
  /* padding: 0 20px; */
}

.auth_left .card-top{
  height: auto !important;
}

.card-footer,
.card-bottom {
  padding: 20px 20px;
  background: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label {
  font-weight: bold;
}

.payment-tab-toggle input:checked~label {
  font-weight: bold;
}

.payment-tab-toggle>label:before {
  top: 20px;
}

.payment-tab-toggle>label:after {
  top: 25px;
}

/* 메뉴 */
.nav-right-button .round {
  border-radius: 30px;
  font-size: 14px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  padding: 6px 20px;
}

.bar-round {
  border-radius: 100px;
  padding: 5px 30px;
  margin-top: 30px;
}

.menu-bar>ul>li {
  padding: 10px 0;
}

.menu-bar>ul>li a {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  color: #333;
  font-weight: 300;
  padding: 12px 20px;
}

.menu-bar>ul>li a:hover {
  color: #111;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

.menu-bar .active a {
  background-color: #01a0fc;
  border-radius: 16px;
  color: #fff;
}

.header-area.fixed-top {
  box-shadow: none;
}

.menu-bar>ul>li .dropdown-menu-item li a {
  background: #fff;
  color: #333;
}

.menu-bar>ul>li .dropdown-menu-item li a:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.off-canvas-menu-list>li {
  margin-bottom: 15px;
}

/* 로그인 */
.loginCase01,
.loginCase02 {
  display: none;
}

.caseview {
  display: block;
}

.login-area h2 {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
  color: #333;
}

.pwGuide {
  color: #999;
}


/* 메인 페이지 */
.theme-btn {
  background-color: #09a8fa;
}

.generic-pagination .page-item.active .page-link {
  background-color: #09a8fa;
}

.logo img {
  width: 94px;
}

.bgGray {
  background: #f3f3f3;
}

.btn-search {
  font-size: 20px;
  height: 50px;
  line-height: 40px;
  border-radius: 12px;
}

.searchBox {
  display: flex;
  padding: 0 15px;
}

.searchBox .writeBtnArea {
  margin-left: 50px;
  width: 200px;
}

.btnWrite {
  border-radius: 30px;
  font-family: 'Noto Sans KR', sans-serif;
  padding: 10px 12px;
  width: 100%;
  display: block;
  color: #fff !important;
}

.max800 {
  max-width: 800px;
}

.max900 {
  max-width: 900px;
}

.max1280 {
  max-width: 1280px;
}

.form-control {
  border-radius: 12px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
}

.round {
  border-radius: 30px;
  font-size: 14px;
}

.media-foot {
  border-left: 1px dotted #ddd;
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.nicname .time {
  color: #999;
}

.media-body h5 a {
  font-weight: bold;
}

.infoArea small {
  color: #545454;
}

.infoArea small i {
  font-size: 18px;
  vertical-align: middle;
}

.infoArea small:last-child {
  margin-left: 100px;
}

.media-card {
  margin-bottom: 10px;
}

.media-card {
  padding: 0;
}

.media-card .media-top {
  padding: 20px;
}

.media-card .media-body {
  padding: 0;
  padding-right: 20px;
  font-family: 'Noto Sans KR', sans-serif;
}

.nicname {
  font-weight: 300;
  text-align: right;
  width: 200px;
  position: absolute;
  right: 0;
}

.time i {
  font-size: 18px;
  vertical-align: middle;
}

.listTitle {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 24px;
  font-weight: 300;
}

.listTitle b {
  font-weight: 700;
}

.media-card .media-foot button {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
  font-size: 16px;
  padding: 10px 30px;
}

.media-card .media-foot button:hover {
  color: #fff;
  background-color: #09a8fa;
  border-color: #09a8fa;
}

.media-card .media-img {
  width: 140px;
  height: 80px;
  border-radius: 0;
  margin-right: 0;
}

.media-card .media-img img {
  border-radius: 0;
}

.profileImg {
  width: 36px;
  height: 36px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  box-sizing: border-box;
}

.profileImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #ccc;
  display:inline-block;
}


/* 질문 하기 */
.btnBic2 {
  border-radius: 30px;
  font-family: 'Noto Sans KR', sans-serif;
  padding: 12px 34px;
  color: #fff !important;
}


/* 답변 안내 */
.reply-area .listTitle {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 34px;
  font-weight: 300;
  word-break: keep-all;
}

.reply-area .listTitle b {
  font-weight: 700;
  color: #01a0fc;
}

.replyImg {
  border-radius: 20px;
  overflow: hidden;
}

.replyImg img {
  width: 100%;
}

.replyImgArea {
  margin-left: 100px;
}

.reply-area .imgText {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 34px;
  font-weight: 300;
  color: #333;
  line-height: 50px;
}

.reply-area .imgText b {
  font-weight: 900;
}

.reply-area h5 {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 22px;
  font-weight: 300;
  color: #333;
}

.btnBic {
  background: #fff;
  border-radius: 30px;
  font-family: 'Noto Sans KR', sans-serif;
  padding: 18px 34px;
}

.adressArea {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.adressArea span {
  margin-right: 1rem;
}

.adressArea .form-control {
  border-radius: 12px !important;
}

.adressArea .btn {
  border-radius: 12px !important;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
}

.reply-area .card-item {
  border-radius: 20px;
}

.tagImgArea {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
  text-align: center;
}

.tagImgArea .tagitem {
  margin: 20px;
}

.tagImgArea .tagitem .tagitemImg {
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  max-width: 364px;
}

.tagImgArea .tagitem .tagitemImg img {
  width: 100%;
}

.tagImgArea .tagitem p {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  color: #333;
  padding: 10px 5px;
}

.tagImgArea .tagitem p img {
  width: 70px;
}

.accodianArea {
  background: #01a0fc;
}

.accodianArea .listTitle {
  color: #fff !important;
  font-weight: 500;
}

.accodianArea h5 {
  color: #fff !important;
  font-weight: 500;
}

.accodianArea .card {
  margin-bottom: 12px;
  border-radius: 20px;
}

.accodianArea .card-header .btn {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  padding: 25px;
}

/* 상품 안내 */
.bgblue {
  background: #50a5f1;
}

.bgSession1 {
  background: #e83e8c;
}

.bgSession2 {
  background: #34c564;
}

.bgSession3 {
  background: #6252de;
}

.mainImg {
  width: 100%;
  text-align: center;
}

.mainImg img {
  width: 100%;
}

.product-area .listTitle {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 38px;
  font-weight: 300;
}

.product-area .listTitle span {
  font-weight: 700;
  color: #01a0fc;
  font-size: 62px;
}

.product-area .listTitle b {
  font-weight: 700;
  color: #333;
}

.productImg img {
  width: 100%;
  max-width: 900px;
}

.productImg2 img {
  width: 100%;
  max-width: 377px;
}

.productDown {
  margin-left: 110px;
  margin-top: 50px;
  margin-right: 30px;
}

.solutionTit {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 40px;
  font-family: 'Noto Sans KR', sans-serif;
}

.solutionTxt {
  font-size: 16px;
  color: #333;
  margin-bottom: 60px;
  font-weight: 400;
  font-family: 'Noto Sans KR', sans-serif;
}

.btndown {
  display: block;
  width: 100%;
  max-width: 240px;
  font-weight: 400;
  font-family: 'Noto Sans KR', sans-serif;
  color: #333;
}

.mbm {
  margin-bottom: -24px;
}

.btn-area3 .link-app {
  display: inline-block;
  overflow: hidden;
  width: 80px;
  height: 80px;
  font-size: 18px;
  margin-right: 14px;
  line-height: 42px;
  border-radius: 80px;
  text-align: center;
  vertical-align: middle;
  transition: all 0.5s ease;
}

.btn-area3 .link-app:hover {
  background: #fff;
  box-shadow: 4px 8px 12px 0 rgb(0 0 0 / 10%);
}

.btn-area3 .tit-ios {
  width: 34px;
  height: 34px;
  display: inline-block;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
  background-image: url(../images/ic_app.png);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 20px;
}

.btn-area3 .tit-android {
  width: 34px;
  height: 34px;
  display: inline-block;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
  background-image: url(../images/ic_gl.png);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 20px;
}

.bgyello {
  background: #f6f6f6 !important;
  background: radial-gradient(circle, #f6f6f6 0%, white 88%) !important;
}

/* 마이페이지 */
.talkRoomImg {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
}

.talkRoomImg img {
  width: 100%;
  height: 100%;
}

.table.table_custom {
  border-collapse: separate;
  border-spacing: 0 5px !important;
}

.table td,
.table th {
  vertical-align: middle;
}

.table td,
.table th {
  vertical-align: middle !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-top: none;
}

.table.table_custom tr th,
.table.table_custom tr td {
  border: 0;
}

.table.table_custom tr th:first-child,
.table.table_custom tr td:first-child {
  border-radius: 0.55rem 0 0 0.55rem;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table.table_custom tr th:first-child,
.table.table_custom tr td:first-child {
  border-radius: 0.55rem 0 0 0.55rem;
}

.table.table_custom tr th,
.table.table_custom tr td {
  border: 0;
}

.table.table_custom tr th:last-child,
.table.table_custom tr td:last-child {
  border-radius: 0 0.55rem 0.55rem 0;
}

.table.table_custom tr {
  background: #fff;
}

.table.table_custom tbody tr {
  cursor: pointer;
}

.nocurse {
  cursor: initial !important;
}

.btnWhite {
  background: #fff;
}

.btnWhite:hover {
  background: var(--secondary, #6c757d);
}

.viewInfo {
  position: absolute;
  right: 20px;
  top: 0;
  line-height: 50px;
  font-weight: 300;
  color: #545454;
}

.bgask {
  background: #faf8f1;
}

.selectize-input {
  border-radius: 12px;
}

.btnbasic {
  line-height: 24px;
}

.file-upload-layout-3 {
  width: auto;
}

.file-upload-layout-3 .file-upload-input {
  height: 55px;
  border-style: dashed;
  border-width: 1px;
}

.file-upload-layout-3 .file-upload-text {
  width: 100%;
  line-height: 55px;
  padding-left: 0;
}


/* 리스트 */
.jobs-snippet {
  overflow-x: auto;
}

.table-responsive {
  position: relative;
  display: block;
  width: 100%;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
}

.ArrowPrev {
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: -40px;
  margin-top: -20px;
  font-size: 40px;
  color: #333;
  opacity: .3;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 99;
  cursor: pointer;
}

.la-chevron-circle-left {
  background: #fff;
  border-radius: 50%;
  height: 1em;
  width: 1em;
}

.la-chevron-circle-right {
  background: #fff;
  border-radius: 50%;
  height: 1em;
  width: 1em;
}

.ArrowPrev:hover {
  opacity: 1;
}

.ArrowNext {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: -40px;
  margin-top: -20px;
  font-size: 40px;
  color: #333;
  opacity: .3;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 99;
  cursor: pointer;
}

.ArrowNext:hover {
  opacity: 1;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.listTxtTit {
  font-size: 14px;
  color: #333;
  position: relative;
  font-weight: 600;
}

.listInfo01,
.listInfo02 {
  display: inline-block;
  width: 50%;
  color: #545454;
}

.listInfo01 i {
  font-size: 20px;
  vertical-align: middle;
}

.listInfo02 i {
  font-size: 20px;
}

.listInfo03 i {
  font-size: 20px;
  vertical-align: middle;
}

.listInfo04 i {
  font-size: 20px;
}

.listInfo03 {
  width: 100%;
  text-align: left;
}

.listInfo03 .mr10 {
  width: 50%;
}

.listInfo03 b {
  font-weight: 500;
  color: #333;
}

.listInfo04 {
  width: 100%;
  text-align: left;
}

.listInfo04 b {
  font-weight: 500;
  color: #333;
}

.askTime {
  font-weight: 300;
  text-align: right;
  color: #999;
  position: absolute;
  right: 0;

}

.askTime b {
  font-weight: 500;
  color: #333;
}

.listInfo01 p {
  margin-bottom: 0;
  text-align: left;

}

.listInfo02 p {
  margin-bottom: 0;
  text-align: left;
}

.media-foot {
  padding: 40px 20px;
  margin-right: -20px;
  border-left: 1px dashed #ddd;
}

.userImg {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.userInfo {
  color: #999;
  margin-top: 10px;
}

.userInfo span {
  margin-right: 10px;
}

.avatar {
  color: #4D5052;
  font-weight: 600;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  display: inline-block;
  background: #808488 no-repeat center/cover;
  position: relative;
  vertical-align: bottom;
  font-size: .875rem;
  user-select: none;
  border: 1px solid #ccc;
}

.w150 {
  width: 150px !important;
}

.owl-action-styled .owl-nav .owl-prev {
  margin-left: -70px;
}

.owl-action-styled .owl-nav .owl-next {
  margin-right: -70px;
}

.owl-action-styled .owl-nav {
  z-index: -1;
}

.owl-action-styled .owl-nav button.owl-prev,
.owl-action-styled .owl-nav button.owl-next {
  line-height: 1;
  
}
/* 답변톡 구매 */
.buyArea h5 {
  font-size: 16px;
  font-weight: 300;
}

.buyInfo {
  margin: 0 auto;
  width: 100%;
  max-width: 940px;
  margin-top: 30px;
}

.buyBox {
  background: #fff;
  border-radius: 30px;
  padding: 20px 40px;
  color: #333;
  font-size: 24px;
}

.buyBox b {
  font-weight: 900;
}

.buyGoods {
  display: flex;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.buyGoods .diaImg {
  width: 120px;
  text-align: center;
  background: #f9f9f9;
  padding: 13px;
}

.buyGoods .textArea {
  padding: 20px;
  color: #333;
}

.buyGoods .textArea b {
  color: #01a0fc;
}

.buyGoods .textArea span {
  display: block;
  font-size: 24px;
  font-weight: 700;
}

.plusPoint {
  background: #01a0fc;
  color: #fff;
  position: absolute;
  border-radius: 20px;
  text-align: center;
  padding: 3px;
  left: 25px;
  top: 0;
  margin-top: -22px;
  width: 60px;
}

.buyBtn {
  border-radius: 30px;
  padding: 10px 40px;
  margin-right: 20px;
  font-weight: 500;
}

.media-body .content {
  display: flex;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  position: relative;
}

.outlineBlack {
  border: 1px solid #6c757d;
}

.cateResultArea {
  width: 100%;
  height: 460px;
  overflow-y: auto;
}

.selectArea {
  border-radius: 10px;
  padding: 15px;
  background: #f3f3f3;
  margin-bottom: 10px;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.textmax {
  color: #999;
  font-weight: 300;
}

.adressArea .form-control {
  margin-right: 20px;
}

.mwt {
  margin-bottom: 20px;
}

.bgbluebar {
  background: #01a0fc;
  color: #fff;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 16px;
  margin-left: 10px;
  display: inline-block;
}

.keep {
  word-break: keep-all;
}

/* 개인톡방 */
.phone_over {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  color: #333;
  word-break: keep-all;
}

.phone_over i {
  font-size: 22px;
}

.PhoneArea {
  position: relative;
  font-size: 14px;
  border-radius: 20px;
}

.PhoneArea .TackBtnArea {
  /* position: fixed;
  bottom: 200px;
  left: 50%;
  margin-left: 90px; */
  z-index: 99;
  cursor: pointer;
}

.PhoneArea .TackBtnArea img {
  border: 4px solid #01a0fc;
  vertical-align: middle;
}

.PhoneArea .TackBtnArea span {
  background: #01a0fc;
  color: #fff;
  font-size: 16px;
  border-radius: 20px 0 0 20px;
  padding: 6px 15px;
  margin-right: -9px;
  vertical-align: middle;
}

.PhoneArea .TackBtnArea .avatar {
  width: 54px;
  height: 54px;
}

.MainImgBg {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.PhoneBody {
  padding: 10px;
  position: relative;
  height: auto;
}

.avatar-lg {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.25rem;
}

.giftTit {
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.giftTit img {
  margin-right: 10px;
  width: 40px;
}

.giftBox {
  border: 1px solid #545454;
  border-radius: 10px;
  padding: 10px;
}

.giftBox img {
  width: 30px;
}

.ic_home {
  position: absolute;
  right: 10px;
  top: 12px;
  color: #333;
}

.ChArea {
  position: absolute;
  width: 50px;
  bottom: 0;
  right: 10px;
}

.ChArea img {
  width: 100%;
}

.PhoneTop {
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.PhoneTop small {
  color: #333;
}

.PhoneTop small img {
  width: 12px;
  display: inline-block;
}

.noImg {
  width: 100px !important;
  margin: 0 auto;
}

.tag {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: .75rem;
  color: #6e7687;
  font-weight: 400;
  background-color: #D1D3D4;
  padding: 0 0.5rem;
  line-height: 2em;
  display: inline-flex;
  cursor: default;
  user-select: none;
}

.PhoneBody .tabArea .tag-rounded {
  background: #fff !important;
  color: #999999;
  font-size: 12px;
  border-radius: 20px;
  padding: 3px 15px;
  margin-bottom: 10px;
  cursor: pointer;
  display: none;
}

.PhoneBody .tabArea .active {
  color: #000;
  font-weight: 600;
}

.PhoneBody .conArea .txtArea {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  font-size: 12px;
  color: #545454;
}

.PhoneBody .conArea .adressArea1 {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  margin-top: 10px;
}

.PhoneBody .conArea .adressArea2 {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  margin-top: 10px;
}

.PhoneBody .conArea .txtArea2 {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  font-size: 12px;
  height: auto;
  color: #545454;
}

.PhoneBody .conArea .txtArea2 i {
  font-size: 20px;
  vertical-align: middle;
}

.PhoneBody .conArea .answerInfo {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  font-size: 12px;
  margin-top: 10px;
}

.PhoneBody .conArea .giftInfo {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  font-size: 12px;
  margin-top: 10px;
}

.PhoneBody .answerInfo .tag-rounded {
  background: #fff !important;
  color: #333;
  font-size: 12px;
  padding: 8px 10px;
  border-radius: 20px;
  line-height: 1;
  border: 1px solid #545454;
}

.PhoneBody .giftInfo .tag-rounded {
  background: #fff !important;
  color: #333;
  font-size: 12px;
  padding: 8px 10px;
  border-radius: 20px;
  line-height: 1;
  border: 1px solid #545454;
}

.PhoneBody .answerInfo small {
  font-weight: 800;
}

.PhoneBody .conArea .footlogo {
  text-align: center;
  padding: 5px 0;
}

.PhoneBody .conArea .footlogo img {
  width: 90px;
}

.MainImg {
  position: relative;
}

.MainImg .ChArea {
  position: absolute;
  width: 40px;
  bottom: 0;
  left: 10px;
}

.PhoneTop .ChArea {
  position: absolute;
  width: 40px;
  bottom: 0;
  right: 10px;
}

.MainImg img {
  width: 100%;
}

.phoneRound {
  border-radius: 20px;
}

.noBg {
  background: inherit !important;
}

/* 색변화 */

.answerMobilebg {
  padding: 0;
}

.whiteBg {
  background: #cfd9e9;
}

.whiteBg .PhoneTop {
  background: #fff
}

.whiteBg .PhoneBody {
  background: #cfd9e9 !important;
}

.whiteBg .PcBg {
  background: #cfd9e9;
}

.whiteBg .MainImgBg {
  background: #fff;
}


.CaseOn {
  display: block !important;
}





.greenBg {
  background: #e2ebe5;
}

.greenBg .PhoneTop {
  background: #4eac6d
}

.greenBg .PhoneBody {
  background: #e2ebe5 !important;
}

.greenBg .TackBtnArea img {
  border: 4px solid #4eac6d !important;
}

.greenBg .TackBtnArea span {
  background: #4eac6d !important;
}

.greenBg .TackBtnArea img {
  border: 4px solid #4eac6d !important;
}

.greenBg .TackBtnArea span {
  background: #4eac6d !important;
}

.greenBg .PhoneTop {
  color: #fff;
}

.greenBg .PhoneTop i {
  color: #fff;
}

.greenBg .PcBg {
  background: #e2ebe5;
}

.greenBg .MainImgBg {
  background: #4eac6d;
  color: #fff !important;
}

.PcBg .greenBg .PhoneBody .conArea .adressArea1 {
  background: #4eac6d;
  color: #fff !important;
}



.blueBg {
  background: #e2eaf2;
}

.blueBg .PhoneTop {
  background: #50a5f1
}

.blueBg .PhoneBody {
  background: #e2eaf2 !important;
}

.blueBg .TackBtnArea img {
  border: 4px solid #50a5f1 !important;
}

.blueBg .TackBtnArea span {
  background: #50a5f1 !important;
}

.blueBg .TackBtnArea img {
  border: 4px solid #50a5f1 !important;
}

.blueBg .TackBtnArea span {
  background: #50a5f1 !important;
}

.blueBg .PhoneTop {
  color: #fff
}

.blueBg .PhoneTop i {
  color: #fff
}

.blueBg .PcBg {
  background: #e2eaf2;
}

.blueBg .MainImgBg {
  background: #50a5f1;
  color: #fff !important;
}

.PcBg .blueBg .PhoneBody .conArea .adressArea1 {
  background: #50a5f1;
  color: #fff !important;
}

.purpleBg {
  background: #e4e2ee;
}

.purpleBg .PhoneTop {
  background: #6153cc
}

.purpleBg .PhoneBody {
  background: #e4e2ee !important;
}

.purpleBg .TackBtnArea img {
  border: 4px solid #6153cc !important;
}

.purpleBg .TackBtnArea span {
  background: #6153cc !important;
}

.purpleBg .TackBtnArea img {
  border: 4px solid #6153cc !important;
}

.purpleBg .TackBtnArea span {
  background: #6153cc !important;
}

.purpleBg .PhoneTop {
  color: #fff;
}

.purpleBg .PhoneTop i {
  color: #fff;
}

.purpleBg .PcBg {
  background: #e4e2ee;
}

.purpleBg .MainImgBg {
  background: #6153cc;
  color: #fff !important;
}

.PcBg .purpleBg .PhoneBody .conArea .adressArea1 {
  background: #6153cc;
  color: #fff !important;
}

.pinkBg {
  background: #f1e0e8;
}

.pinkBg .PhoneTop {
  background: #e83e8c
}

.pinkBg .PhoneBody {
  background: #f1e0e8 !important;
}

.pinkBg .TackBtnArea img {
  border: 4px solid #e83e8c !important;
}

.pinkBg .TackBtnArea span {
  background: #e83e8c !important;
}

.pinkBg .TackBtnArea img {
  border: 4px solid #e83e8c !important;
}

.pinkBg .TackBtnArea span {
  background: #e83e8c !important;
}

.pinkBg .PhoneTop {
  color: #fff;
}

.pinkBg .PhoneTop i {
  color: #fff;
}

.pinkBg .PcBg {
  background: #f1e0e8;
}

.pinkBg .MainImgBg {
  background: #e83e8c;
  color: #fff !important;
}

.PcBg .pinkBg .PhoneBody .conArea .adressArea1 {
  background: #e83e8c;
  color: #fff !important;
}

.grayBg {
  background: #e6e6e8;
}

.grayBg .PhoneTop {
  background: #797c8c
}

.grayBg .PhoneBody {
  background: #e6e6e8 !important;
}

.grayBg .TackBtnArea img {
  border: 4px solid #797c8c !important;
}

.grayBg .TackBtnArea span {
  background: #797c8c !important;
}

.grayBg .TackBtnArea img {
  border: 4px solid #797c8c !important;
}

.grayBg .TackBtnArea span {
  background: #797c8c !important;
}

.grayBg .PhoneTop {
  color: #fff;
}

.grayBg .PhoneTop i {
  color: #fff;
}

.grayBg .PcBg {
  background: #e6e6e8;
}

.grayBg .MainImgBg {
  background: #797c8c;
  color: #fff !important;
}

.PcBg .grayBg .PhoneBody .conArea .adressArea1 {
  background: #797c8c;
  color: #fff !important;
}


@media screen and (max-width: 1200px) {
  .mwt {
    font-size: 26px !important;
    margin-bottom: 10px;
  }


  .auth .card-top .card-title {
    font-size: 30px;
  }

  .auth .card-top .card-title {
    font-size: 28px;
  }

  .card-top {
    margin-bottom: 0;
  }

  .auth .auth_left {
    width: 100% !important;
  }

  .auth .auth_right {
    display: none !important;
  }

  .auth .auth_right {
    display: none;
  }

  .textColor {
    display: none;
  }

  .PcRightBoard {
    border-right: none;
    padding-right: 7.5px;
    margin-bottom: 20px;
  }

  .OptionBox {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
  }

  .auth .logo {
    position: relative;
    left: 10px;
    top: 20px;
    height: 80px;
    width: 200px;
  }

  .auth {
    display: block;
    height: 100vh;
    align-items: center;
  }

  .bar-round {
    margin-top: 0 !important;
  }

  .header-area {
    background: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  }

  .replyImgArea {
    margin: 0;
  }


  .adressArea {
    display: block !important;
  }

  .adressArea span {
    margin-bottom: 10px;
    display: block;
  }

  .adressArea .form-control {
    margin-right: 10px;
  }


}

@media screen and (max-width: 990px) {
  .tagImgArea .tagitem {
    max-width: 368px;
    display: inline-block;
  }

  .reply-area .listTitle {
    font-size: 30px;
  }

  .reply-area h5 {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .reply-area {
    padding-top: 40px !important;
  }

  .buyArea {
    padding-top: 40px !important;
  }

  .job-area {
    padding-top: 40px !important;
  }

  .productDown {
    margin: 0;
  }

  .productTxt {
    margin-bottom: 10px;
  }


  .tagImgArea {
    display: block;
    padding: 10px;
  }

  .reply-area .imgText {
    text-align: center;
    margin-top: 20px;
  }

  .accodianArea .card-header .btn {
    font-size: 14px;
    padding: 25px 10px;
  }

  .mobile2line {
    display: block !important;
  }

  .mobile2line .form-group {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }

  .bar-round {
    margin-top: 0 !important;
  }

  .header-area {
    background: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  }

  .container {
    max-width: 900px;
  }

  .filters {
    flex-direction: unset;
  }

  .viewInfo {
    position: relative;
    right: 0;
    top: 0;
    line-height: 30px;
  }

  .viewInfo span {
    display: block;
  }

  .search-btn-box {
    flex-grow: inherit;
  }

  .search-btn-box .btn {
    width: 100%;
    max-width: 100px;
  }

  .media-body .content {
    display: block;
    text-align: left;
  }

  .buyGoods .diaImg {
    width: 100px;
    padding: 13px 0;
  }

  .buyGoods .textArea span {
    font-size: 16px;
  }

  .buyGoods .textArea {
    padding: 0 0 0 20px;
  }

  .buyBtn {
    padding: 12px 10px;
  }

  .buyArea h5 {
    font-size: 14px;
  }

  .plusPoint {
    padding: 0;
    left: 2px;
    top: 0;
    margin-top: -18px;
    width: 50px;
  }

  .btnBic2 {
    padding: 10px 24px;
  }

  .listTitle {
    font-size: 24px !important;
    margin-top: 10px;
  }

  .solutionTit {
    font-size: 20px;
    line-height: 1.4;
  }

  .solutionTit br {
    display: none !important;
  }

  .card-body {
    padding: 15px;
  }

  .media {
    display: block;
  }

  .media>.mr-3 {
    margin: 0 !important;
  }

  .media>.mr-3 .w150 {
    width: 100% !important;
  }

  .media .media-body {
    margin-top: 10px;
    padding: 0;
  }

  .listTxtTit {
    position: unset;
    display: block !important;
  }

  .listTxtTit span {
    display: block;
    right: 20px;
    bottom: 20px;
  }

  .nicname {
    margin-top: 10px;
    font-weight: 300;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    position: static;

  }

  .nicname .time {
    font-size: 12px;
    display: block;
  }

  .askTime {
    position: static;
    text-align: left;
  }

  .listInfo01,
  .listInfo02 {
    font-size: 13px;
    margin-top: 10px;
  }

  .listInfo01 {
    margin-right: 5%;
  }

  .media-foot {
    padding: 20px;
    margin-right: 0;
    margin-top: 20px;
    border: none;
    border-top: 1px dashed #ddd;
  }

  .media-foot .btn {
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .filters {
    flex-direction: inherit;
  }
}

@media (max-width: 768px) {
  .phone_over {
    max-width: 100%;
  }

  .phoneRound {
    border-radius: 20px 20px 0 0;
  }

  .hero-area {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .hero-area .search-btn-box {
    margin-left: 10px;
  }

  .tagImgArea .tagitem {
    display: block;
  }

  .reply-area .listTitle {
    font-size: 20px;
  }

  .reply-area h5 {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .noflex {
    flex-direction: column;
  }

  .noflex button {
    display: block;
    width: 100%;
  }

  .noflex .btnArea {
    width: 100%;
  }

  .tagImgArea .tagitem .tagitemImg {
    max-width: 100% !important;
  }


  .PhoneBody .tabArea .tag-rounded {
    display: inline-block;
    margin-top: 10px;
  }

  .sectorTit {
    display: none !important;
  }

  .mBox {
    background: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    margin-top: 10px;
  }

  .mBox .sectorTit {
    font-size: 12px !important;
    font-weight: 600;
  }

  .txtArea,
  .txtArea2,
  .mBox,
  .giftInfo,
  .adressArea1,
  .adressArea2 {
    display: none;
  }

  .answerInfo {
    padding: 0 !important;
    margin-bottom: 10px;
  }

  .partOn {
    display: block !important;
  }
}

.job-area {
  padding-top: 40px;
}

/* 풋터 */

.footInfo {
  line-height: 1.1;
  margin-bottom: 10px;
}

.footInfo span {
  padding: 0 5px;
  border-right: 1px solid #ddd;
}

.footInfo span:last-child {
  border-right: none;
}

.footLink {
  word-break: keep-all;
}

.footLink a {
  color: #333333;
  margin-right: 10px;
  border-right: 1px solid #ddd;
  padding-right: 10px;
}

.footLink a:last-child {
  border: none;
}

.footLink a:hover {
  color: #01a0fc;
}

.company_logo {
  border: 1px solid #333;
  padding: 4px 10px;
  line-height: 1;
  display: inline-block;
  max-width: 100px;
  text-align: center;
  background: #fff;
}

.company_logo img {
  width: 100%;
}

.company_share {
  margin: 0 0 0 auto;
  color: #333;
  font-size: 40px;
  width: 42px;
  display: inline-block;
}

.company_share img {
  width: 100%;
}

.maxTxt {
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.maxTxt2 {
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.maxTxt3 {
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #6c757d !important;
}


/* 제품소개 페이지 */


.phone-banner-tit{ font-size: 38px; line-height: 1.6; color: #111; font-family:'Noto Sans KR', sans-serif; font-weight: 900;}
.phone-banner-tit span{color: #01a0fc;}
.phone-banner-txt{ margin-top: 20px; color: #111; line-height: 1.6; font-size:18px; font-weight: 300;}

.banner-some{position: absolute; bottom: 100px; left: 50%; margin-left: -242px; display: flex; z-index: 999;}
.banner-some a{ border-radius: 100%; margin: 0 10px; border: 1px solid rgba(0, 0, 0, 0.1); cursor: pointer; overflow: hidden;}
.tab-user a{
    padding: 4px; 
    color: #999; 
    font-size: 14px; 
    width: 120px; 
    display: inline-block; 
    margin-right: 10px; 
    text-align: center; 
    background: rgba(255, 255, 255, .9);
    border-radius: 20px; 
    box-shadow: 2px 2px 5px rgb(0 0 0 / 5%); 
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border: 1px solid #fff;
    overflow: hidden;
}
.tab-user a:hover{ border: 1px solid rgba(0, 0, 0, 0.2); color: #545454; background: rgba(255, 255, 255, 1);}
.tab-user a.active{border: 1px solid rgba(0, 0, 0, 0.2); color: #545454; background: rgba(255, 255, 255, 1);}

.roller {
    height: 25px;
    line-height: 25px;
    position: relative;
    overflow: hidden;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #spare-time {
    font-size: 14px;
    margin-left: 50px;
    
  }
  #spare-time2 {
    font-size: 14px;
    margin-left: 50px;
   
  }
  
  .roller #rolltext {
  
    position: absolute;
    top: 0;
    left: 25px;
    animation: slide 30s infinite;
  }
  
  @keyframes slide {
  
    0% {
      left: 25px;
    }
    10% {
        left: 25px;
    }
    20% {
        left: -92px;
    }
    30% {
        left: -92px;
    }
    40% {
        left: -192px;
    }
    50% {
        left: -192px;
    }
    60% {
        left: -92px;
    }
    70% {
        left: -92px;
    }
    80% {
        left: 25px;
    }
    90% {
        left: 25px;
    }

  }


/* .accent-link{border: 2px solid #01a0fc; border-radius: 20px !important;} */
.accent-link:hover{background:#01a0fc !important; color: #fff !important;}
.productTit{color: #333; font-size: 30px; line-height:1.2; font-weight: 900;}
.productTit span{color: #01a0fc;}
.productTit .txtPink{color: #01a0fc;}
.productTit .txtGreen{color:#01a0fc;}
.productTxt{color: #333; font-size: 20px; line-height: 1.5; font-weight: 100; margin-top: 50px;}
.productTxt span{font-weight: 500; }
.min660{min-height: 660px;}
.sessonAni01{width: 500px !important; height: 600px !important; border: none; margin: 0 auto;}
.sessonAni01Pc{width: 500px !important; height: 600px !important; border: none; margin: 0 auto; display: block; }
.sessonAni01App{width: 294px !important; height: 600px !important; border: none; margin: 0 auto; display: none;}
.sessonAni02{width: 430px; height: 614px; border: none;}
.sessonAni03{width: 430px; height: 600px; border: none;}
.sessonAni04{width: 300px; height: 600px; border: none;}
.sessonAni05{width: 500px; height: 600px; border: none;}
.solutionImg{ border: 2px solid #c8c8c8; position: relative; background: #ededed; padding: 20px; border-radius: 20px; width: 100%; max-width: 900px; margin: 30px auto; margin-bottom: 80px;}
.solutionImg img{width: 100%; border-radius: 20px;}
.solutionImg .btnImg{ position: absolute; left: 20px; top: 0; margin-top: -22px;}
.solutionImg .btnImg span{display: inline-block; width: 30px; height: 5px; background: #c8c8c8; margin-left: 20px;}
.solutionAni01, .solutionAni02, .solutionAni03, .solutionAni04, .solutionAni02_1, .solutionAni02_2, .solutionAni02_3, .solutionAni02_4, .solutionAni02_5, .solutionAni02_6,.solutionAni02_7,.solutionAni02_8,.solutionAni02_9,.solutionAni02_10,.solutionAni02_11, .solutionAni05, .solutionAni06 {position: relative; display: none;}
.aniTxtArea{
    display: none;
    background: rgba(0, 0, 0, 0.4); 
    border-radius: 30px; 
    font-size: 14px; 
    color: #fff; 
    position: absolute; z-index: 999; 
    top: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 20px;
    font-weight: 300;

}


.productTit{color: #333; font-size: 60px; line-height:1.2; font-weight: 900;}
.productTit span{color: #01a0fc;}
.productTit .txtPink{color: #01a0fc;}
.productTit .txtGreen{color:#01a0fc;}

.productTxt{color: #333; font-size: 20px; line-height: 1.5; font-weight: 100; margin-top: 50px;}
.productTxt span{font-weight: 500; }


.min660 {
  min-height: 660px;
}

.sessonAni01Pc{width: 500px !important; height: 600px !important; border: none; margin: 0 auto; display: block; }
.sessonAni01App{width: 294px !important; height: 600px !important; border: none; margin: 0 auto; display: none;}
.sessonAni02{width: 430px; height: 614px; border: none;}
.sessonAni03{width: 430px; height: 600px; border: none;}
.sessonAni04{width: 300px; height: 600px; border: none;}
.sessonAni05{width: 500px; height: 600px; border: none;}


.solutionImg {
  border: 2px solid #c8c8c8;
  position: relative;
  background: #ededed;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 900px;
  margin: 30px auto;
  margin-bottom: 80px;
}

.solutionImg img {
  width: 100%;
  border-radius: 20px;
}

.solutionImg .btnImg {
  position: absolute;
  left: 20px;
  top: 0;
  margin-top: -22px;
}

.solutionImg .btnImg span {
  display: inline-block;
  width: 30px;
  height: 5px;
  background: #c8c8c8;
  margin-left: 20px;
}

.solutionAni01,
.solutionAni02,
.solutionAni03,
.solutionAni04,
.solutionAni04_1,
.solutionAni04_2,
.solutionAni04_3,
.solutionAni04_4,
.solutionAni04_5,
.solutionAni04_6,
.solutionAni05,
.solutionAni06 {
  position: relative;
  display: none;
}

.aniTxtArea {
  display: none;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  font-size: 14px;
  color: #fff;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 20px;
  font-weight: 300;

}


/* 버튼효과 */
.aniBtn {
  z-index: 999;
  position: absolute;
  top: 56px;
  left: 40px;
}

.aniBtn2 {
  z-index: 999;
  position: absolute;
  top: 42px;
  display: none;
  left: 160px;
}

.aniBtn3 {
  z-index: 999;
  position: absolute;
  top: 89px;
  left: 260px;
  display: none;
}

.aniBtn4 {
  z-index: 999;
  position: absolute;
  top: 90px;
  left: 260px;
  display: none;
}

.sBtn01 {
  width: 30px;
  height: 30px;
  background: rgba(64, 154, 225, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  font-family: sans-serif;
  text-transform: uppercase;
  cursor: pointer
}

.sBtn02 {
  width: 30px;
  height: 30px;
  background: rgba(64, 154, 225, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  margin-top: 22px;
  font-family: sans-serif;
  text-transform: uppercase;
  cursor: pointer
}

.sBtn03 {
  width: 30px;
  height: 30px;
  background: rgba(64, 154, 225, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  margin-top: 26px;
  font-family: sans-serif;
  text-transform: uppercase;
  cursor: pointer
}

.sBtn04 {
  width: 30px;
  height: 30px;
  background: rgba(64, 154, 225, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  margin-top: 24px;
  font-family: sans-serif;
  text-transform: uppercase;
  cursor: pointer
}

.sBtn05 {
  width: 30px;
  height: 30px;
  background: rgba(64, 154, 225, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  margin-top: 26px;
  font-family: sans-serif;
  text-transform: uppercase;
  cursor: pointer
}

.sBtn06 {
  width: 30px;
  height: 30px;
  background: rgba(64, 154, 225, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  margin-top: 24px;
  font-family: sans-serif;
  text-transform: uppercase;
  cursor: pointer
}

.sBtn01:hover {
  animation: animate-pulse 2s infinite;
}

.sBtn02:hover {
  animation: animate-pulse 2s infinite;
}

.sBtn03:hover {
  animation: animate-pulse 2s infinite;
}

.sBtn04:hover {
  animation: animate-pulse 2s infinite;
}

.sBtn05:hover {
  animation: animate-pulse 2s infinite;
}

.sBtn06:hover {
  animation: animate-pulse 2s infinite;
}

@keyframes animate-pulse {

  0% {
    box-shadow: 0 0 0 0 rgba(64, 154, 225, 0.7), 0 0 0 0 rgba(64, 154, 225, 0.7);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(64, 154, 225, 0.0), 0 0 0 0 rgba(64, 154, 225, 0.7);
  }

}

.tBtn01 {
  width: 30px;
  height: 30px;
  background: rgba(206, 70, 36, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  font-family: sans-serif;
  text-transform: uppercase;
  cursor: pointer
}

.tBtn02 {
  width: 30px;
  height: 30px;
  background: rgba(206, 70, 36, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  margin-left: 84px;
  font-family: sans-serif;
  text-transform: uppercase;
  cursor: pointer
}

.tBtn03 {
  width: 20px;
  height: 20px;
  background: rgba(206, 70, 36, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 20px;

  font-family: sans-serif;
  text-transform: uppercase;

  cursor: pointer
}

.tBtn04 {
  width: 20px;
  height: 20px;
  background: rgba(206, 70, 36, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
  margin-top: 7px;
  font-family: sans-serif;
  text-transform: uppercase;

  cursor: pointer
}

.tBtn05 {
  width: 20px;
  height: 20px;
  background: rgba(206, 70, 36, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
  margin-top: 7px;
  font-family: sans-serif;
  text-transform: uppercase;

  cursor: pointer
}

.tBtn06 {
  width: 20px;
  height: 20px;
  background: rgba(206, 70, 36, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
  margin-top: 7px;
  font-family: sans-serif;
  text-transform: uppercase;

  cursor: pointer
}

.tBtn07 {
  width: 20px;
  height: 20px;
  background: rgba(206, 70, 36, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
  font-family: sans-serif;
  text-transform: uppercase;

  cursor: pointer
}

.tBtn08 {
  width: 20px;
  height: 20px;
  background: rgba(206, 70, 36, .3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
  margin-top: 7px;
  font-family: sans-serif;
  text-transform: uppercase;

  cursor: pointer
}

.tBtn09 {
  width: 20px;
  height: 20px;
  background: rgba(206, 70, 36, 0.3);
  border-radius: 50%;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
  margin-top: 7px;
  font-family: sans-serif;
  text-transform: uppercase;

  cursor: pointer
}

.tBtn01:hover {
  animation: animate-pulse2 2s infinite;
}

.tBtn02:hover {
  animation: animate-pulse2 2s infinite;
}

.tBtn03:hover {
  animation: animate-pulse2 2s infinite;
}

.tBtn04:hover {
  animation: animate-pulse2 2s infinite;
}

.tBtn05:hover {
  animation: animate-pulse2 2s infinite;
}

.tBtn06:hover {
  animation: animate-pulse2 2s infinite;
}

.tBtn07:hover {
  animation: animate-pulse2 2s infinite;
}

.tBtn08:hover {
  animation: animate-pulse2 2s infinite;
}

.tBtn09:hover {
  animation: animate-pulse2 2s infinite;
}

@keyframes animate-pulse2 {
  0% {
    box-shadow: 0 0 0 0 rgba(206, 70, 36, 0.7), 0 0 0 0 rgba(206, 70, 36, 0.7);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(206, 70, 36, 0.0), 0 0 0 10px rgba(206, 70, 36, 0);
  }
}


/* 솔루션 이미지 페이드인 */

.fade-in-box {
  display: block;

}

.fade-in-box img {
  animation: fadein .5s;
  -moz-animation: fadein .5s;
  /* Firefox */
  -webkit-animation: fadein .5s;
  /* Safari and Chrome */
  -o-animation: fadein .5s;
  /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
    margin-right: -200px;
  }

  to {
    opacity: 1;
    margin-right: 0px;
  }
}

@-moz-keyframes fadein {

  /* Firefox */
  from {
    opacity: 0;
    margin-right: -200px;
  }

  to {
    opacity: 1;
    margin-right: 0px;
  }
}

@-webkit-keyframes fadein {

  /* Safari and Chrome */
  from {
    opacity: 0;
    margin-right: -200px;
  }

  to {
    opacity: 1;
    margin-right: 0px;
  }
}

@-o-keyframes fadein {

  /* Opera */
  from {
    opacity: 0;
    margin-right: -200px;
  }

  to {
    opacity: 1;
    margin-right: 0px;
  }
}

@media (max-width: 1600px) {
  .auth .auth_left {
    width: 100%;
  }

  .auth .auth_right {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .page-header .left {
    display: none;
  }

  .page-header .right {
    margin-top: 0;
    justify-content: end;
    margin-left: auto;
    text-align: right;
  }
}

@media (max-width: 1000px) {
  .PcRightBoard {
    border-right: none;
    padding-right: 7.5px;
    margin-bottom: 20px;
  }

  .solutionImg {
    border: 1px solid #c8c8c8;
    position: relative;
    background: #ededed;
    padding: 10px;
    border-radius: 20px;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }

  .solutionImg img {
    width: 100%;
    border-radius: 10px;
  }

  .solutionImg .btnImg {
    position: absolute;
    left: 20px;
    top: 0;
    margin-top: -22px;
  }

  .solutionImg .btnImg span {
    display: inline-block;
    width: 20px;
    height: 3px;
    background: #c8c8c8;
    margin-left: 10px;
  }

  .aniBtn {

    top: 38px;
    left: 26px;
  }

  .sBtn02 {
    margin-top: 18px;
  }

  .sBtn03 {
    margin-top: 18px;
  }

  .sBtn04 {
    margin-top: 18px;
  }

  .sBtn05 {
    margin-top: 18px;
  }

  .sBtn06 {
    margin-top: 18px;
  }

  .aniBtn2 {
    top: 28px;
    display: none;
    left: 126px;
  }

  .tBtn02 {
    margin-left: 70px;
  }

  .aniBtn3 {
    top: 69px;
    left: 200px;
    display: none;
  }

  .tBtn04 {
    margin-top: 4px;
  }

  .tBtn05 {
    margin-top: 4px;
  }

  .tBtn06 {
    margin-top: 4px;
  }

  .tBtn08 {
    margin-top: 4px;
  }

  .tBtn09 {
    margin-top: 4px;
  }

  .aniBtn4 {
    top: 69px;
    left: 200px;
    display: none;
  }

}

@media (max-width: 768px) {
  .aniTxtArea {
    font-size: 12px;
    padding: 5px 10px;
  }

  .solutionTxt {
    margin-bottom: 20px;
  }

  .mobileFlex {
    display: flex;
  }

  .mobileFlex button {
    font-size: 14px;
    padding: 0 10px;
    margin: 0 10px;
    height: 60px;
  }

  .solutionImg {
    border: 1px solid #c8c8c8;
    position: relative;
    background: #ededed;
    padding: 10px;
    border-radius: 20px;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }

  .solutionImg img {
    width: 100%;
    border-radius: 10px;
  }

  .solutionImg .btnImg {
    position: absolute;
    left: 20px;
    top: 0;
    margin-top: -22px;
  }

  .solutionImg .btnImg span {
    display: inline-block;
    width: 20px;
    height: 3px;
    background: #c8c8c8;
    margin-left: 10px;
  }

  .aniBtn,
  .aniBtn2,
  .aniBtn3,
  .aniBtn4 {
    display: none !important;
  }

  .mobileOrder1 {
    order: 1;
  }

  .mobileOrder2 {
    order: 2;
  }

  .product-area {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    word-break: keep-all;
  }

  .productTit {
    color: #fff;
    font-size: 30px;
    line-height: 1.2;
    font-weight: 900;
  }

  .productTit span {
    color: #01a0fc;
  }

  .productTit .txtPink {
    color: #ffb8e3;
  }

  .productTit .txtGreen {
    color: #bdff7d;
  }

  .productTxt {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 100;
    margin-top: 20px;
  }

  .productTxt span {
    font-weight: 500;
  }

  .sessonAni01Pc {
    width: 500px !important;
    height: 600px !important;
    border: none;
    margin: 0 auto;
    display: none;
  }

  .sessonAni01App {
    width: 294px !important;
    height: 600px !important;
    border: none;
    margin: 0 auto;
    display: block;
  }

  .sessonAni02 {
    width: 300px !important;
    height: 600px !important;
    border: none;
    margin: 0 auto;
  }

  .sessonAni03 {
    width: 300px !important;
    height: 600px !important;
    border: none;
    margin: 0 auto;
  }

  .sessonAni04 {
    width: 300px !important;
    height: 600px !important;
    border: none;
    margin: 0 auto;
  }

  .min660 {
    min-height: 660px;
  }


  .min660 {
    min-height: auto;
  }

  .answerMobilebg {
    padding: 0;
    padding-top: 10px;
    background: #333 !important;
    background: linear-gradient(to bottom, #333 0%, rgba(255, 255, 255, .0) 88%) !important;
    display: none;
  }

  .PhoneArea .TackBtnArea {
    position: fixed;
    bottom: 50px;
    margin-left: 0;
    left: auto;
    right: 10px;
    z-index: 99;
    cursor: pointer;
  }

  .PhoneArea .TackBtnArea img {
    border: 4px solid #01a0fc;
    vertical-align: middle;
  }

  .PhoneArea .TackBtnArea span {
    background: #01a0fc;
    color: #fff;
    font-size: 14px;
    border-radius: 20px 0 0 20px;
    padding: 6px 15px;
    margin-right: -9px;
    vertical-align: middle;
  }

  .PhoneArea .TackBtnArea .avatar {
    width: 50px;
    height: 50px;
  }

  .answer-area {
    padding-top: 0 !important;
  }

  .mobileHidden {
    display: none;
  }

  .nopt {
    padding-top: 0 !important;
  }

  .job-area {
    min-height: auto
  }

  .maxTxt {
    width: 100%;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .maxTxt2 {
    width: 100%;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .maxTxt3 {
    width: 100%;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .writeTime {
    line-height: 1.5;
  }

  .writeTime span {
    display: block;
  }

  .hidden-xs {
    display: none;
  }

  .table.table_custom tr th:first-child,
  .table.table_custom tr td:first-child {
    border-radius: 0;
  }

  .table.table_custom tr th:last-child,
  .table.table_custom tr td:last-child {
    border-radius: 0;
  }

  .bar-round {
    margin-top: 0 !important;
  }

  .header-area {
    background: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  }

  .footLink {
    word-break: keep-all;
    margin-top: 10px;
  }

  .auth .card-top .card-title {
    font-size: 30px;
  }

  .auth .card-top .card-title2 {
    font-size: 28px;
  }

  .card-top {
    margin-bottom: 0;
  }

  .auth .auth_left {
    width: 100%;
  }

  .auth .auth_right {
    display: none;
  }

  .textColor {
    display: none;
  }

  .PcRightBoard {
    border-right: none;
    padding-right: 7.5px;
    margin-bottom: 20px;
  }

  .OptionBox {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
  }

  .auth .logo {
    position: relative;
    left: 10px;
    top: 20px;
    height: 80px;
  }

  .auth {
    display: block;
    min-height: 100vh !important;
    height: auto;
    align-items: center;
  }

  .shareLink {
    position: absolute;
    right: 10px;
    top: 25px;
  }

  .footLink a {
    margin-right: 4px;
    padding-right: 4px;
    font-size: 12px;
  }
}

@media (max-width: 680px) {
  .searchBox {
    display: block;
    padding: 0 15px;
  }

  .searchBox .writeBtnArea {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1600px) {
  .auth .auth_left {
    width: calc(100% - 450px);
    text-align: center;

  }

  .auth .auth_left .card {
    right: 0;
  }

  .auth .auth_right {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 450px;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 992px) {
  .auth .auth_left .card {
    right: auto
  }
}

/*************** 추가 박현정 ***************/
.img-fit{
  object-fit:cover;
}
.form-control{
  height:50px;
}
.modal-header .close{
  padding:0 !important;
  margin: 0 0 -1rem auto !important;
}

.position-re{
  position:relative !important;
}

.generic-accordion .card{
  word-break:break-all !important;
}
.button-solid{
  padding:0;
  font-size:23px;
  font-weight: lighter;
  color:#333 !important;
}
.button-solid:before{
  font-family: 'fontAwesome';
    content:'\f0c9';
}
.visually-hidden{display:none;}
.btn-menu{width:30px;height:30px;padding:0;text-indent:-999em;}

/* 채팅창 */
.chat-header .title{
  font-size:15px;
  color:#222;
}

.chatPoint{display:inline-block;padding:0 3px;}

.chat-body-message-left .chat-body-message-content{
  display:flex;
  padding:20px 0;
}
.chat-body-message-right .chat-body-message-content{
  padding:15px 0;
}
.chat-body-message-center .chat-body-message-content{
  padding:20px;
}
.chat-body-message-center .chat-body-message-content .chat-center-text{
  background:transparent;
  color:#222;
  font-weight:400;
}
#sent-chat-area.form-control{
  height:auto !important;
}

.chat-body-message-right .chat-body-message-content-text .chat-lefted-text{
  background:#c1daf2 !important;
  margin-right:8px;
  overflow:visible;
} 

.chat-customize .chat-container{
  position:relative;
  padding-top:40px;
}

.chat-customize.light-blue .chat-lefted-text{
  position:relative;
}

.chat-customize .chat-lefted-text:before{
  content:'';
  display:inline-block;
  position:absolute;
  top:10px;
  left:-8px;
  width:0;
  height:0;
  border-top:7px solid #fff;
  border-left:10px solid transparent;
  border-right:10px solid transparent;
  overflow: visible;
}


.chat-customize .chat-body-message-right .chat-lefted-text:before{
   left:inherit;
   top:10px;
   right:-8px;
   border-top-color:#c1daf2;
}

.chat-body-noti{
  z-index:99;
  position:absolute;
  top:10px;
  left:0;
  /* transform: translateX(-50%); */
  width:calc(100% - 10px - 10px);
  margin:0 10px;
  padding:0 10px;
  border-radius: 10px;
  background-color: rgba( 0, 0, 0, 0.5 );
  font-size:15px;
  color:#fff;
}

.chat-body-noti .titArea{
  display:flex;
  /* justify-content: space-between; */
  align-items: center;
  height:40px;
  line-height: 1.1;
}

.chat-body-noti .titArea .q{
  position:relative;
  display:inline-block;
  width:22px;
  height:22px;
  background:#09a8fa;
  border-radius:40%;
}
.chat-body-noti .titArea .q:after{
  content:'Q';
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

.chat-body-noti .titArea .tit{
  width:80%;
  margin:0 10px;
}
.chat-body-noti .titArea .arr{
  padding:0;
}
.chat-body-noti .titArea .arr i{
  position:relative;
  display:inline-block;
  width:20px;
  height:20px;
  margin-top:5px;
  border:1px solid #fff;
  border-radius: 10px;
}
.chat-body-noti .titArea .arr i::before{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  color:#fff;
}

.chat-body-noti .bodyArea{
  /*display:none;*/
  margin:0 30px;
  padding-bottom:10px;
  line-height:1.3;
}

.anwserPop #rightMenuTitle{
  display:none !important;
}
.anwserPop #chat-sidebar{
  top:111.8px;
  background:#fff
}

.PcBg .phone_over .TackBtnArea{
  position:absolute;
  bottom:30px;
  right: 3px;
  left: auto;
  display: inline-flex;
}

.chat-customize.white{
  background:#e3ebf2;
  height: auto !important;
}
.chat-customize.white .ChatOption{
  background:#ffd800;
}
.chat-customize.white .chat-container{
  padding-top:0;
  background:#e3ebf2;
}
.chat-customize.white .chat-body-message-right .chat-lefted-text:before{
  right:0;
}

.font-14{
  font-size:14px;
}
.bold{
  font-weight:600;
}
.btn_use{
  width:73px;
  height: 28px;
  line-height: 26px;
  font-size: 12px;
  color: #000;
  background: #fff;
  border: 1px solid #000;
  border-radius: 8px;
}

.btn.btn_flowing{
  height:25px;
  background: url(../images/img_flowing.png) no-repeat center;
  background-size: cover;
}
.chat-body-noti .titArea .arr i.close{
  margin-top:0;
  font-weight:300;
}


/* 플랜 구성 css */
.plan-case{display: block;}
.plan-area{width: 100%; max-width: 1200px; margin: 0 auto;}
.planBox{width: 100%; min-height: 190px; background: #fff; text-align: left;  border: 1px solid #fff; color: #333; padding: 15px; line-height: 1.6; cursor: pointer;}
.mt-50px{margin-top: 50px;}
.planBox .titTxt{font-size: 18px; font-weight:900; }
.planBox .infoTxt{font-size: 12px; font-weight: 500;}
.txtblue{color: #01a0fc;}
.planMaxDay{text-align: center; font-size: 12px;}
.planBox .priceTxt{font-size: 18px; font-weight: 900; text-align: center;}
.planBox .priceTxt span{ font-size: 14px; font-weight: 800; display: inline-block; margin-right: 10px; padding: 0 5px; text-align: center; position: relative;}
.planBox .priceTxt span::before{content: ''; border-top: 2px solid #333; position: absolute; width: 100%; left: 0; top: 10px;}
.planBox:hover{border: 1px solid #01a0fc;}
.planBox:hover .titTxt{color: #01a0fc;}
.plan-On{border: 1px solid #01a0fc;}
.plan-On .titTxt{color: #01a0fc;}
.planBox .titTxt input {
    position: absolute;
    visibility: hidden;
}
.planBox .titTxt label{
    cursor: pointer;
    display: block;
    position: relative;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: 500;
    color: #0d233e;
}

.planBox .titTxt label:before {
    background-color: #fff;
    border: 2px solid #ceced0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 18px;
    height: 18px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.planBox .titTxt input:checked ~ label:before {
    border-color: #2d86eb;
}
.planBox .titTxt label:after {
    background-color: #ceced0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    content: "";
    position: absolute;
    left: 5px;
    top: 5px;
    width: 8px;
    height: 8px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}
.planBox .titTxt input:checked ~ label:after {
    background-color: #2d86eb;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.planStage{font-size: 12px;}
.planBlue{display: inline-block; background: #01a0fc; width: 8px; height: 8px; border-radius: 8px; }
.planRed{display: inline-block; background: #ff8c2d; width: 8px; height: 8px; border-radius: 8px; }
.planGreen{display: inline-block; background: #31f965; width: 8px; height: 8px; border-radius: 8px; }

.planTit{ width: 100%;}
.planTit ul{margin: 0; padding: 0; display: flex;}
.planTit ul li{list-style: none; padding: 0 5px; line-height: 42px; height: 42px; width: 100%; text-align: center; background: #fff; width: 20%;}
.planTit .mainTit{font-size: 16px; font-weight: 600; color: #333; background: none; text-align: left;}
.planTit .subTit{font-size: 14px; font-weight: 400; color: #333; background: none; text-align: left; padding-right: 30px; margin-right: 10px;}
.underLine{position: relative; width: 130px;}
.underLine::before{content: ''; border-top: 1px dotted #545454; position: absolute; width: 100%; left: 0; bottom: 5px;}


.planCheckArea{ width: 100%; background: #fff;}
.planCheckArea ul{margin: 0; padding: 0;  }
.planCheckArea ul li{list-style: none; padding: 0 5px; line-height: 42px; text-align: center; background: #fff; height: 42px;}
.planCheckArea ul li i{color: #999; font-size: 20px;}
.planCheckArea .noneArea{ background: #f3f3f3;;}
.ver-txt{font-weight: 900; padding: 0 5px; margin-top: 20px; margin-bottom: 10px; color: #111;}
.ver-txt::before{content: ''; border-top: 1px solid #ddd; position: absolute; width: 100%; left: 0; bottom: 0px;}
.ver-txt::after{content: ''; border-top: 1px solid #fff; position: absolute; width: 100%; left: 0; bottom: 0px; margin-bottom: -1px;}

.mt-40px{margin-top: 40px !important;}

.gray40px{height: 40px; background: #f3f3f3 !important;}



/* 답변 안내 */

.replyTit{padding-left: 50px; position: relative;}
    .replyTit span{position: absolute; left: 5px; top: 5px; color: #333; font-size: 32px; font-family: Arial, Helvetica, sans-serif; border-right: 1px solid #ddd; padding-right: 15px; height: 80px;}
.reply-area .listTitle{font-family: 'Noto Sans KR', sans-serif; font-size: 30px; font-weight: 300; word-break: keep-all;}
.reply-area .listTitle b{font-weight: 700; color: #01a0fc;}
.replyImg{border-radius: 20px; overflow: hidden;}
.replyImg img{width: 100%;}
.replyImgArea{margin-left: 100px;}
.reply-area .imgText{font-family: 'Noto Sans KR', sans-serif; font-size: 34px; font-weight: 300; color: #333; line-height: 50px;}
.reply-area .imgText b{font-weight: 900;}
.reply-area h5{font-family: 'Noto Sans KR', sans-serif; font-size: 18px; font-weight: 300; color: #333;}
.btnBic{background: #fff; border-radius: 30px; font-family: 'Noto Sans KR', sans-serif; padding: 18px 34px;}

.adressArea{width: 100%; max-width: 900px; margin: 0 auto;}
.adressArea span{margin-right: 1rem;}
.adressArea .form-control{border-radius: 12px !important;}
.adressArea .btn{border-radius: 12px !important; font-family: 'Noto Sans KR', sans-serif; font-weight: 300;}
.reply-area .card-item{border-radius: 20px;}
.tagImgArea{display: flex; width: 100%; max-width: 1200px; margin:0 auto; padding: 50px; text-align: center;}
.tagImgArea .tagitem{margin: 20px;}
.tagImgArea .tagitem .tagitemImg{border-radius: 20px; overflow: hidden; width: 100%; max-width: 364px;}
.tagImgArea .tagitem .tagitemImg img{width: 100%;}
.tagImgArea .tagitem p {font-family: 'Noto Sans KR', sans-serif; font-weight: 500; color: #333; padding:10px 5px;}
.tagImgArea .tagitem p img{width: 70px;}
.accodianArea{background: #01a0fc;}
.accodianArea .listTitle {color: #fff !important; font-weight: 500;}
.accodianArea h5{color: #fff !important; font-weight: 500;}
.accodianArea .card{margin-bottom: 12px; border-radius: 20px;}
.accodianArea .card-header .btn{font-family: 'Noto Sans KR', sans-serif; font-weight: 500; padding: 25px;}


/* 맴버관리를 위한 다양한기능 설명 */
.shadow-effect {
  background: #fff;
  padding:0;
  overflow: hidden;
  border-radius: 14px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.10);
  height: 190px;
}
#customers-testimonials .shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 300;
}
.testimonial-name {
  margin: 2px auto 0;
  display: table;
  width: 100%;
  padding: 5px 10px;
  border-radius: 12px;
  text-align: left;
  color: #333;
}
.testimonial-name span{background: #3190E7; color: #fff; padding: 1px 5px; font-size: 12px; border-radius: 10px; margin-right: 5px;}
#customers-testimonials .item {
  text-align: center;
  padding: 30px;
  margin-bottom:20px;
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#customers-testimonials .owl-item.active.center .item {
  opacity: 1;
  -webkit-transform: scale3d(1.0, 1.0, 1);
  transform: scale3d(1.0, 1.0, 1);
}
#customers-testimonials .owl-carousel .owl-item img {
  transform-style: preserve-3d;
  max-width: 90px;
  margin: 0 auto 17px;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
  background: #3190E7;
  transform: translate3d(0px, -50%, 0px) scale(0.7);
}
#customers-testimonials.owl-carousel .owl-dots{
display: inline-block;
width: 100%;
text-align: center;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot{
display: inline-block;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
background: #ccc;
display: inline-block;
height: 20px;
border-radius: 100%;
margin: 0 2px 5px;
transform: translate3d(0px, -50%, 0px) scale(0.3);
transform-origin: 50% 50% 0;
transition: all 250ms ease-out 0s;
width: 20px;
}

/* 상품 안내 */
.bgblue{background: #50a5f1;}
.bgSession1{background: #fff;}
.bgSession2{background: none;}
.bgSession3{background:#fff;}
.mainImg{width: 100%; text-align: center;}
.mainImg img{width: 100%;}

.product-area .listTitle{font-family: 'Noto Sans KR', sans-serif; font-size: 38px; font-weight: 300;}
.product-area .listTitle span{font-weight: 700; color: #01a0fc; font-size: 52px;}
.product-area .listTitle b{font-weight: 700; color: #333;}
.productImg img{width: 100%; max-width: 900px; }
.productImg2 img{width: 100%; max-width: 377px; }
.productDown{ margin-left: 110px; margin-top: 50px; margin-right: 30px; }
.solutionTit{ font-size: 24px; color: #333; margin-bottom: 20px; font-weight: 600; line-height: 40px; font-family: 'Noto Sans KR', sans-serif;}
.solutionTxt{ font-size: 16px; color: #333; margin-bottom: 60px; font-weight: 400; font-family: 'Noto Sans KR', sans-serif;}
.btndown{display: block; width: 100%; max-width: 240px;  font-weight: 400; font-family: 'Noto Sans KR', sans-serif; color: #333;}
.mbm{ margin-bottom: -24px;}

.btn-area3 .link-app {
    display: inline-block;
    overflow: hidden;
    width: 80px;
    height: 80px;
    font-size: 18px;
    margin-right: 14px;
    line-height: 42px;
    border-radius: 80px;
    text-align: center;
    vertical-align: middle;
    transition: all 0.5s ease;
}
.btn-area3 .link-app:hover {
    background: #fff;
    box-shadow: 4px 8px 12px 0 rgb(0 0 0 / 10%);
}
.btn-area3 .tit-ios {
    width: 34px;
    height: 34px;
    display: inline-block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url(../images/ic_app.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
}
.btn-area3 .tit-android {
    width: 34px;
    height: 34px;
    display: inline-block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url(../images/ic_gl.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
}
.bgyello{background: #f6f6f6 !important;
    background: radial-gradient(circle, #f6f6f6 0%, white 88%) !important;}

    /*이용사례 */
.bgUser{background: url(/public/images/useinfoBg.png) no-repeat center; min-height: 900px; position: relative; width: 100%; max-width: 1580px; display: block;}
.bgUser2{background: url(/public/images/useinfoBg.png) no-repeat center;  position: relative;  display: none;}
.useTxt{font-size: 14px; color: #333;}
.useTxt p{font-size: 16px; font-weight: bold; margin: 0;}
.useTxt p a{color: #333;}
.useTxt p a:hover{color:#01a0fc}
.useTxt P i{vertical-align: middle;}

.userMan01{position: absolute; right: 60px; bottom: 160px; background: url(/public/images/useMan01.png) no-repeat; background-size: cover; width: 400px; height: 759px; z-index: 96; }
.userMan01 .useTxt{bottom: 0; position: absolute; margin-bottom: -60px; left: 50%; margin-left: -50px; }
.userMan02{position: absolute; left: 50%; margin-left: 150px; bottom: 160px; background: url(/public/images/useMan02.png) no-repeat; background-size: cover; width: 360px; height: 324px; z-index: 97;}
.userMan02 .useTxt{bottom: 0; position: absolute; margin-bottom: -60px; left: 50%; margin-left: -20px; }
.userMan03{position: absolute; left: 50%; margin-left: -80px; bottom: 130px; background: url(/public/images/useMan03.png) no-repeat; background-size: cover; width: 340px; height: 345px; z-index: 98;}
.userMan03 .useTxt{bottom: 0; position: absolute; margin-bottom: -34px; left: 50%; margin-left: -150px; }
.userMan04{position: absolute; left: 50%; margin-left: -360px; bottom: 160px; background: url(/public/images/useMan04.png) no-repeat; background-size: cover; width: 320px; height: 550px; z-index: 94;}
.userMan04 .useTxt{bottom: 0; position: absolute; margin-bottom: -60px; left: 50%; margin-left: -90px; }
.userMan05{position: absolute; left: 50%; margin-left: -550px; bottom: 160px; background: url(/public/images/useMan05.png) no-repeat; background-size: cover; width: 280px; height: 297px; z-index: 96;}
.userMan05 .useTxt{bottom: 0; position: absolute; margin-bottom: -60px; left: 50%; margin-left: -90px; }
.userMan06{position: absolute; left: 80px; bottom: 160px; background: url(/public/images/useMan06.png) no-repeat; background-size: cover; width: 230px; height: 416px; z-index: 98;}
.userMan06 .useTxt{bottom: 0; position: absolute; margin-bottom: -60px; left: 50%; margin-left: -110px; }

@media (max-width: 1200px){
  .bgUser{display: none;}
  .bgUser2{display: block; background-size: contain;}
  .userbox .useTxt{  left:auto; margin-bottom: 20px;}

  .man01{background: url(/public/images/useMan01.png) no-repeat top center; background-size: 70%; width: 100%; height: 180px; border-radius: 20px; background-color: #f3f3f3; z-index: 96; }

  .man02{background: url(/public/images/useMan02.png) no-repeat top center; background-size: 70%; width: 100%; height: 180px; border-radius: 20px; background-color: #f3f3f3; z-index: 96; }

  .man03{ background: url(/public/images/useMan03.png) no-repeat top center; background-size: 70%; width: 100%; height: 180px; border-radius: 20px; background-color: #f3f3f3; z-index: 96; }

  .man04{ background: url(/public/images/useMan04.png) no-repeat top center; background-size: 70%; width: 100%; height: 180px; border-radius: 20px; background-color: #f3f3f3; z-index: 96; }

  .man05{ background: url(/public/images/useMan05.png) no-repeat top center; background-size: 70%; width: 100%; height: 180px; border-radius: 20px; background-color: #f3f3f3; z-index: 96; }

  .man06{ background: url(/public/images/useMan06.png) no-repeat top center; background-size: 70%; width: 100%; height: 180px; border-radius: 20px; background-color: #f3f3f3; z-index: 96; }
}
.review-avatar-area{ width: 100%; font-size: 16px; color: #333; font-weight: bold;}
.review-avatar-area img{width: 100px; height: 100px; border-radius: 100px; box-shadow: 2px 2px 5px rgb(0 0 0 / 10%); margin-right: 10px;}
.review-area{
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;   
    -ms-flex-align: center!important;
    align-items: center!important; 
}
.review-area img{width: 100%; max-width: fit-content;}

@media (max-width: 768px){
    .review-area{
        display: flex;     
        -ms-flex-align: center!important;
        align-items: center!important; 
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important;
    }
    .review-area .arrow-area{ text-align: center; width: 100%; margin: 10px 0; transform:rotate(90deg); }
    .review-area .arrow-area img{width: auto;}
    .review-area .col{ width: 100%; text-align: center; flex-basis: auto;}
    .review-area img{width: 100%; max-width: 80%; margin: 0 auto;}
    .review-area .col:first-child img{width: 100% !important;}
}

.tab-user{
  position: fixed;
  top: 120px;
  /*left: 5vw; */
  /*margin-left: 0px; */
  z-index: 9999;
  display: flex;
}

@media screen and (max-width: 780px) {
  .tab-user {
    top: 80px;
  }
}
.tab-user a{
    padding: 4px; 
    color: #999; 
    font-size: 14px; 
    width: 120px; 
    display: inline-block; 
    margin-right: 10px; 
    text-align: center; 
    background: rgba(255, 255, 255, .9);
    border-radius: 20px; 
    box-shadow: 2px 2px 5px rgb(0 0 0 / 5%); 
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border: 1px solid #fff;
    overflow: hidden;
}
.tab-user a:hover{ border: 1px solid rgba(0, 0, 0, 0.2); color: #545454; background: rgba(255, 255, 255, 1);}
.tab-user a.active{border: 1px solid rgba(0, 0, 0, 0.2); color: #545454; background: rgba(255, 255, 255, 1);}




/* 일반회원 페이지 */
.user-area{margin-top: -110px;}
.fp-section {
    position: relative;
    -webkit-box-sizing: border-box;
    /* Safari<=5 Android<=3 */
    -moz-box-sizing: border-box;
    /* <=28 */
    box-sizing: border-box;
}

.fp-slide {
    float: left;
}

.fp-slide,
.fp-slidesContainer {
    height: 100%;
    display: block;
}

.fp-slides {
    z-index: 1;
    height: 90%;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s ease-out;
    /* Safari<=6 Android<=4.3 */
    transition: all 0.3s ease-out;
}

.fp-section.fp-table,
.fp-slide.fp-table {
    display: table;
    table-layout: fixed;
    position: relative;
    width: 100%;
}

.fp-tableCell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.fp-slidesContainer {
    float: left;
    position: relative;
}

.fp-controlArrow {
    /* -webkit-user-select: none; */
    /* webkit (safari, chrome) browsers */
    /* -moz-user-select: none; */
    /* mozilla browsers */
    /* -khtml-user-select: none; */
    /* webkit (konqueror) browsers */
    /* -ms-user-select: none; */
    /* IE10+ */
    display: none;
    position: absolute;
    z-index: 4;
    top: 50%;
    cursor: pointer;
    width: 0;
    height: 0;
    border-style: solid;
    margin-top: -88px;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.fp-controlArrow.fp-prev {
    left: 20px;
    width: 40px;
    height: 40px;
    opacity: 0.7;
    border: none;
    border-right: 5px solid #ccc;
    border-bottom: 5px solid #ccc;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    box-sizing: border-box;
}

.fp-controlArrow.fp-prev:hover {
    opacity: 1;
}

.fp-controlArrow.fp-next {
    right: 20px;
    width: 40px;
    height: 40px;
    opacity: 0.7;
    border: none;
    border-right: 5px solid #ccc;
    border-bottom: 5px solid #ccc;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: border-box;
}

.fp-controlArrow.fp-next:hover {
    opacity: 1;
}

.fp-scrollable {
    overflow: hidden;
    position: relative;
}

.fp-scroller {
    overflow: hidden;
    /*  */
}

.iScrollIndicator {
    border: 0 !important;
}

.fp-notransition {
    -webkit-transition: none !important;
    transition: none !important;
}

#fp-nav {
    position: fixed;
    z-index: 100;
    margin-top: -5% !important;
    bottom: 50px;
    opacity: 1;
    /* -webkit-transform: translate3d(0, 0, 0); */
}

#fp-nav.right {
    right: 17px;
}

#fp-nav.left {
    left: 17px;
}

.fp-slidesNav {
    position: absolute;
    z-index: 4;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0 !important;
    right: 0;
    margin: 0 auto !important;
}

.fp-slidesNav.bottom {
    bottom: 12%;
    text-align: center;
}

.fp-slidesNav.top {
    top: 17px;
}

#fp-nav ul,
.fp-slidesNav ul {
    margin: 0;
    padding: 0;
}

#fp-nav ul li,
.fp-slidesNav ul li {
    display: block;
    width: 14px;
    height: 13px;
    margin: 7px;
    position: relative;
}

.fp-slidesNav ul li {
    display: inline-block;
}

#fp-nav ul li a,
.fp-slidesNav ul li a {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
    height: 12px;
    width: 12px;
    margin: -6px 0 0 -6px;
    border-radius: 100%;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    height: 4px;
    width: 4px;
    border: 0;
    background: rgba(0, 0, 0, 0.7);
    left: 50%;
    top: 50%;
    margin: -2px 0 0 -2px;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
    width: 10px;
    height: 10px;
    margin: -5px 0px 0px -5px;
}

#fp-nav ul li .fp-tooltip {
    position: absolute;
    top: -2px;
    color: #fff;
    font-size: 14px;
    font-family: arial, helvetica, sans-serif;
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    display: block;
    opacity: 0;
    width: 0;
    cursor: pointer;
}

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active+.fp-tooltip {
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    width: auto;
    opacity: 1;
}

#fp-nav ul li .fp-tooltip.right {
    right: 20px;
}

#fp-nav ul li .fp-tooltip.left {
    left: 20px;
}

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
    height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
    height: auto !important;
}

.nav-menu a {
    color: #333;
}

.c333 {
    color: #333;
}

.page-link {
    color: #657280;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #657280;
    border-color: #586470;
}

.inner {
    display: inline-block;
    position: relative;
    z-index: 10;
    width: 100%;
    max-width: 1100px;
    min-height: 660px;
    text-align: left;
    vertical-align: middle;
}

.inner2 {
    display: inline-block;
    position: relative;
    z-index: 10;
    width: 100%;
    max-width: 1100px;
    padding-left: 550px;
    min-height: 660px;
    text-align: left;
    vertical-align: middle;
}

.text-content {
    position: relative;
    z-index: 100;
    min-height: 660px;
    margin-top: 150px;
}

.btn-area {
    margin-top: 100px;
}

.btn-area .link-app {
    display: inline-block;
    overflow: hidden;
    width: 180px;
    height: 60px;
    font-size: 18px;
    margin-right: 14px;
    line-height: 52px;
    border-radius: 10px;
    border: 3px solid #eee;
    background-color: #fff;
    text-align: center;
    vertical-align: middle;
    transition: all 0.5s ease;
}

.btn-area .link-app:hover {
    box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.1);
}



.btn-area2 {
    position: absolute;
    margin-top: 400px;
}

.btn-area2 .link-app {
    display: inline-block;
    overflow: hidden;
    width: 80px;
    height: 80px;
    font-size: 18px;
    margin-right: 14px;
    line-height: 42px;
    border-radius: 80px;
    /* border: 1px solid #ccc;
    background-color: #f3f3f3; */
    text-align: center;
    vertical-align: middle;
    transition: all 0.5s ease;
}

.btn-area2 .link-app:hover {
    box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.1);
}
.btn-area2 .tit-ios {
    width: 34px;
    height: 34px;
    display: inline-block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/public/images/ic_app.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
    }

.btn-area2 .tit-android {
    width: 34px;
    height: 34px;
    display: inline-block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/public/images/ic_gl.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
}


.btn-area3 .link-app {
    display: inline-block;
    overflow: hidden;
    width: 80px;
    height: 80px;
    font-size: 18px;
    margin-right: 14px;
    line-height: 42px;
    border-radius: 80px;
    /* border: 1px solid #ccc;
    background-color: #f3f3f3; */
    text-align: center;
    vertical-align: middle;
    transition: all 0.5s ease;
}

.btn-area3 .link-app:hover {
    box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.1);
}

.btn-area3 .tit-ios {
    width: 34px;
    height: 34px;
    display: inline-block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/public/images/ic_app.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
    }

.btn-area3 .tit-android {
    width: 34px;
    height: 34px;
    display: inline-block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/public/images/ic_gl.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
}

.active .active .chat01 {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 309px;
    height: 322px;
    /* background-image: url("/public/images/img_chat_n01.png"); */
    background-image: url("../../../public/images/img_chat_n01.png");
    background-size: 100% 100%;
    z-index: 500;
}

.active .active .chat02 {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 309px;
    height: 322px;
    background-image: url("/public/images/img_chat_n02.png");
    background-size: 100% 100%;
    z-index: 500;
}

.active .active .chat03 {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 309px;
    height: 322px;
    background-image: url("/public/images/img_chat_n03.png");
    background-size: 100% 100%;
    z-index: 500;
}

.active .active .chat04 {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 309px;
    height: 322px;
    background-image: url("/public/images/img_chat_n04.png");
    background-size: 100% 100%;
    z-index: 500;
}

.active .active .chat05 {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 309px;
    height: 322px;
    background-image: url("/public/images/img_chat_n05.png");
    background-size: 100% 100%;
    z-index: 500;
}

.active .active .chat06 {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 309px;
    height: 322px;
    background-image: url("/public/images/img_chat_n06.png");
    background-size: 100% 100%;
    z-index: 500;
}

.active .active .chat07 {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 309px;
    height: 322px;
    background-image: url("/public/images/img_chat_n07.png");
    background-size: 100% 100%;
    z-index: 500;
}





.wmpo-intro-pagination-horiz .bullet-active {
    opacity: 1;
    background: #333;
}

.active {
    pointer-events: auto;
}




.tit-ios {
    width: 100px;
    height: 26px;
    display: inline-block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/public/images/ic_appst.png");
    background-size: 100px;
    background-repeat: no-repeat;
    margin-top: -5px;
}

.tit-android {
    width: 116px;
    height: 26px;
    display: inline-block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/public/images/ic_gost.png");
    background-size: 116px;
    background-repeat: no-repeat;
    margin-top: -5px;
}




@keyframes chat {
    0% {
        opacity: 0;
        right: -130px;
    }

    100% {
        opacity: 1;
        right: 0;
    }
}


@keyframes ch1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes ch2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes textUp {
    0% {
        opacity: 0;
        top: -20px;
    }

    100% {
        opacity: 1;
        top: 0;
    }
}

@keyframes textDown {
    0% {
        opacity: 0;
        top: 10px;
    }

    100% {
        opacity: 1;
        top: 0;
    }
}

@keyframes moveOleft {
    0% {
        opacity: 0;
        left: 20px;
    }

    100% {
        opacity: 1;
        left: -40px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadedown {
    0% {
        opacity: 0;
        margin-bottom: 100px;
    }

    100% {
        opacity: 1;
        margin-bottom: 0;
    }
}
@keyframes fadeleft {
    0% {
        opacity: 0;
        margin-left: 200px;
    }

    100% {
        opacity: 1;
        margin-left: 0;
    }
}



/* 

.active .active .slide01_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/public/images/main_bg01.jpg);
    background-image: url('/public/images/main_bg01.jpg');
    background-size: 60%;
    background-position: right bottom;
    background-repeat: no-repeat;
    z-index: 0;
    animation: none !important;
}

.active .active .slide02_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/images/main_bg02.jpg");
    background-size: 60%;
    background-position: right bottom;
    background-repeat: no-repeat;
    z-index: 0;
    animation: none !important;
}

.active .active .slide03_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/images/main_bg03.jpg");
    background-size: 60%;
    background-position: right bottom;
    background-repeat: no-repeat;
    z-index: 0;
    animation: none !important;
}

.active .active .slide04_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-image: url("/public/images/main_bg04.jpg");
    background-size: 60%;
    background-position: right bottom;
    background-repeat: no-repeat;
    z-index: 0;
    animation: none !important;
}

.active .active .slide05_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/images/main_bg05.jpg");
    background-size: 60%;
    background-position: right bottom;
    background-repeat: no-repeat;
    z-index: 0;
    animation: none !important;
}

.active .active .slide06_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/images/main_bg06.jpg");
    background-size: 60%;
    background-position: right bottom;
    background-repeat: no-repeat;
    z-index: 0;
    animation: none !important;
}

.active .active .slide07_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/images/main_bg07.jpg");
    background-size: 60%;
    background-position: right bottom;
    background-repeat: no-repeat;
    z-index: 0;
    animation: none !important;
} */

#mobile-nav-toggle i {
    color: rgba(0, 0, 0, 0.8) !important;
}

.section {
    text-align: center;
}







.pc {display: block;}
.pc_area {
    height: 100%;
    align-items: center;
    display: flex;
}

.mobile_area {
    display: none;
}








@media (max-width: 767.98px) {
    .navbar {
        display: flex !important;
    }

    .text-content {
        margin-top: 0;
    }

    .active .active .btn-area2 {
        margin-top: 380px;
    }

    .btn-area2 .link-app {
        width: 150px;
    }

    .active .ch_txt_area {
        min-height: 40px;
    }

    .txt-desc {
        margin-top: 0;
        font-size: 28px !important;
        text-align: center;
        margin-bottom: 0 !important;
    }

    .txt-tit {
        font-size: 30px !important;
        text-align: center;
        margin-bottom: 0 !important;
    }

    .txt-txt {
        font-size: 18px !important;
        text-align: center;
        display: none;
    }

    .btn_area {
        margin-top: 0;
        width: 100%;
        left: 0;

        margin-bottom: 0 !important;
    }

    .active .ch_txt_area {
        margin-bottom: 0 !important;
    }

    .active .phone_area {
        margin-top: 20px;
        margin-bottom: 0 !important;
    }

    .btn_area .row {
        display: flex;
        flex-wrap: revert;
        width: 100%;
        margin: 0 !important;
        padding: 0 10px;
    }

    .btn_area .btn {
        flex-grow: 1;
        padding: 5px 10px;
        font-size: 14px;
        max-width: 100%;
    }

    .active .banner_txt_area {
        display: none;
    }

    .active .more_banner {
        display: none;
    }

    .pc_area {
        height: 100%;
        align-items: center;
        display: none;
    }

    .mobile_area {
        height: 100%;
        align-items: center;
        display: flex;
    }

    .t_tit_area {
        position: absolute;
        top: 80px !important;
        max-width: 80% !important;
        left: 10%;
        text-align: center;
    }

    .txt_slide_tit {
        position: absolute;
        width: 100%;
        text-align: center;
        font-size: 22px !important;
        top: 60px;
    }

    .txt_slide_tit span {
        font-size: 20px !important;
        color: #09a8fa;
        animation: textUp 0.5s ease-out 0.8s 1 both;
    }

    .service_area img {
        width: 60%;
    }

    .phone_img1 .point_circle {
        bottom: 252px;
        left: 50%;
        margin-left: 34px;
    }

    .phone_img2 .point_circle {
        bottom: 164px;
        left: 50%;
        margin-left: 32px;
    }

    .phone_img4 .point_circle {
        bottom: 142px;
        left: 50%;
        margin-left: -90px;
    }

    .phone_img1 .img-chat {
        width: 320px !important;
        height: 231px !important;
        /* background-image: url("/public/images/app_view01_ch_m.png"); */
        left: 50%;
        margin-left: -160px;
    }

    .phone_img2 .img-chat {
        width: 327px !important;
        height: 260px !important;
        /* background-image: url("/public/images/app_view02_ch_m.png"); */
        left: 50%;
        margin-left: -160px;
    }

    .phone_img3 .img-chat {
        left: 50%;
        margin-left: -180px;
    }

    .phone_img5 .img-chat {
        left: 50%;
        margin-left: -180px;
    }

    .phone_img6 .img-chat {
        width: 297px !important;
        height: 200px !important;
        /* background-image: url("/public/images/app_view06_ch_m.png"); */
        left: 50%;
        margin-left: -160px;
    }

    #slide_guide01,
    #slide_guide02,
    #slide_guide03,
    #slide_guide04,
    #slide_guide05 {
        display: block !important;
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        margin: 0 auto !important;
    }

    #slide_guide02 .guide_chat {
        position: absolute;
        bottom: 0;
        margin-bottom: -60px;
        left: 0 !important;
        width: 100%;
        text-align: center;
        margin-left: 0 !important;
    }

    #slide_guide03 .guide_chat {
        position: absolute;
        bottom: 0;
        margin-bottom: -20px;
        left: 0 !important;
        width: 100%;
        text-align: center;
        margin-left: 0 !important;
    }

    #slide_guide04 .guide_chat2 {
        position: absolute;
        bottom: 0;
        margin-bottom: -20px;
        left: 0 !important;
        width: 100%;
        text-align: center;
        margin-left: 0 !important;
    }

    #slide_guide05 .guide_chat3 {
        position: absolute;
        bottom: 0;
        margin-bottom: 20px;
        left: 0 !important;
        width: 100%;
        text-align: center;
        margin-left: 0 !important;
    }

    .area-phone {
        width: 100%;
        height: 432px !important;
    }

    .phone_area {
        height: 432px !important;
    }

    .img-mockup-phone {
        left: 50% !important;
        margin-left: -90px;
        width: 180px !important;
        height: 391px !important;
    }

    .shape-phone {
        width: 200px !important;
        height: 421px !important;
        left: 50% !important;
        margin-left: -100px;
    }

    .shape-circle {
        width: 300px !important;
        height: 300px !important;
        left: 50% !important;
        margin-left: -150px !important;
    }

    .active .active .guide_man {
        z-index: 0;
    }

    .active .active .guide_man2 {
        z-index: 0;
    }

    .active .active .guide_chat {
        z-index: 9;
    }

    .active .active .guide_chat2 {
        z-index: 9;
    }

    .active .active .guide_chat3 {
        z-index: 9;
    }

    .slide01_bg {
        background-size: 150% !important;
    }

    .slide02_bg {
        background-size: 150% !important;
    }

    .slide03_bg {
        background-size: 150% !important;
    }

    .slide04_bg {
        background-size: 150% !important;
    }

    .slide05_bg {
        background-size: 150% !important;
    }

    .slide06_bg {
        background-size: 150% !important;
    }

    .slide07_bg {
        background-size: 150% !important;
    }

    .list_info {
        position: initial;
        right: 30px;
        top: 30px;
        vertical-align: middle;
        display: flex;
        width: auto;
    }

    .btn-area {
        margin-right: -14px;
        text-align: center;
    }

    .btn-area2 {
        width: 100%;
        text-align: center;
    }
    .btn-area3 {
        width: 100%;
        text-align: center;
    }

    .chat01 {
        left: 50% !important;
        margin-left: -154px;
    }

    .chat02 {
        left: 50% !important;
        margin-left: -154px;
    }

    .chat03 {
        left: 50% !important;
        margin-left: -154px;
    }

    .chat04 {
        left: 50% !important;
        margin-left: -154px;
    }

    .chat05 {
        left: 50% !important;
        margin-left: -154px;
    }

    .chat06 {
        left: 50% !important;
        margin-left: -154px;
    }

    .chat07 {
        left: 50% !important;
        margin-left: -154px;
    }

    .slide01_bg {
        width: 100% !important;
    }

    .slide02_bg {
        width: 100% !important;
    }

    .slide03_bg {
        width: 100% !important;
    }

    .p_pt {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        overflow: hidden;
        position: relative;
        border: 1px solid #ccc;
    }

    .p_pt img {
        width: 60px;
        height: 60px;
    }
}



/*세션 2 슬라이드 폰 */
.talk-mock-tab{display: flex; position: absolute; left: 10px; top: 550px;}
.talk-mock-tab div{width: 140px; border: 1px solid #fff; text-align:center; line-height: 50px; background: #fff; box-shadow: 0 0 5px rgb(0 0 0 / 10%); margin: 0 5px; cursor: pointer;}
.talk-mock-tab div:hover{border: 1px solid #01a0fc; color: #01a0fc;}
.talk-mock-tab div.active{border: 1px solid #01a0fc; color: #01a0fc;}
#myCarousel .carousel-control-prev {
    left: 0;
    margin-left: -80px;
    cursor: pointer;
}
#myCarousel .carousel-control-next {
    right: 0;
    margin-right: -80px;
    cursor: pointer;
}
#myCarousel .carousel-indicators{cursor: pointer; margin-bottom: -60px;}
.phone_banner_area .fp-slides{overflow: visible;}
#myCarousel .carousel-control-prev-icon {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

#myCarousel .carousel-control-next-icon {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.phone_banner_area{ width: 340px !important; height: 640px !important; position: relative;  margin: 0 auto; margin-top: 100px;}
.phone_banner_area .mobile_bg {
    width: 328px !important;
    height: 617px !important;
    position: absolute;
    z-index: 99;
    left: 0;
    top: 0;
    margin-top: -18px;
    margin-left: -14px;
    /* background-image: url("/public/images/phone_bg.png"); */
    background-size: 100%;
    
}
.phone_banner_area .fp-slides{ max-width: 260px; margin: 0 auto; margin-top: 20px;}

#myCarousel .carousel-caption {
    left:0;
    right:0;
    bottom:0;
    text-align:left;
    padding:10px;
    background:rgba(0,0,0,0.6);
    text-shadow:none;
}
#myCarousel .list-group {
    position:absolute;
    top:0;
    right:0;
}
.list-group-item{padding: 0}
#myCarousel .list-group-item {
    border-radius:0px;
    cursor:pointer;
}

.list-group-item.active {background: none;}
.flowing_txt01, .flowing_txt02, .flowing_txt03, .flowing_txt04, .flowing_txt05 {display: none; width: 590px; min-height: 340px; position: absolute; left: 0; top: 0;}
.list-group-item.active .flowing_txt01{ display: block; animation: fadeleft 1s ease-out 0.5s 1 both; margin-top: -50px;}
.list-group-item.active .flowing_txt02{ display: block; animation: fadeleft 1s ease-out 0.5s 1 both;}
.list-group-item.active .flowing_txt03{ display: block; animation: fadeleft 1s ease-out 0.5s 1 both;}
.list-group-item.active .flowing_txt04{ display: block; animation: fadeleft 1s ease-out 0.5s 1 both;}
.list-group-item.active .flowing_txt05{ display: block; animation: fadeleft 1s ease-out 0.5s 1 both;}

.btn-secondary{color: #fff !important;}
.btn-outline-secondary:hover{color: #fff !important;}
.carousel-item img{width: 100%;}
.list-group-item{z-index: 999 !important; }

.over_man01{position: absolute; z-index: 9999; top: 240px; display: none;}
.over_man02{position: absolute; z-index: 9999; top: 240px; display: none;}
.over_man03{position: absolute; z-index: 9999; top: 240px; display: none;}
.over_man01 img{width: 100%;}
.over_man02 img{width: 100%;}
.over_man03 img{width: 100%;}
.list-group-item.active .over_man01{display: block; animation: fadedown 0.3s ease-out 0.2s 1 both;}
.list-group-item.active .over_man02{display: block; animation: fadedown 0.3s ease-out 0.2s 1 both;}
.list-group-item.active .over_man03{display: block; animation: fadedown 0.3s ease-out 0.2s 1 both;}

@keyframes circlemv {
    0% {
        border: 2px solid #d3365d;
    }

    50% {
        border: 6px solid #d3365d;
    }

    100% {
        border: 2px solid #d3365d;
    }
}

@keyframes opa {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

@keyframes opav {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes chide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes imgleft {
    0% {
        opacity: 0;
        margin-left: -60px;
    }

    100% {
        opacity: 1;
        margin-left: 0;
    }
}

@keyframes imgright {
    0% {
        opacity: 0;
        margin-left: 60px;
    }

    100% {
        opacity: 1;
        margin-left: 0;
    }
}

@keyframes chatup {
    0% {
        opacity: 0;
        margin-bottom: 40px;
    }

    100% {
        opacity: 1;
        margin-bottom: -20px;
    }
}

@keyframes chatup2 {
    0% {
        opacity: 0;
        margin-bottom: 90px;
    }

    100% {
        opacity: 1;
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    #section1 .fp-slidesNav.bottom {
        display: block;
    }

    .mobile {
        display: block;
    }

    .pc {
        display: none !important;
    }

    #header #logo h1 {
        font-size: 28px;
        text-align: left;
    }
    #header{background:rgba(255, 255, 255, .8);}

    #logo {
        text-align: left;
    }

    #slide_guide01,
    #slide_guide02,
    #slide_guide03,
    #slide_guide04,
    #slide_guide05,
    #slide_guide06 {
        opacity: 1;
    }
}






/* .owl-carousel .owl-stage-outer{} */
.carousel-wrap {
  margin: 90px auto;
  padding: 0 20px;
  width: 100%;
  position: relative;
}

/* fix blank or flashing items on carousel */
.owl-carousel .item {
  position: relative;
  z-index: 100; 
  /* -webkit-backface-visibility: hidden;  */
  /* opacity: .3; */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    transform: scale3d(0.9, 0.9, 1);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.owl-carousel .owl-item.active.center .item {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}
.owl-carousel .item:first-child {opacity: 0;}

.leftShadow{background: url(/public/images/left_shadow.png) left; z-index: 1; position: absolute; left: 0; top: 0; width: 20%; height: 100%; background-repeat: repeat-y; background-size: 100%; }
.rightShadow{background: url(/public/images/right_shadow.png) right;  z-index: 1; position: absolute; right: 0; top: 0; width: 20%; height: 100%; background-repeat: repeat-y; background-size: 100%; }
/* end fix */
.owl-nav  {
  margin-top: -26px;
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  color: #cdcbcd;
  opacity: .6;
  -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
    z-index: 999;
}
.owl-nav:hover  {
  opacity: 1;
}

.owl-nav i {
  font-size: 52px;
}



.owl-carousel .owl-stage-outer{overflow: visible;}
.carousel-wrap {
margin: 90px auto;
padding: 0 20px;
width: 100%;
position: relative;
}

/* fix blank or flashing items on carousel */
.owl-carousel .item {
position: relative;
z-index: 100; 
/* -webkit-backface-visibility: hidden;  */
/* opacity: .3; */
-webkit-transform: scale3d(0.9, 0.9, 1);
transform: scale3d(0.9, 0.9, 1);
-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
cursor: pointer;
}

.owl-carousel .owl-item.active.center .item {
opacity: 1;
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
}
.owl-carousel .item:first-child {opacity: 0;}

.leftShadow{background: url(/public/images/left_shadow.png) left; z-index: 1; position: absolute; left: 0; top: 0; width: 20%; height: 100%; background-repeat: repeat-y; background-size: 100%; }
.rightShadow{background: url(/public/images/right_shadow.png) right;  z-index: 1; position: absolute; right: 0; top: 0; width: 20%; height: 100%; background-repeat: repeat-y; background-size: 100%; }
/* end fix */
.owl-nav  {
margin-top: -26px;
position: absolute;
top: 50%;
width: 100%;
display: flex;
color: #cdcbcd;
opacity: .6;
-webkit-box-pack: justify!important;
-ms-flex-pack: justify!important;
justify-content: space-between!important;
z-index: 999;
}
.owl-nav:hover  {
opacity: 1;
}

.owl-nav i {
font-size: 52px;
}
.file-upload-layout-2 .file-upload-input{
  height:65px;
}
.file-upload-layout-2 .file-upload-text{
  height:65px;
}
.photo_update_box{
  display:flex;flex-wrap:nowrap;width:auto;height:176px;
}
.photo_update_box >div{display:inline-block;position:relative;width:calc(25% - 3/4*20px);margin-right:20px;white-space: nowrap;}
.photo_update_box >div:last-child{margin-right:0;}
.photo_update_box >div img{width:170px;height:170px;}
.photo_update_box >div >.delImage_close{display:inline-block;position:absolute;width:26px;height:26px;background:#09a8fa;opacity:0.8;top:-6px;right:-6px;z-index:98;}
.photo_update_box >div >.delImage_close:before,
.photo_update_box >div >.delImage_close:after{content:'';position:absolute;top:3px;left:13px;height:21px;border-left:1px solid #fff;}
.photo_update_box >div >.delImage_close:before{transform:rotate(45deg);}
.photo_update_box >div >.delImage_close:after{transform:rotate(-45deg);}



.productCarousel1 .owl-carousel .item {
  opacity: 0.3;
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.productCarousel1 .owl-carousel .slick-center div {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.productCarousel1 .item {
    outline: none;
}

.productCarousel1 button, .productCarousel1 button:focus {
  margin: 0 50px;
  z-index: 2;
  background-color: inherit;
}

.productCarousel1 .slick-prev:before, .productCarousel1 .slick-next:before {
  color: #fff;
  content: '\e92f';
}


.productCarousel2 .inner {
  display: inline-block;
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 1100px;
  min-height: 660px;
  text-align: left;
  vertical-align: middle;
}

.productCarousel2 .slick-slide .slide01_bg {
  position: absolute;
  /* display: flex; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/images/main_bg01.jpg');
  background-color: #fff;
  background-size: 60%;
  background-position: right bottom;
  background-repeat: no-repeat;
  /* z-index: 999; */
  animation: none !important;
}
.productCarousel2 .slick-slide .slide02_bg {
  position: absolute;
  /* display: flex; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/images/main_bg02.jpg');
  background-color: #f0e7df;
  background-size: 60%;
  background-position: right bottom;
  background-repeat: no-repeat;
  /* z-index: 999; */
  animation: none !important;
}
.productCarousel2 .slick-slide .slide03_bg {
  position: absolute;
  /* display: flex; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/images/main_bg03.jpg');
  background-color: #bae9ed;
  background-size: 60%;
  background-position: right bottom;
  background-repeat: no-repeat;
  /* z-index: 999; */
  animation: none !important;
}
.productCarousel2 .slick-slide .slide04_bg {
  position: absolute;
  /* display: flex; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/images/main_bg04.jpg');
  background-color: #ece2db;
  background-size: 60%;
  background-position: right bottom;
  background-repeat: no-repeat;
  /* z-index: 999; */
  animation: none !important;
}
.productCarousel2 .slick-slide .slide05_bg {
  position: absolute;
  /* display: flex; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/images/main_bg05.jpg');
  background-color: #d8dde1;
  background-size: 60%;
  background-position: right bottom;
  background-repeat: no-repeat;
  /* z-index: 999; */
  animation: none !important;
}
.productCarousel2 .slick-slide .slide06_bg {
  position: absolute;
  /* display: flex; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/images/main_bg06.jpg');
  background-color: #ebf3f6;
  background-size: 60%;
  background-position: right bottom;
  background-repeat: no-repeat;
  /* z-index: 999; */
  animation: none !important;
}
.productCarousel2 .slick-slide .slide07_bg {
  position: absolute;
  /* display: flex; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/images/main_bg07.jpg');
  background-color: #efe0be;
  background-size: 60%;
  background-position: right bottom;
  background-repeat: no-repeat;
  /* z-index: 999; */
  animation: none !important;
}

.productCarousel2 .inner2 {
  display: inline-block;
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 1100px;
  padding-left: 550px;
  min-height: 660px;
  text-align: left;
  vertical-align: middle;
}


.productCarousel2 .slick-dots img {
  border-radius: 50%;
  /* margin: 0 10px; */
}

.productCarousel2 .chat01 {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 309px;
  height: 322px;
  /* background-image: url("/public/images/img_chat_n01.png"); */
  background-image: url("../../../public/images/img_chat_n01.png");
  background-size: 100% 100%;
  z-index: 500;
}

.productCarousel2 .chat02 {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 309px;
  height: 322px;
  background-image: url("/public/images/img_chat_n02.png");
  background-size: 100% 100%;
  z-index: 500;
}

.productCarousel2 .chat03 {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 309px;
  height: 322px;
  background-image: url("/public/images/img_chat_n03.png");
  background-size: 100% 100%;
  z-index: 500;
}

.productCarousel2 .chat04 {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 309px;
  height: 322px;
  background-image: url("/public/images/img_chat_n04.png");
  background-size: 100% 100%;
  z-index: 500;
}

.productCarousel2 .chat05 {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 309px;
  height: 322px;
  background-image: url("/public/images/img_chat_n05.png");
  background-size: 100% 100%;
  z-index: 500;
}

.productCarousel2 .chat06 {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 309px;
  height: 322px;
  background-image: url("/public/images/img_chat_n06.png");
  background-size: 100% 100%;
  z-index: 500;
}

.productCarousel2 .chat07 {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 309px;
  height: 322px;
  background-image: url("/public/images/img_chat_n07.png");
  background-size: 100% 100%;
  z-index: 500;
}

.productCarousel2 .fp-section.fp-table,
.fp-slide.fp-table {
    display: table;
    table-layout: fixed;
    position: relative;
    width: 100%;
}

.productCarousel2 .fp-tableCell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.productCarousel2 .slide {
  position: relative;
}
.productCarousel2 .slick-dots li {
  width: 100px;
  height: 100px;

}
/* .productCarousel2 .slick-active {
  width: 100px;
  height: 100px;
} */


.room-list2 {
  background-color: #f5f5f5;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  top: 10px;
  border-radius: 20px 20px 0 0;
  width: 90%;
  z-index: 1000;
}

.room-list2 .room-item-header {
  justify-content: space-between;
  margin: 20px 20px 0;
}


.border20{border-radius: 20px;}
.white{background: #f8f8f9 !important;}


.ticket-user{padding:10px 20px; margin-top: 10px; font-size: 14px;}
.ticket-buy-box{display: flex; text-align: center; margin-top: 10px; }
.ticket-buy-box .box{ width: 100%;   margin-left: 10px; }
.ticket-buy-box .box .inner-box{border: 1px solid #bbbbbb; width: 100%;  border-radius: 10px; padding: 20px;  background: #f8dfc6; color: #333; font-weight: 600;}
.ticket-buy-box .box .inner-box img{width: 100%; margin: 0 auto;}
.ticket-buy-box .box:first-child{margin-left: 0;}
.ticket-buy-box button{margin-top: 10px; border: 1px solid #ccc; background: #fff; color: #333; padding: 5px 15px; border-radius: 20px; font-size: 14px;}
.ticket-info{  padding:20px 20px; border-top: 1px solid #ddd;}
.ticket-info h3{font-size: 14px; font-weight: 600; color: #333; margin-bottom: 0.5rem;}
.ticket-info h3 i{font-size: 18px; vertical-align: middle; }
.ticket-info h4{font-size: 14px; color: #545454; font-weight: 300;}
.ticket-info ul{ margin: 0; padding: 0; margin-top: 10px;}
.ticket-info ul li{list-style: none; font-size: 14px; font-weight: 300;}

.ticket-box{display: flex; flex-wrap: wrap; border: 1px solid #ddd; background: #fff; border-radius: 10px; padding: 20px; margin: 20px; margin-top: 10px;}
.ticket-box h3{width: 100%; font-size: 14px; font-weight: 600; color: #333;}
.btn-charge{border: 1px solid #ccc; color: #333; padding: 5px 15px; border-radius: 20px; font-size: 14px;}
.btn-charge:hover{ text-decoration: none; background: #f3f3f3; color: #111;}
.room-item-close-button button{ font-size: 22px; color: #999;}
.room-item-close-button button:hover{color: #333; text-decoration: none;}

@media screen and (max-width: 768px){

  .page{
      left:70px;
      width:calc( 100% - 70px)
  }
  .chat_app .chat_room{
      margin-left:0;
      width:100%;
      padding:0 20px
  }
  .chat_app .chat_list{
      width:300px;
  }
  .m-none{display: none;}
  .plan-case .col{width: 25%; padding: 0;}
  .plan-case .row{margin: 0;}
  .plan-case .infoTxt{display: none;}
  .plan-case .planBox{min-height: 106px;}
  .plan-case .mt-50px{margin-top: 0;}
  .plan-case .titTxt{font-size: 14px;}
  .planMaxDay{letter-spacing: -1px;}
  .underLine{width: auto;}
  .planBox .priceTxt{font-size: 16px; font-weight: 600;}
  .planBox .priceTxt span{font-size: 12px; font-weight: 600; display: block; margin: 0;}
  .planBox .titTxt label:after{top: 8px;}
  .planBox .titTxt label:before{top: 3px;}
  .planTit .mainTit{font-size: 14px; width: 100%;}
  .planTit ul{flex-wrap:wrap;}
  .planTit ul li{width: 25%; font-size: 12px;}
  .planTit ul li i{font-size: 18px;}
  .planTit .subTit{width: 100%; background: #fff; margin-right: 0; padding-left: 10px; padding-bottom: 0;}
  .planBlue{margin-left: 5px !important;}
  .planRed{margin-left: 5px !important;}
  .planGreen{margin-left: 5px !important;}
  .underLine::before{border: none;}
  .planTit {
      border-radius: 10px;
      overflow: hidden;
  }
  .tooltip{z-index: 9999999;}
  .chat_app .spaceoption{display: none; width: 100%; z-index: 9999; background: #fff; right: 0; height: 100vh;}
  .talkUserList {
      height: calc(100vh - 220px);
  }
  .chat_room .card-header{flex-wrap: wrap;}
  .selectgroup{margin: 0 !important; margin-bottom: 10px !important;}
  .notice-con-area{position: relative; margin: 0; left: 0; width: 100%; min-width: 100%; top: 0;}
  .notice-con-area .notice-tit-txt-area{width: 200px;}
  .notice-body-area{padding: 10px;}
  .noticAddview{font-size: 12px; margin: 0;}
  .noticAddview .photoArea{width: 100%; height: auto;}

  .calendar-con-area{position: relative; margin: 0; left: 0; width: 100%; min-width: 100%; top: 0;}
  .calendar-con-area .notice-tit-txt-area{width: 200px;}
  .calendar-body-area .calendar {padding: 10px !important;}
  .calendar-body-area .calendar .month{font-size: 1.2rem !important;}
  
  .vote-con-area{position: relative; margin: 0; left: 0; width: 100%; min-width: 100%; top: 0;}
  .vote-con-area .notice-tit-txt-area{width: 200px;}
  .vote-con-area .selectgroup-item{display: block; width: 100%;}
  
  .attendance-con-area{position: relative; margin: 0; left: 0; width: 100%; min-width: 100%; top: 0;}
  .attendance-con-area .notice-tit-txt-area{width: 200px;}
  .attendance-body-area .calendar {padding: 10px !important;}
  .attendance-body-area .calendar .month{font-size: 1.2rem !important;}

  .card-tit-area h3{
      width: 300px; 
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;}
  .card-tit-area a{display: block; margin-left: auto; color: #999; margin-top: -10px; }
  
  .card-tit-area a i{font-size: 24px !important;}

  .btn-close-option {display: block; margin-left: auto; color: #999 !important; margin-top: 10px; }
  .btn-close-option  i{font-size: 24px !important;}

  .talkDataList{height: calc(100vh - 220px);}

  .talkDataList .listImgArea .listImg {
      width: 100% !important;
      height: 90px !important;
  }
  .talkDataList .listImgArea .listImg .overLock{line-height: 90px;}

  

  .card-options{display: none;}
}  

@media (max-width: 768px){
  .tab-pane h5{font-size: 14px; font-weight: 300;}
  .fixtop02{padding: 15px;}
  .markArea .asktalk{position: relative; bottom: auto; left: auto; margin-top: -50px;}
  .premiummodal .pcmode {
      height: 38rem !important;
  }
  .premiummodal_w .pcmode {
      height: 38rem !important;
  }
  .Pc{ display: none !important;}
  .Mobile{ display: block !important;}
  .avatarArea{width: 100%; text-align: center;}
  .mtup .avatar {
      width: 60px;
      height: 60px;
      vertical-align: middle;
  }
  .premiummodal .socialArea p{text-align: center; font-size: 12px;}
  .socialtop{display: flex; 
      -webkit-box-pack: center!important;
      -ms-flex-pack: center!important;
      justify-content: center!important;}
  
  .countInfo{ width: 100%; text-align: center; margin-top: 10px;}
.countInfo p{display: inline-block; font-size: 12px;}
  .mvList{padding-left: 140px; position: relative; text-align: left; height: 60px; }
.mvList .listImg2{width: 120px; height: 60px; position: absolute; left: 0; top: 0;}
.mvList .listImg2 img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}
.premiummodal .giftInfo .giftTit{font-size: 12px !important;}
  .phone_over{max-width: 100%;}
  .phoneRound{border-radius: 20px 20px 0 0;}
  .hero-area{padding-top: 30px !important; padding-bottom: 30px !important;}
  .hero-area .search-btn-box{margin-left: 10px;}
  
  .tagImgArea .tagitem{display: block;}
  .reply-area .listTitle{font-size: 20px;}
  .reply-area h5{margin-bottom: 20px; font-size: 14px;}
  .noflex{flex-direction: column;}
  .noflex button{display: block; width: 100%;}
  .noflex .btnArea{width: 100%;}
  .tagImgArea .tagitem .tagitemImg{max-width: 100% !important;}

  .tabArea .tag{
      font-size:12px !important;
       background-color: rgb(255, 255, 255);
       color: #999;
       padding: 3px 15px !important;
       cursor: pointer;
  }
  .PhoneBody .tabArea .tag-rounded{display:inline-block; margin-top: 10px;}
  .sectorTit{display: none !important;}
  .mBox{background: #fff; border-radius: 10px; padding:10px 20px; margin-top: 10px;}
  .mBox .sectorTit{font-size: 12px !important; font-weight: 600;}

  .answerInfo{padding: 0 !important; margin-bottom: 10px;}
  .partOn{display: block !important;}
  .PhoneArea{font-size: 12px;}
  .phone_over .PhoneArea {border-radius: 20px;}
  .PhoneBody .conArea .adressArea{font-size: 12px !important;}
  .PhoneBody .conArea .txtArea2{font-size: 12px !important;}
  .PhoneBody .conArea .giftInfo{font-size: 12px !important;}
  .premiummodal .listTxt{font-size: 12px !important;}
  .premiummodal .imgboard{width: 54px;}
  .premiummodal_w .imgboard{width: 54px;}
  .subTit{font-size: 12px !important; font-weight: 700 !important;}
  .subTit b{font-weight: 700 !important;}
  .aniTxtArea{font-size: 12px;     padding: 5px 10px;}
.solutionTxt{margin-bottom: 20px;}    
.mobileFlex{display: flex;}    
.mobileFlex button{font-size: 14px; padding:0 10px; margin: 0 10px; height: 60px;}   
.solutionImg{ border: 1px solid #c8c8c8; position: relative; background: #ededed; padding: 10px; border-radius: 20px; width: 100%; max-width: 900px; margin: 0 auto;}
.solutionImg img{width: 100%; border-radius: 10px;}
.solutionImg .btnImg{ position: absolute; left: 20px; top: 0; margin-top: -22px;}
.solutionImg .btnImg span{display: inline-block; width: 20px; height: 3px; background: #c8c8c8; margin-left: 10px;}    

.aniBtn, .aniBtn2, .aniBtn3, .aniBtn4{display: none !important;}
.mobileOrder1{ order: 1;}
.mobileOrder2{order: 2;}   
.product-area{padding-top: 40px !important; padding-bottom: 40px !important; word-break: keep-all;}
.productTit{color: #333; font-size: 30px; text-align: center; line-height:1.2; font-weight: 900;}

.productTxt{color: #333; font-size: 14px; text-align: center; line-height: 1.5; font-weight: 100; margin-top: 20px;}
.productTxt span{font-weight: 500; }
.frameAni01{width: 100%; height: 440px; overflow: hidden;}

.sessonAni01{
  width: 720px !important;
  height: 840px !important; 
  border: none; 
  margin: 0 auto; 
  zoom: 0.70;
  -moz-transform: scale(0.70);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.70);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.70);
  -webkit-transform-origin: 0 0;
}

.frameAni02{width: 300px; height: 440px; overflow: hidden; margin: 0 auto;}
.sessonAni02{
  width: 680px !important;
  height: 880px !important; 
  border: none; 
  margin: 0 auto; 
  zoom: 0.70;
  -moz-transform: scale(0.70);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.70);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.70);
  -webkit-transform-origin: 0 0;
}
.frameAni03{width: 300px; height: 420px; overflow: hidden; margin: 0 auto;}
.sessonAni03{
  width: 680px !important;
  height: 880px !important; 
  border: none; 
  margin: 0 auto; 
  zoom: 0.70;
  -moz-transform: scale(0.70);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.70);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.70);
  -webkit-transform-origin: 0 0;
}
.sessonAni04{width: 100% !important; max-width: 300px; height: 600px !important; border: none; margin: 0 auto;}

.frameAni05{width: 320px; height: 420px; overflow: hidden; margin: 0 auto;}
.sessonAni05{
  width: 720px !important;
  height: 880px !important; 
  border: none; 
  margin: 0 auto; 
  zoom: 0.70;
  -moz-transform: scale(0.70);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.70);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.70);
  -webkit-transform-origin: 0 0;
}
.min660{min-height: 660px;}



  .min660{min-height: auto;}
  .answerMobilebg {
      padding: 0;
      /*padding-top: 10px;*/
      background: #333 !important;
      background: linear-gradient(to bottom, #333 0%, rgba(255,255,255,.0) 88%) !important;
      display: none;
  }
  .PhoneArea .TackBtnArea{position: fixed; bottom:190px; margin-left: 0; left: auto; right: 10px; z-index: 999999; cursor: pointer;}
  .PhoneArea .TackBtnArea img{border: 4px solid #01a0fc; vertical-align: middle;}
  .PhoneArea .TackBtnArea span{background: #01a0fc; color: #fff; font-size: 14px; border-radius: 20px 0 0 20px; padding: 6px 15px; margin-right: -9px; vertical-align: middle;}
  .PhoneArea .TackBtnArea .avatar{width: 50px; height: 50px;}

  .answer-area{padding-top: 0 !important;}
  .mobileHidden{display: none;}
  .nopt{padding-top: 0 !important;}
  .job-area{min-height: auto}
  .maxTxt{width: 100%; max-width: 200px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
  .maxTxt2{width: 100%; max-width: 160px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
  .maxTxt3{width: 100%; max-width: 300px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
  .writeTime {line-height: 1.5;}
  .writeTime span{display: block;}
  .hidden-xs {
      display: none;
  }
  .table.table_custom tr th:first-child, .table.table_custom tr td:first-child {
      border-radius: 0;
  }
  .table.table_custom tr th:last-child, .table.table_custom tr td:last-child {
      border-radius: 0;
  }
  .bar-round{margin-top: 0 !important;}
  .header-area {background: #fff; box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);} 
  .footLink{ word-break: keep-all; margin-top: 10px;}
  .auth .card-top .card-title{font-size: 30px;}
  .auth .card-top .card-title2{font-size: 28px;}
  .card-top{margin-bottom: 0;}
  .auth .auth_left{width: 100%;}
  .auth .auth_right{display: none;}
  .textColor{display: none;}
  .PcRightBoard{border-right:none; padding-right: 7.5px; margin-bottom: 20px;}
  .OptionBox{border: 1px solid #ddd; border-radius: 10px; padding: 10px;}
  .auth .logo{    position: relative; left: 10px; top: 20px; height: 80px;}
  .auth {
      display: block;
      height: 100vh;
      align-items: center;

  }
  .shareLink{ position: absolute; right: 10px; top: 25px;}

  .footLink a {
      margin-right: 4px;
      padding-right: 4px;
      font-size: 12px;
  }
  .gudok-level{
      font-size: 12px;
      -webkit-box-pack: justify!important;
      -ms-flex-pack: justify!important;
      justify-content: space-between!important;
  }
  .gudok-bottom-pay{padding: 10px 0;}
  .gudok-pay-info{display: block;}
  .gudok-buy{width: 100%;}
  .before-pay{ text-align: right;}
  .discount-pay{ margin: 0;}

  .payarea-count{
      display: flex;
      -webkit-box-pack: justify!important;
      -ms-flex-pack: justify!important;
      justify-content: space-between!important;
      margin: 10px 20px;
  }
  .before-pay span{width: 90px; margin-left: auto;}
  .space-menu{margin: 0;}
  .space-menu ul{display: flex; flex-grow: 1; }
  .space-menu li{    width: 100%; padding: 0;  text-align: center; letter-spacing: -.5px;}
  #con-pdf .conPdf{margin: 0 auto; margin-bottom: 20px;}
  #con-photo .conImg{margin: 0 auto; margin-bottom: 20px;}
  #con-mv{width: 306px; margin: 0 auto; margin-bottom: 20px;}
  #con-mv .listConArea{ padding: 0; height: auto;}
  #con-mv .listImg{position:relative !important; width: 100% !important; height: 130px !important; margin-bottom: 10px;}
  #con-mv .listConArea .listConTxt{ -webkit-line-clamp: 1;}

  .before-pay span::after{ margin-left: 0;}

}

@media (max-width: 680px){
  .searchBox{display: block; padding: 0 15px;}
  .searchBox .writeBtnArea{margin-left: 0; width: 100%; margin-top: 20px;}
  }
  
  @media only screen and (max-width: 1600px){
      .auth .auth_left {
          width: calc(100% - 450px);
          text-align: center;
          
      }
      .auth .auth_left .card{right: 0;}
      .auth .auth_right {
          display: flex;
          align-items: center;
          height: 100vh;
          width: 450px;
          padding: 0 20px;
      }
  }
  
  @media screen and (max-width: 768px) {
    .owl-action-styled .owl-nav .owl-prev {
      margin-left: -30px;
    }
  
    .owl-action-styled .owl-nav .owl-next {
      margin-right: -30px;
    }
  
    .owl-action-styled .owl-nav {
      z-index: 5;
    }
  
    .product-area .listTitle span {
      font-size: 32px;
    }
  }

  @media screen and (max-width: 990px){
    .tagImgArea .tagitem{max-width: 368px; display: inline-block;}
    .reply-area .listTitle{font-size: 30px;}
    .reply-area h5{margin-bottom: 20px; font-size: 20px;}
    
    .reply-area{padding-top: 40px !important;}
    .buyArea{padding-top: 40px !important;}
    .job-area{padding-top: 40px !important;}
    .productDown{margin: 0;}
    .productTxt{margin-bottom: 10px;}
    
    
    .tagImgArea{display: block; padding: 10px;}
    .reply-area .imgText{text-align: center; margin-top: 20px; }
    
    .accodianArea .card-header .btn{font-size: 14px; padding: 25px 10px;}
    .mobile2line{display: block !important;}
    .mobile2line .form-group{ width: 100%; padding-left: 0; margin-top: 10px;}
    .bar-round{margin-top: 0 !important;}
    .header-area {background: #fff; box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);} 
    .container{max-width: 900px;}
    .filters{flex-direction: unset;}
    .viewInfo{position: relative; right: 0; top: 0; line-height: 30px;}
    .viewInfo span{display: block;}
    .search-btn-box {
        flex-grow: inherit;
    }
    .search-btn-box .btn {
        width: 100%;
        max-width: 100px;
    }

    .media-body .content{display: block; text-align: left;}
    .buyGoods .diaImg{width: 100px; padding: 13px 0;}
    .buyGoods .textArea span{font-size: 16px;}
    .buyGoods .textArea{padding: 0 0 0 20px;}
    .buyBtn{padding: 12px 10px;}
    .buyArea h5{font-size: 14px;}
    .plusPoint{
        padding: 0;
        left: 2px;
        top: 0;
        margin-top: -18px;
        width: 50px;
    }
    .btnBic2{padding: 10px 24px;}
    .listTitle{font-size: 24px !important; margin-top: 10px;}
    .solutionTit{font-size: 20px; line-height: 1.4; }
    .solutionTit br{display:none !important;}
    .card-body {
        padding: 15px;
    }
    
    .media>.mr-3 {
        margin: 0 !important;
    }
    .media>.mr-3 .w150 {
        width: 100% !important;
    }
    .media .media-body {
        margin-top: 10px;
        padding: 0;
    }
    .listTxtTit{position: unset; display: block !important;}
    .listTxtTit span { display: block;  right: 20px; bottom: 20px;}
    
    .nicname {
        margin-top: 10px;
        font-weight: 300;
        display: flex; -ms-flex-pack: justify;  justify-content: space-between;
        width: 100%;
        position: static;
        
    }

    .nicname .time{font-size: 12px; display: block;}
    .askTime{position: static; text-align: left;}
    .listInfo01, .listInfo02 {
       font-size: 13px;
       margin-top: 10px;
    }
    .listInfo01 {
        margin-right: 5%;
    }
    .media-foot {
        padding: 20px;
        margin-right: 0;
        margin-top: 20px;
        border: none;
        border-top: 1px dashed #ddd;
    }
    .media-foot .btn{width: 100%;}
}

  @media only screen and (max-width: 992px){.auth .auth_left .card{right:auto}
  }


  @media (max-width: 1370px){
    .PhoneArea .TackBtnArea{right: 30px; left: auto; margin-left: 0;}
    .userMan01{position: absolute; right: 60px; bottom: 160px; background: url(/public/images/useMan01.png) no-repeat; background-size: cover; width: 269px; height: 510px; z-index: 96; }
    .userMan01 .useTxt{bottom: 0; position: absolute; margin-bottom: -60px; left: 50%; margin-left: -50px; }
    .userMan02{position: absolute; left: 50%; margin-left: 100px; bottom: 160px; background: url(/public/images/useMan02.png) no-repeat; background-size: cover; width: 255px; height: 229px; z-index: 97;}
    .userMan02 .useTxt{bottom: 0; position: absolute; margin-bottom: -60px; left: 50%; margin-left: -30px; }
    .userMan03{position: absolute; left: 50%; margin-left: -70px; bottom: 130px; background: url(/public/images/useMan03.png) no-repeat; background-size: cover; width: 240px; height: 244px; z-index: 98;}
    .userMan03 .useTxt{bottom: 0; position: absolute; margin-bottom: -34px; left: 50%; margin-left: -120px; }
    .userMan04{position: absolute; left: 50%; margin-left: -290px; bottom: 160px; background: url(/public/images/useMan04.png) no-repeat; background-size: cover; width: 225px; height: 387px; z-index: 94;}
    .userMan04 .useTxt{bottom: 0; position: absolute; margin-bottom: -60px; left: 50%; margin-left: -50px; }
    .userMan05{position: absolute; left: 50%; margin-left: -35%; bottom: 160px; background: url(/public/images/useMan05.png) no-repeat; background-size: cover; width: 209px; height: 222px; z-index: 96;}
    .userMan05 .useTxt{bottom: 0; position: absolute; margin-bottom: -60px; left: 50%; margin-left: -60px; }
    .userMan06{position: absolute; left: 80px; bottom: 160px; background: url(/public/images/useMan06.png) no-repeat; background-size: cover; width: 167px; height: 302px; z-index: 98;}
    .userMan06 .useTxt{bottom: 0; position: absolute; margin-bottom: -60px; left: 50%; margin-left: -90px; }
}

.pre-wrap {
  white-space: pre-wrap;
  line-height: 1.3;
}
