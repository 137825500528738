.tp-ui--avatar-box {
  display: inline-block;
  border-radius: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: var(--v-bg-color, #fff);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    vertical-align: unset;
  }
}