.landing-page-chat-wrapper {
  @media screen and (max-device-width: 740px) {
    .chat-customize.white {
      padding-bottom: 45px !important; // 아이폰 등에서 아래쪽이 잘리는 증상 완화용. 실제 적용 후 세부조정 필요
    }
  }

  @media screen and (max-width: 740px) {
    .chat-customize.white {
      display: flex;
      flex-direction: column;

      position: absolute;
      inset: 0;

      .chat-container {
        flex-grow: 1;

        .chat-body {
          position: absolute;
          inset: 0;
          height: auto !important;
          overflow-y: auto;

          padding: 15px;
          padding-top: 0;

          display: flex;
          flex-direction: column;

          .scroll-bottom-button {
            position: fixed;
            bottom: 70px;
          }
        }

        .chat-body-messages  {
          flex-grow: 1;
          margin-bottom: 0;
        }
      }

      .chat-footer {
        position: sticky;
        bottom: 0;
        width: 100%;
      }
    }
  }
}