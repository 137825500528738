/*-================== loading-area ==============-*/
#preloader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: $white;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
        width: 50px;
        height: 50px;

        .path {
            stroke: $theme-color-2;
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }
    }
}

.section-title {
    font-size: $fs-35;
    font-weight: $fw-bold;

    @media #{$device-375px} {
        font-size: $fs-30;
    }
}

.section-title,
.section-desc {
    br {
        @media #{$tab_device} {
            display: none;
        }

        @media #{$large_mobile} {
            display: none;
        }

        @media #{$small_mobile} {
            display: none;
        }
    }
}

.section-desc {
    line-height: 30px;
    font-size: $fs-18;

    @media #{$tab_device} {
        width: auto !important;
        margin-left: inherit !important;
        margin-right: inherit !important;
    }

    @media #{$large_mobile} {
        width: auto !important;
        margin-left: inherit !important;
        margin-right: inherit !important;
    }

    @media #{$small_mobile} {
        width: auto !important;
        margin-left: inherit !important;
        margin-right: inherit !important;
        font-size: $fs-15;
        line-height: 26px;
    }
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: $black;
    opacity: .8;
    pointer-events: none;
    z-index: -1;
}

/*======== icon-shape ========*/
.icon-shape {
    position: absolute;
    background-color: $color-1;
    width: 20px;
    height: 20px;
    @include border-radius(100%);
    opacity: 0.3;

    &.icon-shape-1 {
        left: 4%;
        top: 15%;
    }

    &.icon-shape-2 {
        left: 30%;
        top: 10%;
        background-color: $color-2;
        clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    }

    &.icon-shape-3 {
        left: 13%;
        top: 50%;
        background-color: $color-3;
        clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    }

    &.icon-shape-4 {
        left: 40%;
        bottom: 10%;
        background-color: $color-4;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }

    &.icon-shape-5 {
        right: 5%;
        top: 10%;
        background-color: $color-5;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }

    &.icon-shape-6 {
        right: 25%;
        top: 30%;
        background-color: $color-6;
        clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
        @include border-radius(0);
    }

    &.icon-shape-7 {
        right: 16%;
        bottom: 23%;
        background-color: $color-7;
        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
        @include border-radius(0);
    }
}

/*======= stroke-shape ========*/
.stroke-shape {
    position: absolute;
    width: 120px;
    @include transform(rotate(-55deg));
    opacity: 0.1;
    background-color: $theme-color-4;
    height: 2px;
    display: block;

    &.stroke-shape-1,
    &.stroke-shape-2,
    &.stroke-shape-3 {
        top: 175px;
    }

    &.stroke-shape-1 {
        left: 70px;
    }

    &.stroke-shape-2 {
        left: 90px;
    }

    &.stroke-shape-3 {
        left: 110px;
    }

    &.stroke-shape-4 {
        right: 70px;
    }

    &.stroke-shape-5 {
        right: 90px;
    }

    &.stroke-shape-6 {
        right: 110px;
    }

    &.stroke-shape-4,
    &.stroke-shape-5,
    &.stroke-shape-6 {
        bottom: 70px;
        @include transform(rotate(55deg));
    }
}

/*====== stroke-shape-white ========*/
.stroke-shape-white {
    background-color: $white;
}

/*====== vertical-bar-shape ========*/
.vertical-bar-shape {
    position: absolute;
    top: 0;
    width: 40px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.01);
    pointer-events: none;

    &.vertical-bar-shape-1 {
        left: 0;
    }

    &.vertical-bar-shape-2 {
        left: 33%;
    }

    &.vertical-bar-shape-3 {
        right: 33%;
    }

    &.vertical-bar-shape-4 {
        right: 0;
    }
}

/*====== generic-img-box ========*/
.generic-img-box {
    position: relative;

    img {
        position: absolute;
        width: 180px;
        height: 180px;
        @include border-radius(100%);
        border: 8px solid $white;
        @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));

        &:nth-child(1) {
            top: -80px;
            right: 0;
        }

        &:nth-child(2) {
            top: -20px;
            right: 210px;
            width: 200px;
            height: 200px;
        }

        &:nth-child(3) {
            bottom: -60px;
            right: 30px;

            @media #{$laptop_m} {
                right: 0;
            }
        }

        &:nth-child(4) {
            bottom: -80px;
            left: 180px;
            width: 150px;
            height: 150px;

            @media #{$laptop_m} {
                left: 0;
            }
        }
    }
}

/*====== generic-img-box-layout-2 ========*/
.generic-img-box-layout-2 {
    img {
        @include border-radius(12px);
        border: 0;

        &:nth-child(1) {
            top: -73px;
            right: -10px;
            width: 205px;
            height: 205px;
        }

        &:nth-child(2) {
            top: -200px;
            right: 280px;
            width: 150px;
            height: 150px;
        }

        &:nth-child(3) {
            bottom: 160px;
            right: 80px;
            width: 100px;
            height: 100px;
            opacity: 0.5;
        }

        &:nth-child(4) {
            bottom: 260px;
            right: 250px;
            left: auto;
            width: 110px;
            height: 110px;
        }

        &:nth-child(5) {
            top: 0;
            left: 50px;
            opacity: 0.5;
        }

        &:nth-child(6) {
            bottom: 200px;
            right: 530px;
            opacity: 0.5;
            width: 85px;
            height: 85px;
        }
    }
}

/*====== pattern-bg ========*/
.pattern-bg {
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        position: absolute;
        content: "";
        width: 120px;
        height: 105px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
        pointer-events: none;
    }

    &::before {
        background-image: url("../../images/line-shape.png");
        top: 20%;
        left: 10%;
        opacity: 0.1;
    }

    &::after {
        background-image: url("../../images/dots.png");
        bottom: 10%;
        right: 15%;
        opacity: 0.4;
    }
}

.pattern-bg-2 {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: -1;
        pointer-events: none;
        background-image: url("../../images/pattern-bg.jpg");
        top: 0;
        left: 0;
        opacity: 0.1;
    }
}

/*======== toggle-input ========*/
.toggle-input {
    width: 40px;
    height: 20px;
    position: relative;
    cursor: pointer;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $theme-color-2;
        @include transition(0.3s);
        @include border-radius(100px);
    }

    &::after {
        position: absolute;
        content: '';
        top: 9%;
        left: 9%;
        width: 40%;
        height: 80%;
        background-color: $white;
        @include transition(0.3s);
        @include border-radius(100%);
    }

    &:checked {
        &::after {
            left: 50%;
        }
    }
}

/*======== hover-underline ========*/
.hover-underline {
    &:hover {
        text-decoration: underline;
    }
}

.text-underline {
    text-decoration: underline;
}

.video-box {
    position: relative;

    .video-content {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        @include transform(translateY(-50%));
    }
}

/*======== photo-gallery ========*/
.photo-gallery {
    .row {
        margin-right: -5px;
        margin-left: -5px;
    }

    .col-lg-12,
    .col-lg-6 {
        padding-left: 5px;
        padding-right: 5px;
    }
}

/*======== gallery-item ========*/
.gallery-item {
    img {
        @include border-radius(4px);
        @include transition(0.3s);
        width: 100%;
    }

    margin-bottom: 10px;
    display: block;
    overflow: hidden;
    @include border-radius(4px);

    &:hover {
        img {
            @include transform(scale(1.1));
        }
    }
}

/*======== generic-list-item ========*/
.generic-list-item {
    li {
        margin-bottom: 10px;
        line-height: 22px;
        color: $theme-color-4;

        a {
            color: $theme-color-4;
            @include transition(0.3s);
            display: block;
            position: relative;

            &:hover {
                color: $theme-color-2;
            }
        }
    }

    &.package-list {
        li {
            padding-left: 25px;
            position: relative;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            i {
                position: absolute;
                top: 3px;
                left: 0;
            }
        }
    }
}

/*======== generic-list-item-inline ========*/
.generic-list-item-inline {
    li {
        display: inline-block;
        margin-right: 10px;
    }
}

/*======== generic-list-item-white ========*/
.generic-list-item-white {
    li {
        color: rgba(255, 255, 255, 0.5);

        a {
            color: rgba(255, 255, 255, 0.5);
        }
    }
}

/*======== generic-list-item-hover-underline ========*/
.generic-list-item-hover-underline {
    li {
        a {
            display: inline-block;

            &::before {
                position: absolute;
                content: "";
                bottom: -5px;
                right: 0;
                width: 0;
                height: 2px;
                background-color: $theme-color-2;
                @include transition(0.3s);
            }

            &:hover {
                letter-spacing: .4px;

                &::before {
                    width: 100%;
                    right: auto;
                    left: 0;
                }
            }
        }
    }
}

/*===== generic-list-item-highlight =====*/
.generic-list-item-highlight {
    li {
        margin-bottom: 4px;

        a {
            padding: 5px 14px;
        }

        &.active {
            a {
                background-color: rgba(128, 137, 150, 0.1);
                font-weight: $fw-medium;
                color: $theme-color;
                border-right: 3px solid $theme-color-2;
            }
        }
    }
}

/*====== generic-list-item-bullet ======*/
.generic-list-item-bullet {
    li {
        padding-left: 15px;
        position: relative;

        &::before {
            position: absolute;
            top: 2px;
            left: 0;
            content: "\2022";
            padding: 0 3px;
            font-size: $fs-18;
        }
    }
}

/*====== generic-list-item--bullet ======*/
.generic-list-item--bullet {
    li {
        &:first-child {
            padding-left: 0;

            &::before {
                display: none;
            }
        }

        &::before {
            font-size: $fs-12;
        }
    }
}

/*===== vertical-list =====*/
.vertical-list {
    .item {
        padding: 12px 15px;
        border-bottom: 1px solid rgba(128, 137, 150, 0.1);
    }
}

/*====== sticky-navs =======*/
.sticky-navs-wrap,
.js--scroll-nav {
    position: sticky;
    top: 0;
    z-index: 1024;
}

.terms-and-condition-area,
.privacy-area {
    z-index: 1024;
}

/*====== js-scroll-nav =======*/
.js-scroll-nav {
    li {
        display: inline-block;

        a {
            font-size: $fs-15;
            color: $theme-color-4;
            @include transition(0.3s);
            padding: 6px 16px;
            @include border-radius(50px);
            display: block;
            line-height: 20px;
            font-weight: $fw-medium;

            &:hover {
                color: $theme-color;
                background-color: rgba(128, 137, 150, 0.1);
            }
        }

        &.active {
            a {
                color: $theme-color;
                background-color: rgba(128, 137, 150, 0.1);
            }
        }
    }
}

/*===== js--scroll-nav =======*/
.js--scroll-nav {
    li {
        display: block;
        margin-bottom: 4px;

        a {
            @include border-radius(4px);
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
}

/*====== post =======*/
.post {
    .votes {
        width: 90px;
        padding: 12px 20px 12px 10px;
    }

    .vote-block {
        border: 1px solid rgba(128, 137, 150, 0.2);
        color: $theme-color-4;
        font-size: $fs-12;
        @include border-radius(4px);
        text-align: center;
        padding: 2px 8px;
        line-height: 22px;
        font-weight: $fw-medium;
    }

    .answered-accepted {
        .vote-block {
            background-color: $color-12;
            color: $white;
            border-color: $color-12;
        }
    }
}

/*===== user-stats =====*/
.user-stats {
    .stat {
        margin-left: 20px;

        &:first-child {
            margin-left: 0;
        }
    }
}

/*===== badge-top =====*/
.badge-top {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    @include border-radius(8px);
    margin-bottom: 15px;
}

.badge-top-name {
    font-size: $fs-14;
    text-transform: uppercase;
    border-right: 1px solid transparent;
    padding: 15px 0;
    width: 90px;
    text-align: center;
    font-weight: $fw-bold;
}

.badge-top-gold,
.badge-top-silver,
.badge-top-bronze {
    .badge-top-name {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
}

.badge-top-gold {
    background-color: rgba(249, 184, 81, 0.1);

    .badge-top-name {
        background-color: rgba(249, 184, 81, 0.2);
        border-color: rgba(249, 184, 81, 0.2);
        color: $color-3;
    }
}

.badge-top-silver {
    background-color: rgba(206, 206, 208, 0.1);

    .badge-top-name {
        background-color: rgba(206, 206, 208, 0.2);
        border-color: rgba(206, 206, 208, 0.2);
        color: $color-gray-2;
    }
}

.badge-top-bronze {
    background-color: rgba(175, 93, 24, 0.1);

    .badge-top-name {
        background-color: rgba(175, 93, 24, 0.2);
        border-color: rgba(175, 93, 24, 0.2);
        color: $color-11;
    }
}

/*===== votes =====*/
.votes {
    width: 100px;
    padding-right: 15px;

    .vote-counts {
        padding-right: 3px;
        flex-grow: 1;
        text-align: right;
    }

    .vote-icon,
    .answer-icon {
        display: inline-block;
        width: 25px;
        vertical-align: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .vote-icon {
        height: 12px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMyIDIyIj48cGF0aCBzdHJva2U9IiM3NzgwOEUiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTIgMjFsMTUtMjAgMTUgMjBoLTMweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
    }

    .answer-icon {
        height: 19px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzOCI+PGcgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjNzc4MDhGIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0zMSAxNi4xNzhjMCAuNzg1LS41MDMgMy4zNDktMS4zMDQgNS43NjctMS4zMDQgMy45MjctNS44NyAxNS4wNTUtNS44NyAxNS4wNTVsLTMuMjYxLTYuNTQ1Yy0uODE1LjQxMi0yLjYwOS45MS00LjY5MS45MS04LjM0MSAwLTE1LjEyNy02LjgxNC0xNS4xMjctMTUuMTg2czYuNzg2LTE1LjE3OCAxNS4xMjctMTUuMTc4YzguMzQxIDAgMTUuMTI2IDYuODA3IDE1LjEyNiAxNS4xNzh6Ii8+PHRleHQgZmlsbD0iIzc3ODA4RiIgZm9udC1mYW1pbHk9IkhlbHZldGljYSBOZXVlIiBmb250LXNpemU9IjE2IiBmb250LXdlaWdodD0iNDIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48dHNwYW4geD0iOS4xNjQiIHk9IjIwLjcxMiI+QTwvdHNwYW4+PC90ZXh0PjwvZz48L3N2Zz4=);
    }

    &.answered-accepted {
        .answer-icon {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzOCI+PGcgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzRDOTA2NyIgc3Ryb2tlPSIjNEM5MDY3IiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0zMSAxNi4xNzhjMCAuNzg1LS41MDMgMy4zNDktMS4zMDQgNS43NjctMS4zMDQgMy45MjctNS44NyAxNS4wNTUtNS44NyAxNS4wNTVsLTMuMjYxLTYuNTQ1Yy0uODE1LjQxMi0yLjYwOS45MS00LjY5MS45MS04LjM0MSAwLTE1LjEyNy02LjgxNC0xNS4xMjctMTUuMTg2czYuNzg2LTE1LjE3OCAxNS4xMjctMTUuMTc4YzguMzQxIDAgMTUuMTI2IDYuODA3IDE1LjEyNiAxNS4xNzh6Ii8+PHRleHQgZmlsbD0iI2ZmZiIgZm9udC1mYW1pbHk9IkhlbHZldGljYSBOZXVlIiBmb250LXNpemU9IjE2IiBmb250LXdlaWdodD0iNDIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48dHNwYW4geD0iOSIgeT0iMjEiPkE8L3RzcGFuPjwvdGV4dD48L2c+PC9zdmc+);
        }
    }
}

/*===== votes-2 =====*/
.votes-2 {
    width: 86px;
    padding-right: 18px;
}

.answer-block {
    @include border-radius(4px);
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px solid transparent;

    &.answered {
        border-color: $color-12;
        color: $color-12
    }

    &.answered-accepted {
        background-color: $color-12;
        color: $white;
    }
}

/*===== meta =====*/
.meta {
    .author {
        color: $theme-color-2;
        @include transition(0.2s);

        &:hover {
            color: $theme-color;
        }
    }
}

/*====== tag-link =======*/
.tag-link {
    color: $theme-color-4;
    background-color: rgba(121, 127, 135, 0.1);
    margin-right: 2px;
    margin-bottom: 2px;
    text-align: center;
    display: inline-block;
    font-size: $fs-12;
    line-height: 16px;
    padding: 3px 7px;
    @include border-radius(4px);
    @include transition(0.2s);
    border: 1px solid rgba(121, 127, 135, 0.05);

    &:hover {
        color: $theme-color;
        background-color: rgba(121, 127, 135, 0.2);
    }

    &.active {
        background-color: $theme-color-2;
        border-color: $theme-color-2;
        color: $white;
        font-weight: $fw-medium;
    }
}

/*====== tag-link-md =======*/
.tag-link-md {
    margin-bottom: 6px;
    font-size: $fs-14;
    line-height: 23px;
    padding: 2px 14px;
}

/*====== tag-link-blue =======*/
.tag-link-blue {
    background-color: rgba(45, 134, 235, 0.1);
    color: $theme-color-2;
    border-color: rgba(45, 134, 235, 0.05);

    &:hover {
        background-color: rgba(45, 134, 235, 0.2);
        color: $theme-color-2;
    }
}

/*====== badge =======*/
.tag-stat {
    min-width: 40px;
    text-align: right;
}

/*====== badge =======*/
.badge {
    font-weight: $fw-medium;
    border: 1px solid transparent;
    position: relative;
}

.badge-md {
    font-size: $fs-13;
    line-height: 24px;
    padding: 2px 12px;
}

.badge-gray {
    background-color: rgba(128, 137, 150, 0.1);
    border-color: rgba(128, 137, 150, 0.1);
    color: $theme-color-4;

    &:hover {
        background-color: rgba(128, 137, 150, 0.2);
        color: $theme-color;
    }
}

.badge-gold {
    background-color: rgba(249, 184, 81, 0.1);
    border-color: rgba(249, 184, 81, 0.1);

    &:hover {
        background-color: rgba(249, 184, 81, 0.2);
        color: $theme-color;
    }
}

.badge-bronze {
    background-color: rgba(175, 93, 24, 0.1);
    border-color: rgba(175, 93, 24, 0.1);

    &:hover {
        background-color: rgba(175, 93, 24, 0.2);
        color: $theme-color;
    }
}

/*====== badge-stats =======*/
.badge-stats {
    .badge {
        .ball {
            position: absolute;
            left: 12px;
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
}

/*====== ball =======*/
.ball {
    width: 6px;
    height: 6px;
    display: inline-block;
    @include border-radius(100%);
    background-color: $color-11;
    margin-right: 4px;
}

.ball-lg {
    width: 8px;
    height: 8px;
}

.gold {
    background-color: $color-3;
}

.silver {
    background-color: $color-gray-2;
}

.red {
    background-color: $color-10;
}

/*===== generic-pagination =====*/
.generic-pagination {
    .page-link {
        border: 0;
        @include border-radius(6px);
        color: $theme-color-4;
        margin-left: 3px;
        font-weight: $fw-medium;
        font-size: $fs-15;
        @include transition(0.2s);

        &:hover {
            color: $theme-color-2;
            background-color: transparent;
        }
    }

    .page-item {
        &:first-child {
            .page-link {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }
        }

        &:last-child {
            .page-link {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }

        &.active {
            .page-link {
                background-color: $theme-color-2;

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.generic--pagination {
    .page-link {
        padding: 4px 8px;
        font-size: $fs-14;
    }
}

/*===== qty =======*/
.quantity-item {
    background-color: $white;
    @include box-shadow(0 1px 5px 0 rgba(0, 0, 0, 0.10));
    @include border-radius(6px);
}

.qtyInput {
    width: 40px;
    border: 0;
    text-align: center;
    color: $theme-color;
    font-weight: $fw-medium;
    pointer-events: none;
    font-size: $fs-17;
}

.qtyBtn {
    line-height: 1;
    padding: 8px 10px;
    font-size: $fs-17;
    color: $theme-color;
    text-align: center;
    border: 0;
    @include transition(0.3s);
    background-color: transparent;

    &:hover {
        color: $theme-color-2;
    }
}

.qtyDec {
    border-right: 1px solid rgba(127, 136, 151, 0.1);
}

.qtyInc {
    border-left: 1px solid rgba(127, 136, 151, 0.1);
}

/*====== divider =======*/
.divider {
    background-color: rgba(127, 136, 151, 0.1);
    height: 1px;

    span {
        display: block;
        width: 50px;
        height: 1px;
        background-color: $theme-color-2;
    }
}

/*===== company-logo =====*/
.company-logo {
    position: relative;
    z-index: 2;
    width: 60px;
    height: 60px;
    background-color: $white;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
    @include border-radius(8px);

    img {
        @include border-radius(8px);
        width: 100%;
    }
}

/*===== company-details =====*/
.company-details {
    strong {
        font-weight: normal;
        text-transform: uppercase;
        color: $theme-color-4;
        font-size: $fs-11;
        position: relative;
        display: block;
        margin-bottom: 15px;

        span {
            background-color: $white;
            display: inline-block;
            padding: 0 10px;
            position: relative;
            z-index: 2;
            line-height: 20px;
        }

        &::before {
            content: '';
            border-top: 1px solid rgba(128, 137, 150, 0.1);
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
        }
    }
}

/*===== svg color =====*/
.svg-icon-color-white {
    path {
        fill: $white;
    }
}

.svg-icon-color-gray {
    path {
        fill: $theme-color-4;
    }
}

.svg-icon-color-1 {
    path {
        fill: $color-1;
    }
}

.svg-icon-color-2 {
    path {
        fill: $color-2;
    }
}

.svg-icon-color-3 {
    path {
        fill: $color-3;
    }
}

.svg-icon-color-4 {
    path {
        fill: $color-4;
    }
}

.svg-icon-color-5 {
    path {
        fill: $theme-color-2;
    }
}

.svg-icon-color-6 {
    path {
        fill: $color-7;
    }
}

.filter-gray {
    filter: grayscale(1);
}

/*===== upvotejs =====*/
div.upvotejs {
    span {
        &.count {
            font-size: $fs-17 !important;
        }
    }

    .upvote,
    .downvote {
        height: 22px;
        width: 22px;
        background-size: 23px;

        &.upvote {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMyIDIyIj48cGF0aCBzdHJva2U9IiM3NzgwOEUiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTIgMjFsMTUtMjAgMTUgMjBoLTMweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
            background-position: -1px 3px;

            &.upvote-on {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMyIDIyIj48cGF0aCBmaWxsPSIjNzc4MDhGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzc3ODA4RSIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMiAyMWwxNS0yMCAxNSAyMGgtMzB6Ii8+PC9zdmc+);
                background-position: -1px 3px;
            }
        }

        &.downvote {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMyIDIyIj48cGF0aCBzdHJva2U9IiM3NzgwOEUiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTIgMWwxNSAyMCAxNS0yMGgtMzB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
            background-position: -1px 3px;

            &.downvote-on {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMyIDIyIj48cGF0aCBmaWxsPSIjNzc4MDhGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzc3ODA4RSIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMiAxbDE1IDIwIDE1LTIwaC0zMHoiLz48L3N2Zz4=);
                background-position: -1px 3px;
            }
        }
    }

    a.star {
        width: 30px;
        background-position: -5px -120px;

        &.star-on {
            background-position: -45px -120px;
        }

        &.check {
            background-position: -5px -265px;

            &.star-on {
                background-position: -45px -265px;
            }
        }
    }
}

/*======= custom-scrollbar-styled =======*/
.custom-scrollbar-styled {
    &::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        @include border-radius(10px);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(128, 137, 150, 0.2);
        @include border-radius(10px);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(128, 137, 150, 0.4);
    }
}

/*======= question-post-user-action =======*/
.question-post-user-action {
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
    border-top: 1px solid rgba(128, 137, 150, 0.1);
    position: relative;
    z-index: 2;
    background-color: $white;
}

.post-menu {
    background-color: $white;
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);

    .btn {
        font-size: $fs-14;
        color: $theme-color-4;
        @include border-radius(0);
        @include transition(0.3s);

        &:hover {
            color: $theme-color-2;
        }

        &:focus {
            @include box-shadow(none);
        }
    }
}

/*======= question-highlight =======*/
.question-highlight {
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
    padding-bottom: 20px;
}

/*======= votes-styled =======*/
.votes-styled {
    background-color: rgba(128, 137, 150, 0.08);
    border-top: 1px solid rgba(128, 137, 150, 0.08);
    padding: 15px 13px;
}

/*======= question-post-body-wrap =======*/
.question-post-body-wrap,
.answer-body-wrap {
    padding: 15px 0 15px 15px;
    width: calc(100% - 60px);
}

.meta {
    &.text-right {
        @media #{$device-375px} {
            text-align: left !important;
        }
    }
}

/*======= question-post-body =======*/
.question-post-body,
.answer-body {
    p {
        margin-bottom: 15px;
        color: rgba(13, 35, 62, 0.8);
    }
}

/*======= code-block =======*/
.code-block {
    background-color: $color-gray-3;
    padding: 12px 15px;
    overflow: auto;
    max-height: 300px;
    margin-bottom: 15px;

    code {
        max-height: 300px;
    }
}

.code-block-deleted {
    background-color: rgba(216, 39, 56, 0.04);
}

.code-block-edited {
    background-color: rgba(202, 232, 212, 0.3);
}

.code-string {
    color: $color-13;
}

.code-attr,
.code-keyword {
    color: teal;
}

.code-built-in,
.code-name {
    color: chocolate;
}

.diff-add {
    background-color: rgba(202, 232, 212, 0.8);
}

.diff-deleted {
    background-color: rgba(216, 39, 56, 0.1);
}

/*===== code =====*/
.code {
    background-color: rgba(128, 137, 150, 0.1);
    color: $theme-color-4;
    padding: 2px 6px;
    @include border-radius(2px);
}

/*===== subheader =====*/
.subheader {
    background-color: rgba(128, 137, 150, 0.1);
    border-top: 1px solid rgba(128, 137, 150, 0.1);
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
    padding: 10px 13px;
    position: relative;
}

/*===== tooltip =====*/
.tooltip-inner,
.popover {
    background-color: $white;
    color: $theme-color;
    @include box-shadow(0 1px 10px rgba(0, 0, 0, 0.1));
    @include border-radius(8px);
}

.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: $white !important;
}

.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: $white !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: $white !important;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: $white !important;
}

/*=== popover ===*/
.popover {
    border-color: rgba(128, 137, 150, 0.1);
    max-width: 310px;

    .popover-body {
        padding: 15px;
    }
}

.bs-popover-top > .arrow::before {
    border-top-color: rgba(128, 137, 150, 0.1);
}

.bs-popover-bottom > .arrow::before {
    border-bottom-color: rgba(128, 137, 150, 0.1);
}

/*===== jqte =====*/
.jqte {
    margin: 0;
    @include box-shadow(none);
    @include border-radius(4px);
    border-color: rgba(128, 137, 150, 0.2);

    * {
        font-family: "Ubuntu", sans-serif !important;
    }

    ul,
    dl,
    ol {
        padding: 5px 5px 5px 16px;
    }

    ul {
        list-style: disc;
    }
}

.jqte_toolbar {
    background-color: rgba(128, 137, 150, 0.1);
    border-bottom-color: rgba(128, 137, 150, 0.2);
}

.jqte_tool {
    padding: 3px;

    &.jqte_tool_1 {
        .jqte_tool_label {
            width: 80px;
            height: 24px;
            padding: 0;
            line-height: 24px;
        }

        .jqte_tool_text {
            font-weight: $fw-medium;
        }
    }

    &:hover {
        @include box-shadow(0 0 40px rgba(82, 85, 90, 0.2));
    }
}

.jqte_tool,
.jqte_tool_icon,
.jqte_tool_label {
    border-color: transparent !important;
}

.jqte_title {
    font-weight: $fw-medium;
}

.jqte_formats,
.jqte_fontsizes,
.jqte_cpalette,
.jqte_linktypes {
    @include border-radius(4px);
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    border-color: rgba(128, 137, 150, 0.2);
}

.jqte_formats .jqte_format:hover,
.jqte_fontsizes .jqte_fontsize:hover,
.jqte_linktypes a:hover {
    background-color: rgba(128, 137, 150, 0.1);
}

.jqte_editor,
.jqte_source {
    min-height: 160px;
}

.jqte_placeholder {
    display: block;
}

.jqte_linktypeview {
    border-color: rgba(128, 137, 150, 0.4);
    padding: 4px 10px;
}

.jqte_linktypearrow {
    bottom: 13px;
    right: 13px;
}

.jqte_linkinput {
    border-color: rgba(128, 137, 150, 0.3);
    padding-top: 4px;
    padding-bottom: 4px;
    color: $theme-color;

    &:focus,
    &:hover {
        border-color: rgba(128, 137, 150, 0.6);
    }
}

.jqte_linkbutton {
    background-color: rgba(128, 137, 150, 0.1);
    border-color: rgba(128, 137, 150, 0.3);
    color: $theme-color;
    @include box-shadow(inset 0 0);
    padding-top: 4px;
    padding-bottom: 4px;

    &:hover {
        background-color: rgba(128, 137, 150, 0.2);
    }
}

/*=== profile-img ===*/
.edit-profile-photo {
    @media #{$device-375px} {
        margin-bottom: 20px;
    }
}

/*=== profile-img ===*/
.profile-img {
    @include border-radius(4px);
    width: 160px;

    @media #{$device-375px} {
        margin-bottom: 15px;
    }
}

/*=== social-icons ===*/
.social-icons {
    li {
        display: inline-block;

        a {
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            @include border-radius(100%);
            @include transition(0.3s);
            @include box-shadow(0 .125rem .25rem rgba(0, 0, 0, .075));
            background-color: $white;
            display: block;
            color: $theme-color;

            &:hover {
                color: $theme-color-2;
            }
        }
    }
}

.social-icons-sm {
    li {
        a {
            width: 27px;
            height: 27px;
            line-height: 27px;
        }
    }
}

/*====== copy-to-clipboard =======*/
.copy-to-clipboard {
    position: relative;
    z-index: 1;
}

/*====== text-success-message=======*/
.text-success-message {
    background-color: $color-12;
    color: $white;
    @include border-radius(4px);
    padding: 3px 6px;
    position: absolute;
    top: -10px;
    left: 50%;
    @include transition(0.4s);
    font-size: $fs-15;
    opacity: 0;
    visibility: hidden;
    @include transform(translateX(-50%));
    font-weight: $fw-medium;

    &.active {
        top: -40px;
        opacity: 1;
        visibility: visible;
    }

    &:after {
        position: absolute;
        content: "";
        bottom: -4px;
        left: 50%;
        @include transform(translateX(-50%) rotate(45deg));
        background-color: $color-12;
        width: 8px;
        height: 8px;
    }
}

/*=== back-to-top ===*/
#back-to-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: $white;
    color: $theme-color-4;
    font-size: $fs-18;
    z-index: 9999;
    width: 40px;
    height: 40px;
    line-height: 40px;
    @include border-radius(100%);
    text-align: center;
    cursor: pointer;
    @include box-shadow(0 7px 10px rgba(0, 0, 0, .075));
    @include transition(0.3s);

    svg {
        fill: $theme-color;
        @include transition(0.3s);
    }

    &:hover {
        background-color: $theme-color-2;
        color: $white;

        svg {
            fill: $white;
        }
    }
}

.is-tilt {
    -webkit-animation: tilt 2s infinite alternate linear;
    -moz-animation: tilt 2s infinite alternate linear;
    animation: tilt 2s infinite alternate linear;
}

.is-scale {
    -webkit-animation: scale 2s infinite alternate linear;
    -moz-animation: scale 2s infinite alternate linear;
    animation: scale 2s infinite alternate linear;
}

.is-bounce {
    -webkit-animation: bounce 4s infinite alternate linear;
    -moz-animation: bounce 4s infinite alternate linear;
    animation: bounce 4s infinite alternate linear;
}

.is-swing {
    -webkit-animation: swing 4s 0.1s infinite alternate linear;
    -moz-animation: swing 4s 0.1s infinite alternate linear;
    animation: swing 4s 0.1s infinite alternate linear;
}

.is-spin {
    -webkit-animation: spin 2s infinite alternate linear;
    -moz-animation: spin 2s infinite alternate linear;
    animation: spin 2s infinite alternate linear;
}

.is-rotate {
    -webkit-animation: rotate 2s infinite linear;
    -moz-animation: rotate 2s infinite linear;
    animation: rotate 2s infinite linear;
}

/*====== owl-action-styled ======*/
.owl-action-styled {
    .owl-nav {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        margin-top: 0;
        width: 100%;
        justify-content: space-between;
        display: flex;

        [class*=owl-] {
            width: 60px;
            height: 60px;
            line-height: 60px;
            @include border-radius(100%);
            background: $white;
            @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
            @include transition(0.2s);

            @media #{$tab_device} {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }

            @media #{$large_mobile} {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }

            @media #{$small_mobile} {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }

            svg {
                width: 30px;
                height: 30px;
                fill: $theme-color-4;
                @include transition(0.2s);

                @media #{$tab_device} {
                    width: 18px;
                    height: 18px;
                }

                @media #{$large_mobile} {
                    width: 18px;
                    height: 18px;
                }

                @media #{$small_mobile} {
                    width: 18px;
                    height: 18px;
                }
            }

            &:hover {
                background: $white;
                @include transform(scale(1.2));

                svg {
                    fill: $theme-color;
                }
            }
        }

        button {

            &.owl-prev,
            &.owl-next {
                background-color: $white;
            }
        }

        .owl-prev {
            margin-left: 50px;
        }

        .owl-next {
            margin-right: 50px;
        }
    }
}

/*====== owl--action-styled ======*/
.owl--action-styled {
    .owl-nav {
        width: auto;
        margin-left: -460px;
        margin-top: 50px;

        @media #{$tab_device} {
            margin-left: 0;
            margin-top: 0;
            position: inherit;
            top: auto;
            @include transform(translateY(0));
            display: block;
        }

        @media #{$large_mobile} {
            margin-left: 0;
            margin-top: 0;
            position: inherit;
            top: auto;
            @include transform(translateY(0));
            display: block;
        }

        @media #{$small_mobile} {
            margin-left: 0;
            margin-top: 0;
            position: inherit;
            top: auto;
            @include transform(translateY(0));
            display: block;
        }

        [class*=owl-] {
            width: 50px;
            height: 50px;

            @media #{$tab_device} {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }

            @media #{$large_mobile} {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }

            @media #{$small_mobile} {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }
        }

        .owl-prev {
            margin-left: 0;
        }

        .owl-next {
            margin-right: 0;
        }
    }
}

/*====== recruiting-carousel =======*/
.recruiting-carousel {
    margin-left: 450px;

    @media #{$tab_device} {
        margin-left: 0;
    }

    @media #{$large_mobile} {
        margin-left: 0;
    }

    @media #{$small_mobile} {
        margin-left: 0;
    }

    .owl-stage {
        padding-top: 10px;
    }
}

.recruiting-heading {
    position: absolute;
    top: 20px;
    left: 0;

    @media #{$tab_device} {
        position: inherit;
        top: auto;
        left: auto;
    }

    @media #{$large_mobile} {
        position: inherit;
        top: auto;
        left: auto;
    }

    @media #{$small_mobile} {
        position: inherit;
        top: auto;
        left: auto;
    }
}

.jobs-list {
    .media {
        margin-bottom: 20px;

        @media #{$small_mobile} {
            flex-direction: column;
            text-align: center;
        }
    }
}

.breadcrumb-list {
    font-size: $fs-15;

    li {
        display: inline-block;

        span {
            position: relative;
            top: -1px;
        }

        a {
            color: $theme-color;
            @include transition(0.3s);

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.iti {
    display: block;
}

.iti__selected-flag {
    outline: none;
}

.svg-shape {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
}

/*===== filters ======*/
.filters {
    @media #{$large_mobile} {
        flex-direction: column;
    }

    @media #{$small_mobile} {
        flex-direction: column;
    }

    h3 {
        @media #{$large_mobile} {
            margin-right: auto;
        }

        @media #{$small_mobile} {
            margin-right: auto;
        }
    }
}

.filter-option-box {
    @media #{$large_mobile} {
        width: 100% !important;
        margin-top: 10px;
    }

    @media #{$small_mobile} {
        width: 100% !important;
        margin-top: 10px;
    }
}

/*===== blockquote-box ======*/
.blockquote-box {
    border-left: 7px solid rgba(128, 137, 150, 0.2);
    padding-left: 20px;
    position: relative;

    p {
        padding-right: 30px;
        font-style: italic;
        font-size: $fs-16;
    }

    .la-quote-right {
        position: absolute;
        bottom: 10px;
        right: 5px;
        font-size: $fs-50;
        opacity: 0.2;
    }
}

.img-box {
    img {
        &:nth-child(2) {
            margin-left: -70px;
            margin-top: -70px;
            border: 10px solid $white;
        }
    }
}

/*======= sidebar ========*/
.sidebar {
    &.pt-45px {
        @media #{$tab_device} {
            padding-top: 0 !important;
        }

        @media #{$large_mobile} {
            padding-top: 0 !important;
        }

        @media #{$small_mobile} {
            padding-top: 0 !important;
        }
    }

    &.pl-30px {
        @media #{$tab_device} {
            padding-left: 0 !important;
        }

        @media #{$large_mobile} {
            padding-left: 0 !important;
        }

        @media #{$small_mobile} {
            padding-left: 0 !important;
        }
    }
}

/*====== payment-tab =======*/
.payment-tab {
    background-color: $white;
    max-height: 55px;
    overflow: hidden;
    position: relative;
    @include transition(0.5s);
    width: 100%;
    @include border-radius(8px);
    @include box-shadow(0 1px 5px rgba(0, 0, 0, 0.10));
    margin-bottom: 15px;

    &.is-active {
        max-height: 400px;

        .payment-tab-content {
            visibility: visible;
            opacity: 1;
        }
    }
}

/*====== payment-tab-content =======*/
.payment-tab-content {
    padding: 0 20px 20px 20px;
    visibility: hidden;
    opacity: 0;
    @include transition(0.2s);
}

/*===== payment logo ======*/
.payment-logo {
    position: absolute;
    right: 20px;
    top: 21px;
}

/*===== payment-tab-toggle ======*/
.payment-tab-toggle {
    padding-right: 20px;
    padding-left: 20px;

    > label {
        cursor: pointer;
        display: block;
        position: relative;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 30px;
        margin-bottom: 0;
        font-weight: $fw-medium;
        color: $theme-color;

        &:before {
            background-color: $white;
            border: 2px solid $color-gray-2;
            @include border-radius(100%);
            content: "";
            position: absolute;
            top: 18px;
            left: 0;
            width: 18px;
            height: 18px;
            @include transition(0.3s);
        }

        &:after {
            background-color: $color-gray-2;
            @include border-radius(100%);
            content: "";
            position: absolute;
            left: 5px;
            top: 23px;
            width: 8px;
            height: 8px;
            @include transition(0.3s);
            @include transform(scale(0));
        }
    }

    > input {
        position: absolute;
        visibility: hidden;

        &:checked ~ label {
            &:before {
                border-color: $theme-color-2
            }

            &:after {
                background-color: $theme-color-2;
                @include transform(scale(1));
            }
        }
    }
}

/*======= cart-totals ========*/
.cart-totals {
    @media #{$tab_device} {
        width: 100% !important;
    }

    @media #{$large_mobile} {
        width: 100% !important;
    }

    @media #{$small_mobile} {
        width: 100% !important;
    }
}

/*======= Responsive column ========*/
.responsive-column-half {
    @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    @media #{$large_mobile} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    @media #{$small_mobile} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}