$theme-color: #0d233e;
$theme-color-2: #2d86eb;
$theme-color-3: #2479d8;
$theme-color-4: #6c727c;
$theme-font: 'Ubuntu', sans-serif;
/*===== font size ====*/
$fs-100: 100px;
$fs-95: 95px;
$fs-90: 90px;
$fs-85: 85px;
$fs-80: 80px;
$fs-75: 75px;
$fs-60: 60px;
$fs-65: 65px;
$fs-55: 55px;
$fs-50: 50px;
$fs-45: 45px;
$fs-40: 40px;
$fs-35: 35px;
$fs-34: 34px;
$fs-33: 33px;
$fs-32: 32px;
$fs-31: 31px;
$fs-30: 30px;
$fs-29: 29px;
$fs-28: 28px;
$fs-27: 27px;
$fs-26: 26px;
$fs-25: 25px;
$fs-24: 24px;
$fs-23: 23px;
$fs-22: 22px;
$fs-21: 21px;
$fs-20: 20px;
$fs-19: 19px;
$fs-18: 18px;
$fs-17: 17px;
$fs-16: 16px;
$fs-15: 15px;
$fs-14: 14px;
$fs-13: 13px;
$fs-12: 12px;
$fs-11: 11px;
/*===== font weight ====*/
$fw-thin: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-black: 900;
/*===== colors ====*/
$white: #fff;
$black: #000;
$color-gray: #f7f7ff;
$color-gray-2: #ceced0;
$color-gray-3: #f6f6f6;
$color-gray-4: #f6fafd;
$color-1: #8C43FF;
$color-2: #28d5a7;
$color-3: #f9b851;
$color-4: #CC08E9;
$color-5: #FF8C2A;
$color-6: #70ef5c;
$color-7: #307CF3;
$color-8: #385499;
$color-9: #39A1F2;
$color-10: #F4414F;
$color-11: #af5d18;
$color-12: #45A163;
$color-13: #52750f;
/*===== breakpoints ====*/
$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1199px)';
$laptop_m_two: '(max-width: 1024px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';

$device-425px: '(max-width: 425px)';
$device-375px: '(max-width: 375px)';