/*
	Template Name: Disilab
	Author: TechyDevs
	Email: contact@techydevs.com
	Website: techydevs.com
	Description: Disilab -  Social Questions and Answers HTML Template
	Version: 1.0
*/
/*===== font size ====*/
/*===== font weight ====*/
/*===== colors ====*/
/*===== breakpoints ====*/
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

a:hover,
a:focus,
.btn-link:hover,
.btn-link:focus {
    text-decoration: none;
}

input:focus,
button:focus {
    outline: none;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    color: #0d233e;
}

body {
    font-size: 16px;
    line-height: 28px;
    font-family: "Ubuntu", sans-serif;
    color: #6c727c;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.theme-font-2 {
    font-family: 'Merriweather', serif !important;
}

.truncate,
.truncate-4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.truncate {
    -webkit-line-clamp: 2;
}

.truncate-4 {
    -webkit-line-clamp: 4;
}

.hide {
    display: none !important;
}

.flex-inherit {
    -ms-flex: inherit !important;
    flex: inherit !important;
}

.top-0 {
    top: 0 !important;
}

.right-0 {
    right: 0 !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.left-0 {
    left: 0 !important;
}

.z-index-1 {
    z-index: 1;
}

.z-index-n1 {
    z-index: -1;
}

.opacity-1 {
    opacity: 0.1 !important;
}

.opacity-2 {
    opacity: 0.2 !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-100px {
    width: 100px !important;
}

.h-50px {
    height: 50px !important;
}

.h-200px {
    height: 200px !important;
}

.h-400px {
    height: 400px !important;
}

.flex-inherit {
    flex: inherit !important;
}

/*---------------- fonts ----------------*/
.fs-100 {
    font-size: 100px !important;
}

.fs-90 {
    font-size: 90px !important;
}

.fs-85 {
    font-size: 85px !important;
}

.fs-80 {
    font-size: 80px !important;
}

.fs-70 {
    font-size: 70px !important;
}

.fs-65 {
    font-size: 65px !important;
}

.fs-60 {
    font-size: 60px !important;
}

.fs-55 {
    font-size: 55px !important;
}

.fs-50 {
    font-size: 50px !important;
}

.fs-45 {
    font-size: 45px !important;
}

.fs-40 {
    font-size: 40px !important;
}

.fs-35 {
    font-size: 35px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-27 {
    font-size: 27px !important;
}

.fs-26 {
    font-size: 26px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-23 {
    font-size: 23px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-21 {
    font-size: 21px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-19 {
    font-size: 19px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-11 {
    font-size: 11px !important;
}

/*---------------- font-weight ----------------*/
.fw-thin {
    font-weight: 200 !important;
}

.fw-light {
    font-weight: 300 !important;
}

.fw-regular {
    font-weight: 400 !important;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-semi-bold {
    font-weight: 600 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.fw-black {
    font-weight: 900 !important;
}

/*---------------- line-height ----------------*/
.lh-100 {
    line-height: 100px !important;
}

.lh-90 {
    line-height: 90px !important;
}

.lh-85 {
    line-height: 85px !important;
}

.lh-80 {
    line-height: 80px !important;
}

.lh-75 {
    line-height: 75px !important;
}

.lh-70 {
    line-height: 70px !important;
}

.lh-65 {
    line-height: 65px !important;
}

.lh-60 {
    line-height: 60px !important;
}

.lh-55 {
    line-height: 55px !important;
}

.lh-50 {
    line-height: 50px !important;
}

.lh-45 {
    line-height: 45px !important;
}

.lh-40 {
    line-height: 40px !important;
}

.lh-35 {
    line-height: 35px !important;
}

.lh-34 {
    line-height: 34px !important;
}

.lh-30 {
    line-height: 30px !important;
}

.lh-28 {
    line-height: 28px !important;
}

.lh-26 {
    line-height: 26px !important;
}

.lh-25 {
    line-height: 25px !important;
}

.lh-24 {
    line-height: 24px !important;
}

.lh-22 {
    line-height: 22px !important;
}

.lh-20 {
    line-height: 20px !important;
}

.lh-18 {
    line-height: 18px !important;
}

.lh-16 {
    line-height: 16px !important;
}

.lh-15 {
    line-height: 15px !important;
}

.lh-1 {
    line-height: 1 !important;
}

/*======= shadow-md ========*/
.shadow-md {
    box-shadow: 0 12px 11px rgba(0, 0, 0, 0.035), 0 100px 80px rgba(0, 0, 0, 0.05) !important;
}

/*======= radius ========*/
.rounded-full {
    -webkit-border-radius: 100% !important;
    -moz-border-radius: 100% !important;
    border-radius: 100% !important;
}

.rounded-pill {
    -webkit-border-radius: 30px !important;
    -moz-border-radius: 30px !important;
    border-radius: 30px !important;
}

.rounded-rounded {
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    border-radius: 8px !important;
}

.radius-top-left-8 {
    border-top-left-radius: 8px !important;
}

.radius-bottom-left-8 {
    border-bottom-left-radius: 8px !important;
}

.before-none::before {
    display: none !important;
}

.after-none::after {
    display: none !important;
}

.before-after-none::before, .before-after-none::after {
    display: none !important;
}

/*==== hover-y =====*/
.hover-y,
.hover-s {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.hover-y:hover {
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
    transform: translateY(-3px);
}

.hover-s:hover {
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -o-transform: scale(1.02);
    transform: scale(1.02);
}

/*==== border color =====*/
.border-gray {
    border-color: rgba(128, 137, 150, 0.1) !important;
}

.border-top-gray {
    border-top-color: rgba(128, 137, 150, 0.1) !important;
}

.border-right-gray {
    border-right-color: rgba(128, 137, 150, 0.1) !important;
}

.border-bottom-gray {
    border-bottom-color: rgba(128, 137, 150, 0.1) !important;
}

.border-left-gray {
    border-left-color: rgba(128, 137, 150, 0.1) !important;
}

/*==== bg =====*/
.bg-diagonal-gradient-primary {
    background: #3cb1c6 !important;
    background: -moz-linear-gradient(-45deg, #3597a9 0%, #78e7f1 100%) !important;
    background: -webkit-linear-gradient(-45deg, #3597a9 0%, #78e7f1 100%) !important;
    background: linear-gradient(135deg, #3597a9 0%, #78e7f1 100%) !important;
}

.bg-diagonal-gradient-primary-2 {
    background: #3597a9 !important;
    background: -moz-linear-gradient(-45deg, #3597a9 0%, #78e7f1 100%) !important;
    background: -webkit-linear-gradient(-45deg, #3597a9 0%, #78e7f1 100%) !important;
    background: linear-gradient(135deg, #2c65bd 0%, #55989f 100%) !important;
}

.bg-vertical-gradient-gray {
    background: #ffffff !important;
    background: -moz-linear-gradient(top, #ffffff 0%, #f7f7f7 100%) !important;
    background: -webkit-linear-gradient(top, #ffffff 0%, #f7f7f7 100%) !important;
    background: linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%) !important;
}

.bg-radial-gradient-gray {
    background: #f6f6f6 !important;
    background: radial-gradient(circle, #f6f6f6 0%, white 88%) !important;
}

.bg-gray {
    background-color: #f7f7ff !important;
}

.bg-gray-2 {
    background-color: #ceced0 !important;
}

.bg-1 {
    background-color: #8C43FF !important;
}

.bg-2 {
    background-color: #28d5a7 !important;
}

.bg-3 {
    background-color: #f9b851 !important;
}

.bg-4 {
    background-color: #CC08E9 !important;
}

.bg-5 {
    background-color: #FF8C2A !important;
}

.bg-6 {
    background-color: #70ef5c !important;
}

.bg-7 {
    background-color: #307CF3 !important;
}

.bg-8 {
    background-color: #385499 !important;
}

.bg-9 {
    background-color: #39A1F2 !important;
}

.bg-10 {
    background-color: #F4414F !important;
}

.bg-11 {
    background-color: #af5d18 !important;
}

.bg-12 {
    background-color: #2d86eb !important;
}

/*---------------- text-color ----------------*/
.text-black {
    color: #0d233e !important;
}

.text-gray {
    color: #6c727c !important;
}

.text-gray-2 {
    color: #ceced0 !important;
}

.text-color {
    color: #2d86eb !important;
}

.text-color-2 {
    color: #8C43FF !important;
}

.text-color-3 {
    color: #28d5a7 !important;
}

.text-color-4 {
    color: #f9b851 !important;
}

.text-color-5 {
    color: #CC08E9 !important;
}

.text-color-6 {
    color: #FF8C2A !important;
}

.text-color-7 {
    color: #70ef5c !important;
}

.text-color-8 {
    color: #307CF3 !important;
}

.text-color-9 {
    color: #385499 !important;
}

.text-color-10 {
    color: #39A1F2 !important;
}

.text-color-11 {
    color: #F4414F !important;
}

.text-color-12 {
    color: #af5d18 !important;
}

/*---------------- container ----------------*/
.container {
    max-width: 1280px;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

/*======== padding ========*/
.section-padding {
    padding-top: 120px;
    padding-bottom: 120px;
}

.section--padding {
    padding-top: 120px;
    padding-bottom: 90px;
}

/*====== padding top =======*/
.pt-200px {
    padding-top: 200px !important;
}

.pt-190px {
    padding-top: 190px !important;
}

.pt-180px {
    padding-top: 180px !important;
}

.pt-170px {
    padding-top: 170px !important;
}

.pt-160px {
    padding-top: 160px !important;
}

.pt-150px {
    padding-top: 150px !important;
}

.pt-140px {
    padding-top: 140px !important;
}

.pt-130px {
    padding-top: 130px !important;
}

.pt-120px {
    padding-top: 120px !important;
}

.pt-115px {
    padding-top: 115px !important;
}

.pt-110px {
    padding-top: 110px !important;
}

.pt-100px {
    padding-top: 100px !important;
}

.pt-95px {
    padding-top: 95px !important;
}

.pt-90px {
    padding-top: 90px !important;
}

.pt-85px {
    padding-top: 85px !important;
}

.pt-80px {
    padding-top: 80px !important;
}

.pt-75px {
    padding-top: 75px !important;
}

.pt-70px {
    padding-top: 70px !important;
}

.pt-60px {
    padding-top: 60px !important;
}

.pt-50px {
    padding-top: 50px !important;
}

.pt-45px {
    padding-top: 45px !important;
}

.pt-40px {
    padding-top: 40px !important;
}

.pt-35px {
    padding-top: 35px !important;
}

.pt-30px {
    padding-top: 30px !important;
}

.pt-20px {
    padding-top: 20px !important;
}

.pt-10px {
    padding-top: 10px !important;
}

/*====== padding bottom =======*/
.pb-200px {
    padding-bottom: 200px !important;
}

.pb-190px {
    padding-bottom: 190px !important;
}

.pb-180px {
    padding-bottom: 180px !important;
}

.pb-170px {
    padding-bottom: 170px !important;
}

.pb-160px {
    padding-bottom: 160px !important;
}

.pb-150px {
    padding-bottom: 150px !important;
}

.pb-140px {
    padding-bottom: 140px !important;
}

.pb-130px {
    padding-bottom: 130px !important;
}

.pb-120px {
    padding-bottom: 120px !important;
}

.pb-115px {
    padding-bottom: 115px !important;
}

.pb-110px {
    padding-bottom: 110px !important;
}

.pb-100px {
    padding-bottom: 100px !important;
}

.pb-95px {
    padding-bottom: 95px !important;
}

.pb-90px {
    padding-bottom: 90px !important;
}

.pb-85px {
    padding-bottom: 85px !important;
}

.pb-80px {
    padding-bottom: 80px !important;
}

.pb-75px {
    padding-bottom: 75px !important;
}

.pb-70px {
    padding-bottom: 70px !important;
}

.pb-60px {
    padding-bottom: 60px !important;
}

.pb-50px {
    padding-bottom: 50px !important;
}

.pb-45px {
    padding-bottom: 45px !important;
}

.pb-40px {
    padding-bottom: 40px !important;
}

.pb-35px {
    padding-bottom: 35px !important;
}

.pb-30px {
    padding-bottom: 30px !important;
}

.pb-20px {
    padding-bottom: 20px !important;
}

.pb-10px {
    padding-bottom: 10px !important;
}

/*======= padding right ========*/
.pr-150px {
    padding-right: 150px !important;
}

.pr-140px {
    padding-right: 140px !important;
}

.pr-130px {
    padding-right: 130px !important;
}

.pr-120px {
    padding-right: 120px !important;
}

.pr-110px {
    padding-right: 110px !important;
}

.pr-100px {
    padding-right: 100px !important;
}

.pr-95px {
    padding-right: 95px !important;
}

.pr-90px {
    padding-right: 90px !important;
}

.pr-85px {
    padding-right: 85px !important;
}

.pr-80px {
    padding-right: 80px !important;
}

.pr-70px {
    padding-right: 70px !important;
}

.pr-60px {
    padding-right: 60px !important;
}

.pr-55px {
    padding-right: 55px !important;
}

.pr-50px {
    padding-right: 50px !important;
}

.pr-45px {
    padding-right: 45px !important;
}

.pr-40px {
    padding-right: 40px !important;
}

.pr-30px {
    padding-right: 30px !important;
}

/*======== padding left ========*/
.pl-150px {
    padding-left: 150px !important;
}

.pl-140px {
    padding-left: 140px !important;
}

.pl-130px {
    padding-left: 130px !important;
}

.pl-120px {
    padding-left: 120px !important;
}

.pl-110px {
    padding-left: 110px !important;
}

.pl-100px {
    padding-left: 100px !important;
}

.pl-95px {
    padding-left: 95px !important;
}

.pl-90px {
    padding-left: 90px !important;
}

.pl-85px {
    padding-left: 85px !important;
}

.pl-80px {
    padding-left: 80px !important;
}

.pl-70px {
    padding-left: 70px !important;
}

.pl-60px {
    padding-left: 60px !important;
}

.pl-55px {
    padding-left: 55px !important;
}

.pl-50px {
    padding-left: 50px !important;
}

.pl-45px {
    padding-left: 45px !important;
}

.pl-40px {
    padding-left: 40px !important;
}

.pl-30px {
    padding-left: 30px !important;
}

/*===== margin top ====*/
.mt-200px {
    margin-top: 200px;
}

.mt-190px {
    margin-top: 190px;
}

.mt-180px {
    margin-top: 180px;
}

.mt-160px {
    margin-top: 160px;
}

.mt-150px {
    margin-top: 150px;
}

.mt-140px {
    margin-top: 140px;
}

.mt-130px {
    margin-top: 130px;
}

.mt-120px {
    margin-top: 120px;
}

.mt-110px {
    margin-top: 110px;
}

.mt-100px {
    margin-top: 100px;
}

.mt-95px {
    margin-top: 95px;
}

.mt-90px {
    margin-top: 90px;
}

.mt-85px {
    margin-top: 85px;
}

.mt-80px {
    margin-top: 80px;
}

.mt-70px {
    margin-top: 70px;
}

.mt-60px {
    margin-top: 60px;
}

.mt-55px {
    margin-top: 55px;
}

.mt-50px {
    margin-top: 50px;
}

.mt-45px {
    margin-top: 45px;
}

.mt-40px {
    margin-top: 40px;
}

.mt-35px {
    margin-top: 35px;
}

.mt-30px {
    margin-top: 30px;
}

.mt-28px {
    margin-top: 28px;
}

.mt-20px {
    margin-top: 20px;
}

.mt-10px {
    margin-top: 10px;
}

.mt-5px {
    margin-top: 5px;
}

/*======= margin bottom =======*/
.mb-200px {
    margin-bottom: 200px;
}

.mb-190px {
    margin-bottom: 190px;
}

.mb-180px {
    margin-bottom: 180px;
}

.mb-170px {
    margin-bottom: 170px;
}

.mb-160px {
    margin-bottom: 160px;
}

.mb-150px {
    margin-bottom: 150px;
}

.mb-140px {
    margin-bottom: 140px;
}

.mb-130px {
    margin-bottom: 130px;
}

.mb-120px {
    margin-bottom: 120px;
}

.mb-110px {
    margin-bottom: 110px;
}

.mb-100px {
    margin-bottom: 100px;
}

.mb-95px {
    margin-bottom: 95px;
}

.mb-90px {
    margin-bottom: 90px;
}

.mb-85px {
    margin-bottom: 85px;
}

.mb-80px {
    margin-bottom: 80px;
}

.mb-70px {
    margin-bottom: 70px;
}

.mb-60px {
    margin-bottom: 60px;
}

.mb-50px {
    margin-bottom: 50px;
}

.mb-55px {
    margin-bottom: 55px;
}

.mb-45px {
    margin-bottom: 45px;
}

.mb-40px {
    margin-bottom: 40px;
}

.mb-35px {
    margin-bottom: 35px;
}

.mb-30px {
    margin-bottom: 30px;
}

.mb-28px {
    margin-bottom: 28px;
}

.mb-20px {
    margin-bottom: 20px;
}

.mb-10px {
    margin-bottom: 10px;
}

.mb-5px {
    margin-bottom: 5px;
}

/*========= theme-btn ========*/
.theme-btn {
    background-color: #2d86eb;
    color: #fff;
    font-weight: 500;
    padding-right: 18px;
    padding-left: 18px;
    line-height: 30px;
    font-size: 15px;
    position: relative;
}

.theme-btn:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.theme-btn:hover {
    background-color: #2479d8;
    color: #fff;
}

.theme-btn:hover .icon {
    padding-left: 5px;
}

.icon {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

/*===== theme-btn-gray ======*/
.theme-btn-gray {
    background-color: rgba(128, 137, 150, 0.08);
    color: #0d233e;
}

.theme-btn-gray:hover {
    background-color: rgba(128, 137, 150, 0.15);
    color: #0d233e;
}

.btn-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 45px;
    height: 100%;
    background-color: rgba(128, 137, 150, 0.2);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-btn {
    background-color: rgba(244, 65, 79, 0.08);
    color: #F4414F;
}

.google-btn .btn-icon {
    background-color: rgba(244, 65, 79, 0.2);
}

.google-btn:hover {
    background-color: rgba(244, 65, 79, 0.15);
    color: #F4414F;
}

.facebook-btn {
    background-color: rgba(56, 84, 153, 0.08);
    color: #385499;
}

.facebook-btn .btn-icon {
    background-color: rgba(56, 84, 153, 0.2);
}

.facebook-btn:hover {
    background-color: rgba(56, 84, 153, 0.15);
    color: #385499;
}

.twitter-btn {
    background-color: rgba(57, 161, 242, 0.08);
    color: #39A1F2;
}

.twitter-btn .btn-icon {
    background-color: rgba(57, 161, 242, 0.2);
}

.twitter-btn:hover {
    background-color: rgba(57, 161, 242, 0.15);
    color: #39A1F2;
}

/*===== theme-btn-outline ======*/
.theme-btn-outline {
    background-color: transparent;
    color: #2d86eb;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #2d86eb;
}

.theme-btn-outline:hover {
    background-color: #2d86eb;
    border-color: #2d86eb;
    color: #fff;
}

.theme-btn-outline-gray {
    border-color: rgba(127, 136, 151, 0.2);
    color: #6c727c;
}

.theme-btn-outline-white {
    border-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.7);
}

/*===== theme-btn-sm ======*/
.theme-btn-sm {
    line-height: 23px;
    padding-right: 14px;
    padding-left: 14px;
    font-size: 14px;
}

/*===== theme-btn-white ======*/
.theme-btn-white {
    background-color: #fff;
    color: #0d233e;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.theme-btn-white:hover {
    background-color: #2d86eb;
}

/*==== collapse-btn =====*/
.collapse-btn {
    color: #6c727c;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.collapse-btn.theme-btn-outline {
    color: #2d86eb;
}

.collapse-btn.theme-btn-outline:hover {
    color: #fff;
}

.collapse-btn:hover {
    color: #2d86eb;
}

.collapse-btn[aria-expanded="true"] .collapse-btn-show {
    display: inline-block;
}

.collapse-btn[aria-expanded="true"] .collapse-btn-hide {
    display: none;
}

.collapse-btn[aria-expanded="false"] .collapse-btn-show {
    display: none;
}

/*======== btn-text ========*/
.btn-text {
    color: #6c727c;
    font-weight: 500;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    font-size: 15px;
}

.btn-text:hover {
    color: #2d86eb;
}

.btn-text:hover .icon {
    padding-left: 5px;
}

/*===== btn--group =====*/
.btn--group .btn {
    border-color: rgba(128, 137, 150, 0.3);
    color: #6c727c;
    font-size: 15px;
}

.btn--group .btn.focus, .btn--group .btn:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.btn--group .btn.active {
    background-color: #2d86eb;
    color: #fff;
    border-color: #2d86eb;
}

.btn--group .btn:hover:not(.active) {
    background-color: rgba(128, 137, 150, 0.07);
}

.btn[disabled] {
    opacity: .5;
    pointer-events: none;
}

.hover-bg-gray:hover {
    background-color: rgba(128, 137, 150, 0.1);
}

/*---------------- css loader ----------------*/
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

/* Begin CSS for global animation */
@keyframes tilt {
    0% {
        -webkit-transform: rotateX(-30deg);
        -moz-transform: rotateX(-30deg);
        transform: rotateX(-30deg);
    }
    25% {
        -webkit-transform: rotateX(30deg);
        -moz-transform: rotateX(30deg);
        transform: rotateX(30deg);
    }
    50% {
        -webkit-transform: rotateY(-30deg);
        -moz-transform: rotateY(-30deg);
        transform: rotateY(-30deg);
    }
    75% {
        -webkit-transform: rotateY(30deg);
        -moz-transform: rotateY(30deg);
        transform: rotateY(30deg);
    }
    100% {
        -webkit-transform: rotateZ(20deg);
        -moz-transform: rotateZ(20deg);
        transform: rotateZ(20deg);
    }
}

@keyframes scale {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        transform: scale(1.1);
    }
}

@keyframes bounce {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 100px, 0);
        transform: translate3d(0, 100px, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes scale {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(2);
        -moz-transform: scale(2);
        transform: scale(2);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes swing {
    0% {
        -webkit-transform: translate3d(70px, 0, 0) rotateZ(10deg);
        -moz-transform: translate3d(70px, 0, 0) rotateZ(10deg);
        transform: translate3d(70px, 0, 0) rotateZ(10deg);
    }
    100% {
        -webkit-transform: translate3d(-70px, 0, 0) rotateZ(-10deg);
        -moz-transform: translate3d(-70px, 0, 0) rotateZ(-10deg);
        transform: translate3d(-70px, 0, 0) rotateZ(-10deg);
    }
}

/*======= file upload ========*/
.file-upload-wrap {
    position: relative;
    width: 170px;
}

.file-upload-wrap .file-upload-input {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid rgba(128, 137, 150, 0.2);
    height: 50px;
    text-align: center;
    cursor: pointer;
    position: relative;
    display: inline-block;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    text-indent: -99999px;
}

.file-upload-wrap .file-upload-input:hover {
    border-color: rgba(128, 137, 150, 0.4);
    background-color: rgba(128, 137, 150, 0.04);
}

.file-upload-wrap .file-upload-text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
    text-align: center;
    font-size: 15px;
    color: #6c727c;
    line-height: 50px;
    padding-left: 20px;
}

.file-upload-wrap .MultiFile-list {
    margin-top: 15px;
}

.file-upload-wrap .MultiFile-list > .MultiFile-label {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: rgba(128, 137, 150, 0.04);
    display: inline-block;
    border: 1px solid rgba(128, 137, 150, 0.1);
    padding: 10px;
    position: relative;
    width: 100%;
}

.file-upload-wrap .MultiFile-list > .MultiFile-label + .MultiFile-label {
    margin-top: 20px;
}

.file-upload-wrap .MultiFile-list > .MultiFile-label > span {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.file-upload-wrap .MultiFile-list > .MultiFile-label > span .MultiFile-label {
    width: 20%;
    margin: 5px;
}

.file-upload-wrap .MultiFile-list .MultiFile-remove {
    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    position: absolute;
    background-color: #fff;
    color: #0d233e;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    top: -10px;
    right: -10px;
    z-index: 1;
    font-size: 15px;
    font-weight: 500;
}

.file-upload-wrap .MultiFile-list .MultiFile-remove:hover {
    color: #F4414F;
}

.file--upload-wrap .MultiFile-list > .MultiFile-label > span .MultiFile-label {
    width: auto;
}

/*======== file-upload-layout-2 =========*/
.file-upload-layout-2 {
    width: auto;
}

.file-upload-layout-2 .file-upload-input {
    height: 125px;
    border-style: dashed;
    border-width: 2px;
}

.file-upload-layout-2 .file-upload-text {
    width: 100%;
    line-height: 125px;
    padding-left: 0;
}

.modal--open {
    overflow: hidden;
}

/* modal container */
.modal-container .close {
    padding: 0;
    margin: 0;
}

.modal-container.fade .modal-dialog {
    -webkit-transform: scale(0.8) translate(0, 0);
    -moz-transform: scale(0.8) translate(0, 0);
    -ms-transform: scale(0.8) translate(0, 0);
    -o-transform: scale(0.8) translate(0, 0);
    transform: scale(0.8) translate(0, 0);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.modal-container .modal-header {
    border-bottom-color: rgba(128, 137, 150, 0.1);
}

.modal-container .modal-content {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

.modal-container .modal-dialog {
    -webkit-transform: scale(0.8) translate(0, 0);
    -moz-transform: scale(0.8) translate(0, 0);
    -ms-transform: scale(0.8) translate(0, 0);
    -o-transform: scale(0.8) translate(0, 0);
    transform: scale(0.8) translate(0, 0);
}

.modal-container.show .modal-dialog {
    -webkit-transform: scale(1) translate(0, 0);
    -moz-transform: scale(1) translate(0, 0);
    -ms-transform: scale(1) translate(0, 0);
    -o-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
}

.close {
    font-size: 18px;
    width: 30px;
    height: 30px;
    padding: 0;
    line-height: 32px;
    border-radius: 100%;
    margin: 0;
    opacity: 1;
    color: #0d233e;
    text-shadow: none;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.close:hover {
    background-color: rgba(128, 137, 150, 0.1);
}

.close:not(:disabled):not(.disabled):hover {
    opacity: 1;
}

/*======= comments-list ========*/
.comments-list li {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .comments-list li {
        flex-direction: column;
        text-align: center;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .comments-list li {
        flex-direction: column;
        text-align: center;
    }
}

.comments-list li.comment-reply {
    margin-left: 64px;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .comments-list li.comment-reply {
        margin-left: 0;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .comments-list li.comment-reply {
        margin-left: 0;
    }
}

/*======= comment-actions ========*/
.comment-actions {
    width: 60px;
    font-weight: 500;
    padding: 13px;
    font-size: 14px;
    background-color: rgba(128, 137, 150, 0.04);
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    line-height: 1.5;
}

/*======= comment-body ========*/
.comment-body {
    font-size: 14px;
    padding: 13px;
    line-height: 1.5;
}

.comment-avatar {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .comment-avatar {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 13px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .comment-avatar {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 13px;
    }
}

.comment-avatar img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    width: 100%;
    height: 100%;
}

.comment-user {
    font-weight: 500;
    margin-right: 1px;
}

.comment-user.owner {
    background-color: rgba(13, 35, 62, 0.1);
    padding: 1px 5px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.comment-separated {
    margin-right: 1px;
    margin-left: 1px;
}

.comment-user,
.comment-date {
    color: #0d233e;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.comment-user:hover,
.comment-date:hover {
    color: #2d86eb;
}

/*======= comment-form =======*/
.comment-form {
    width: 100%;
    background-color: rgba(128, 137, 150, 0.04);
    padding: 10px 30px;
}

.comment-link {
    font-size: 14px;
    color: #6c727c;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.comment-link:hover {
    color: #2479d8;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cta-heading,
    .error-content {
        flex-direction: column;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .cta-heading,
    .error-content {
        flex-direction: column;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .cta-heading,
    .error-content {
        flex-direction: column;
    }
}

.undraw-illustration {
    width: 290px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .undraw-illustration,
    .error-content-img {
        margin-bottom: 35px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .undraw-illustration,
    .error-content-img {
        margin-bottom: 35px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .undraw-illustration,
    .error-content-img {
        margin-bottom: 35px;
        width: 100%;
    }
}

.form-group {
    position: relative;
}

.input-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 20px;
    pointer-events: none;
}

.form-btn {
    position: absolute;
    color: #6c727c;
    padding: 12px 14px;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 18px;
    background-color: transparent;
    border: none;
}

.form-btn .la {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.form-btn:hover .la {
    color: #0d233e;
}

.form--control {
    height: 50px;
    font-size: 15px;
    border-color: rgba(127, 136, 151, 0.2);
    padding-right: 18px;
    padding-left: 18px;
    color: #0d233e;
}

.form--control:focus {
    border-color: #2d86eb;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.form--control::-webkit-input-placeholder {
    color: rgba(128, 137, 150, 0.7);
}

.form--control:-ms-input-placeholder {
    color: rgba(128, 137, 150, 0.7);
}

.form--control::placeholder {
    color: rgba(128, 137, 150, 0.7);
}

.form--control-bg-gray {
    background-color: rgba(127, 136, 151, 0.1);
    border: 0;
}

.form--control-bg-gray:focus {
    background-color: rgba(127, 136, 151, 0.1);
}

.form-control-sm {
    height: 35px;
    padding-right: 12px;
    padding-left: 12px;
}

.custom-select {
    cursor: pointer;
    border-color: rgba(127, 136, 151, 0.2);
    color: #6c727c;
    font-size: 15px;
}

.custom-select:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: #2d86eb;
}

.custom--select {
    height: 50px;
}

/*================= Chosen select ====================*/
.selectize-dropdown,
.selectize-input,
.selectize-input input {
    font-size: 14px;
}

.selectize-input {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: rgba(127, 136, 151, 0.2);
    padding-left: 14px;
}

.selectize-input.focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.selectize-input > * {
    color: #6c727c;
}

.selectize-dropdown {
    border-color: rgba(127, 136, 151, 0.2);
}

.selectize-dropdown .active {
    background-color: rgba(127, 136, 151, 0.1);
    color: #0d233e;
}

.selectize-dropdown .option {
    padding: 6px 14px;
}

.selectize-control.multi .selectize-input > div {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 3px 7px;
    background-color: rgba(127, 136, 151, 0.1);
}

.input--tags .selectize-input,
.select--container .selectize-input {
    padding-top: 14px;
    padding-bottom: 14px;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    form.w-25 {
        width: 100% !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    form.w-25 {
        width: 100% !important;
    }
}

/*========= custom--control-label ==========*/
.custom--control-label {
    cursor: pointer;
}

.custom--control-label::before, .custom--control-label::after {
    top: .35rem;
}

.lost-pass-btn {
    color: #6c727c;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.lost-pass-btn:hover {
    color: #2d86eb;
}

/*==== leaflet-bar ======*/
.leaflet-bar {
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.leaflet-bar a {
    border-bottom-color: rgba(128, 137, 150, 0.1);
    color: #0d233e;
}

.leaflet-bar a:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.leaflet-bar a:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.leaflet-bar a:hover {
    border-bottom-color: rgba(128, 137, 150, 0.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-information-wrap {
        padding-left: 0 !important;
        padding-top: 40px;
        border-left: 0 !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .contact-information-wrap {
        padding-left: 0 !important;
        padding-top: 40px;
        border-left: 0 !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .contact-information-wrap {
        padding-left: 0 !important;
        padding-top: 40px;
        border-left: 0 !important;
    }
}

.contact-success-message,
.spinner-icon {
    display: none;
}

/*=========== toggle-password =============*/
.toggle-password .eye-off {
    display: none;
}

.toggle-password.active .eye-off {
    display: block;
}

.toggle-password.active .eye-on {
    display: none;
}

.toggle-password:hover svg {
    fill: #fff;
}

.shape-bg {
    background-image: url("../imageshtml/line-shape3.png");
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .form-content h3 {
        font-size: 24px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .form-content.radius-top-left-8 {
        border-top-right-radius: 8px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .form-content.radius-top-left-8 {
        border-top-right-radius: 8px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .form-content.radius-top-left-8 {
        border-top-right-radius: 8px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .form-content.radius-bottom-left-8 {
        border-bottom-left-radius: 0 !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .form-content.radius-bottom-left-8 {
        border-bottom-left-radius: 0 !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .form-content.radius-bottom-left-8 {
        border-bottom-left-radius: 0 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .form-action-wrapper {
        padding-right: 50px;
        padding-left: 50px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .form-action-wrapper {
        padding-right: 50px;
        padding-left: 50px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .form-action-wrapper {
        padding-right: 50px;
        padding-left: 50px;
    }
}

/*======= info-box =======*/
.info-box {
    margin-bottom: 30px;
}

.info-box .icon-element {
    position: relative;
}

.info-box .info-number {
    position: absolute;
    top: 0;
    right: -10px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background-color: rgba(128, 137, 150, 0.1);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    display: block;
    font-size: 16px;
    font-weight: 500;
}

.info-box:hover .icon-element {
    -webkit-transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    -o-transform: translateY(-4px);
    transform: translateY(-4px);
}

/*===== generic-accordion =======*/
.generic-accordion .card {
    border-color: rgba(128, 137, 150, 0.1);
    margin-bottom: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 5px 0 rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 5px 0 rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 5px 0 rgba(82, 85, 90, 0.1);
}

.generic-accordion .card-header {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border-bottom: 0;
    background-color: transparent;
    padding: 0;
}

.generic-accordion .card-header .btn {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #0d233e;
    font-weight: 500;
    padding: 10px 20px;
    border-bottom: 1px solid transparent;
}

.generic-accordion .card-header .btn .collapse-icon {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.generic-accordion .card-header .btn[aria-expanded="true"] {
    border-bottom-color: rgba(128, 137, 150, 0.1);
}

.generic-accordion .card-header .btn[aria-expanded="true"] .collapse-icon {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.generic-accordion .card-body {
    padding: 1.15rem !important;
}

/*======== skillbar ========*/
.progress-slim {
    height: 4px;
}

.progress-fat {
    height: 24px;
}

/*===== icon-element ======*/
.icon-element {
    color: #0d233e;
    font-size: 30px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px rgba(14, 16, 48, 0.1);
    -moz-box-shadow: 0 0 10px rgba(14, 16, 48, 0.1);
    box-shadow: 0 0 10px rgba(14, 16, 48, 0.1);
    text-align: center;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    display: block;
}

.icon-element-lg {
    width: 80px;
    height: 80px;
    line-height: 80px;
}

.icon-element-sm {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 24px;
}

.icon-element-xs {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
}

.generic-table {
    background-color: #fff;
    border: 0;
    color: #0d233e;
    -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

.generic-table thead {
    background-color: rgba(128, 137, 150, 0.1);
}

.generic-table thead th {
    border-top: 0;
    border-bottom: 0;
}

.generic-table td,
.generic-table th {
    padding: 1rem 2rem;
    font-weight: 500;
    vertical-align: middle;
    border-top-color: rgba(128, 137, 150, 0.1);
}

.generic--table td,
.generic--table th {
    padding: 9px 20px;
}

.generic--table td {
    color: #6c727c;
}

/*======== card-item =========*/
.card-item {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-box-shadow: 0 0 8px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 8px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 8px rgba(82, 85, 90, 0.1);
    border: 0;
}

.card-item .card-img {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

.card-item .card-img img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
}

.card-item .card-link {
    color: #6c727c;
    font-size: 14px;
    display: inline-block;
    line-height: 20px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    top: -15px;
    z-index: 2;
    background-color: #fff;
    padding: 4px 11px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.card-item .card-link:hover {
    color: #0d233e;
}

.card-item .card-title {
    color: #0d233e;
    line-height: 25px;
    font-size: 20px;
    font-weight: 700;
}

.card-item .card-title a {
    color: #0d233e;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.card-item .card-title a:hover {
    color: #2d86eb;
}

.card-item .card-text a {
    color: #6c727c;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.card-item .card-text a:hover {
    color: #2d86eb;
}

.card-item .card-body {
    padding: 1.6rem;
}

/*======== media-card =======*/
.media-card {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    padding: 25px;
    background-color: #fff;
}

.media-card .media-img {
    width: 80px;
    height: 80px;
    margin-right: 15px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

.media-card .media-img img {
    width: 100%;
    height: 100%;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

.media-card .media--img {
    border: 5px solid #fff;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
}

.media-card .media--img img {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
}

.media-card .media-body h5 {
    color: #0d233e;
    font-size: 18px;
    font-weight: 700;
}

.media-card .media-body h5 a {
    color: #0d233e;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.media-card .media-body h5 a:hover {
    color: #2d86eb;
}

.media-img-sm {
    width: 45px !important;
    height: 45px !important;
}

.media-img--sm {
    width: 39px !important;
    height: 39px !important;
}

.media-img-xs {
    width: 30px !important;
    height: 30px !important;
}

/*======== media--card =======*/
.media--card {
    margin-bottom: 15px;
    padding: 0;
}

.media--card .media-body {
    border-left: 1px solid rgba(128, 137, 150, 0.1);
    padding: 12px 20px;
}

.media--card .media-body h5 {
    font-weight: 500;
    font-size: 16px;
}

/*======== media--card-2 =======*/
.media--card-2 {
    padding: 0 0 15px 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
}

.media--card-2:last-child {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
}

.media--card-2 .media-body {
    padding: 0;
    border-left: 0;
}

.media--card-2 .media-body h5 {
    font-size: 15px;
    line-height: 21px;
}

/*======== user-media =======*/
.user-media {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
    margin-bottom: 0;
    padding: 10px;
}

.user-media.owner {
    background-color: #f6fafd;
}

.user-media .media-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.user-media .media-body {
    padding: 0;
    border-left: 0;
}

.user-media .media-body h5 {
    font-size: 14px;
    font-weight: 500;
}

/*======== user--media =======*/
.user--media {
    border-bottom: 0;
    padding: 0;
}

/*======== award-card =======*/
.award-card .card-body {
    color: #6c727c;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.award-card .card-body:hover {
    color: #2d86eb;
}

/*======== case-card =======*/
.case-card {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    margin-right: 8px;
    margin-left: 8px;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -ms-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .case-card {
        margin-right: 0;
        margin-left: 0;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .case-card {
        margin-right: 0;
        margin-left: 0;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .case-card {
        margin-right: 0;
        margin-left: 0;
    }
}

.case-card .card-text {
    display: none;
}

.case-card .card-body {
    padding-right: 0;
    padding-left: 0;
}

.case-card.case-card-is-active .card-text {
    display: block;
}

.case-card.case-card-is-active svg {
    fill: #2d86eb;
}

.case-card.case-card-is-active, .case-card:hover {
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    z-index: 2;
}

/*======== work-content-card =======*/
.work-content-card {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    width: 286px;
    height: 286px;
    margin: 12px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.remote {
    width: 334px;
    height: 334px;
}

.austin,
.munich {
    width: 80px;
    height: 80px;
}

.london {
    width: 120px;
    height: 120px;
}

/* cat-item */
.cat-item {
    padding: 5px 10px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    background-color: #fff;
    -webkit-box-shadow: 0 0 8px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 8px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 8px rgba(82, 85, 90, 0.1);
    color: #0d233e;
    font-size: 14px;
    font-weight: 500;
}

.cat-item .cat-number {
    color: #6c727c;
}

.cat-item:hover {
    color: #2d86eb;
}

/*======== ad-banner =======*/
.ad-banner {
    width: 290px;
    height: 500px;
    background-color: rgba(128, 137, 150, 0.06);
    position: relative;
}

.ad-banner .ad-text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: 500;
}

.ad-banner-2 {
    height: 300px;
}

/*-================== loading-area ==============-*/
#preloader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #fff;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 50px;
    height: 50px;
}

.loader .spinner .path {
    stroke: #2d86eb;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

.section-title {
    font-size: 35px;
    font-weight: 700;
}

@media (max-width: 375px) {
    .section-title {
        font-size: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title br,
    .section-desc br {
        display: none;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .section-title br,
    .section-desc br {
        display: none;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .section-title br,
    .section-desc br {
        display: none;
    }
}

.section-desc {
    line-height: 30px;
    font-size: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-desc {
        width: auto !important;
        margin-left: inherit !important;
        margin-right: inherit !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .section-desc {
        width: auto !important;
        margin-left: inherit !important;
        margin-right: inherit !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .section-desc {
        width: auto !important;
        margin-left: inherit !important;
        margin-right: inherit !important;
        font-size: 15px;
        line-height: 26px;
    }
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: .8;
    pointer-events: none;
    z-index: -1;
}

/*======== icon-shape ========*/
.icon-shape {
    position: absolute;
    background-color: #8C43FF;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    opacity: 0.3;
}

.icon-shape.icon-shape-1 {
    left: 4%;
    top: 15%;
}

.icon-shape.icon-shape-2 {
    left: 30%;
    top: 10%;
    background-color: #28d5a7;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.icon-shape.icon-shape-3 {
    left: 13%;
    top: 50%;
    background-color: #f9b851;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}

.icon-shape.icon-shape-4 {
    left: 40%;
    bottom: 10%;
    background-color: #CC08E9;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.icon-shape.icon-shape-5 {
    right: 5%;
    top: 10%;
    background-color: #FF8C2A;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.icon-shape.icon-shape-6 {
    right: 25%;
    top: 30%;
    background-color: #70ef5c;
    clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.icon-shape.icon-shape-7 {
    right: 16%;
    bottom: 23%;
    background-color: #307CF3;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

/*======= stroke-shape ========*/
.stroke-shape {
    position: absolute;
    width: 120px;
    -webkit-transform: rotate(-55deg);
    -moz-transform: rotate(-55deg);
    -ms-transform: rotate(-55deg);
    -o-transform: rotate(-55deg);
    transform: rotate(-55deg);
    opacity: 0.1;
    background-color: #6c727c;
    height: 2px;
    display: block;
}

.stroke-shape.stroke-shape-1, .stroke-shape.stroke-shape-2, .stroke-shape.stroke-shape-3 {
    top: 175px;
}

.stroke-shape.stroke-shape-1 {
    left: 70px;
}

.stroke-shape.stroke-shape-2 {
    left: 90px;
}

.stroke-shape.stroke-shape-3 {
    left: 110px;
}

.stroke-shape.stroke-shape-4 {
    right: 70px;
}

.stroke-shape.stroke-shape-5 {
    right: 90px;
}

.stroke-shape.stroke-shape-6 {
    right: 110px;
}

.stroke-shape.stroke-shape-4, .stroke-shape.stroke-shape-5, .stroke-shape.stroke-shape-6 {
    bottom: 70px;
    -webkit-transform: rotate(55deg);
    -moz-transform: rotate(55deg);
    -ms-transform: rotate(55deg);
    -o-transform: rotate(55deg);
    transform: rotate(55deg);
}

/*====== stroke-shape-white ========*/
.stroke-shape-white {
    background-color: #fff;
}

/*====== vertical-bar-shape ========*/
.vertical-bar-shape {
    position: absolute;
    top: 0;
    width: 40px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.01);
    pointer-events: none;
}

.vertical-bar-shape.vertical-bar-shape-1 {
    left: 0;
}

.vertical-bar-shape.vertical-bar-shape-2 {
    left: 33%;
}

.vertical-bar-shape.vertical-bar-shape-3 {
    right: 33%;
}

.vertical-bar-shape.vertical-bar-shape-4 {
    right: 0;
}

/*====== generic-img-box ========*/
.generic-img-box {
    position: relative;
}

.generic-img-box img {
    position: absolute;
    width: 180px;
    height: 180px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 8px solid #fff;
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}

.generic-img-box img:nth-child(1) {
    top: -80px;
    right: 0;
}

.generic-img-box img:nth-child(2) {
    top: -20px;
    right: 210px;
    width: 200px;
    height: 200px;
}

.generic-img-box img:nth-child(3) {
    bottom: -60px;
    right: 30px;
}

@media (max-width: 1199px) {
    .generic-img-box img:nth-child(3) {
        right: 0;
    }
}

.generic-img-box img:nth-child(4) {
    bottom: -80px;
    left: 180px;
    width: 150px;
    height: 150px;
}

@media (max-width: 1199px) {
    .generic-img-box img:nth-child(4) {
        left: 0;
    }
}

/*====== generic-img-box-layout-2 ========*/
.generic-img-box-layout-2 img {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    border: 0;
}

.generic-img-box-layout-2 img:nth-child(1) {
    top: -73px;
    right: -10px;
    width: 205px;
    height: 205px;
}

.generic-img-box-layout-2 img:nth-child(2) {
    top: -200px;
    right: 280px;
    width: 150px;
    height: 150px;
}

.generic-img-box-layout-2 img:nth-child(3) {
    bottom: 160px;
    right: 80px;
    width: 100px;
    height: 100px;
    opacity: 0.5;
}

.generic-img-box-layout-2 img:nth-child(4) {
    bottom: 260px;
    right: 250px;
    left: auto;
    width: 110px;
    height: 110px;
}

.generic-img-box-layout-2 img:nth-child(5) {
    top: 0;
    left: 50px;
    opacity: 0.5;
}

.generic-img-box-layout-2 img:nth-child(6) {
    bottom: 200px;
    right: 530px;
    opacity: 0.5;
    width: 85px;
    height: 85px;
}

/*====== pattern-bg ========*/
.pattern-bg {
    position: relative;
    z-index: 1;
}

.pattern-bg::before, .pattern-bg::after {
    position: absolute;
    content: "";
    width: 120px;
    height: 105px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    pointer-events: none;
}

.pattern-bg::before {
    background-image: url("../imageshtml/line-shape.png");
    top: 20%;
    left: 10%;
    opacity: 0.1;
}

.pattern-bg::after {
    background-image: url("../imageshtml/dots.png");
    bottom: 10%;
    right: 15%;
    opacity: 0.4;
}

.pattern-bg-2 {
    position: relative;
}

.pattern-bg-2::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: -1;
    pointer-events: none;
    background-image: url("../imageshtml/pattern-bg.jpg");
    top: 0;
    left: 0;
    opacity: 0.1;
}

/*======== toggle-input ========*/
.toggle-input {
    width: 40px;
    height: 20px;
    position: relative;
    cursor: pointer;
}

.toggle-input::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2d86eb;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
}

.toggle-input::after {
    position: absolute;
    content: '';
    top: 9%;
    left: 9%;
    width: 40%;
    height: 80%;
    background-color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.toggle-input:checked::after {
    left: 50%;
}

/*======== hover-underline ========*/
.hover-underline:hover {
    text-decoration: underline;
}

.text-underline {
    text-decoration: underline;
}

.video-box {
    position: relative;
}

.video-box .video-content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*======== photo-gallery ========*/
.photo-gallery .row {
    margin-right: -5px;
    margin-left: -5px;
}

.photo-gallery .col-lg-12,
.photo-gallery .col-lg-6 {
    padding-left: 5px;
    padding-right: 5px;
}

/*======== gallery-item ========*/
.gallery-item {
    margin-bottom: 10px;
    display: block;
    overflow: hidden;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.gallery-item img {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: 100%;
}

.gallery-item:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}

/*======== generic-list-item ========*/
.generic-list-item li {
    margin-bottom: 10px;
    line-height: 22px;
    color: #6c727c;
}

.generic-list-item li a {
    color: #6c727c;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    display: block;
    position: relative;
}

.generic-list-item li a:hover {
    color: #2d86eb;
}

.generic-list-item.package-list li {
    padding-left: 25px;
    position: relative;
    margin-bottom: 20px;
}

.generic-list-item.package-list li:last-child {
    margin-bottom: 0;
}

.generic-list-item.package-list li i {
    position: absolute;
    top: 3px;
    left: 0;
}

/*======== generic-list-item-inline ========*/
.generic-list-item-inline li {
    display: inline-block;
    margin-right: 10px;
}

/*======== generic-list-item-white ========*/
.generic-list-item-white li {
    color: rgba(255, 255, 255, 0.5);
}

.generic-list-item-white li a {
    color: rgba(255, 255, 255, 0.5);
}

/*======== generic-list-item-hover-underline ========*/
.generic-list-item-hover-underline li a {
    display: inline-block;
}

.generic-list-item-hover-underline li a::before {
    position: absolute;
    content: "";
    bottom: -5px;
    right: 0;
    width: 0;
    height: 2px;
    background-color: #2d86eb;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.generic-list-item-hover-underline li a:hover {
    letter-spacing: .4px;
}

.generic-list-item-hover-underline li a:hover::before {
    width: 100%;
    right: auto;
    left: 0;
}

/*===== generic-list-item-highlight =====*/
.generic-list-item-highlight li {
    margin-bottom: 4px;
}

.generic-list-item-highlight li a {
    padding: 5px 14px;
}

.generic-list-item-highlight li.active a {
    background-color: rgba(128, 137, 150, 0.1);
    font-weight: 500;
    color: #0d233e;
    border-right: 3px solid #2d86eb;
}

/*====== generic-list-item-bullet ======*/
.generic-list-item-bullet li {
    padding-left: 15px;
    position: relative;
}

.generic-list-item-bullet li::before {
    position: absolute;
    top: 2px;
    left: 0;
    content: "\2022";
    padding: 0 3px;
    font-size: 18px;
}

/*====== generic-list-item--bullet ======*/
.generic-list-item--bullet li:first-child {
    padding-left: 0;
}

.generic-list-item--bullet li:first-child::before {
    display: none;
}

.generic-list-item--bullet li::before {
    font-size: 12px;
}

/*===== vertical-list =====*/
.vertical-list .item {
    padding: 12px 15px;
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
}

/*====== sticky-navs =======*/
.sticky-navs-wrap,
.js--scroll-nav {
    position: sticky;
    top: 0;
    z-index: 1024;
}

.terms-and-condition-area,
.privacy-area {
    z-index: 1024;
}

/*====== js-scroll-nav =======*/
.js-scroll-nav li {
    display: inline-block;
}

.js-scroll-nav li a {
    font-size: 15px;
    color: #6c727c;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    padding: 6px 16px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    display: block;
    line-height: 20px;
    font-weight: 500;
}

.js-scroll-nav li a:hover {
    color: #0d233e;
    background-color: rgba(128, 137, 150, 0.1);
}

.js-scroll-nav li.active a {
    color: #0d233e;
    background-color: rgba(128, 137, 150, 0.1);
}

/*===== js--scroll-nav =======*/
.js--scroll-nav li {
    display: block;
    margin-bottom: 4px;
}

.js--scroll-nav li a {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
}

/*====== post =======*/
.post .votes {
    width: 90px;
    padding: 12px 20px 12px 10px;
}

.post .vote-block {
    border: 1px solid rgba(128, 137, 150, 0.2);
    color: #6c727c;
    font-size: 12px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    text-align: center;
    padding: 2px 8px;
    line-height: 22px;
    font-weight: 500;
}

.post .answered-accepted .vote-block {
    background-color: #45A163;
    color: #fff;
    border-color: #45A163;
}

/*===== user-stats =====*/
.user-stats .stat {
    margin-left: 20px;
}

.user-stats .stat:first-child {
    margin-left: 0;
}

/*===== badge-top =====*/
.badge-top {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.badge-top-name {
    font-size: 14px;
    text-transform: uppercase;
    border-right: 1px solid transparent;
    padding: 15px 0;
    width: 90px;
    text-align: center;
    font-weight: 700;
}

.badge-top-gold .badge-top-name,
.badge-top-silver .badge-top-name,
.badge-top-bronze .badge-top-name {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.badge-top-gold {
    background-color: rgba(249, 184, 81, 0.1);
}

.badge-top-gold .badge-top-name {
    background-color: rgba(249, 184, 81, 0.2);
    border-color: rgba(249, 184, 81, 0.2);
    color: #f9b851;
}

.badge-top-silver {
    background-color: rgba(206, 206, 208, 0.1);
}

.badge-top-silver .badge-top-name {
    background-color: rgba(206, 206, 208, 0.2);
    border-color: rgba(206, 206, 208, 0.2);
    color: #ceced0;
}

.badge-top-bronze {
    background-color: rgba(175, 93, 24, 0.1);
}

.badge-top-bronze .badge-top-name {
    background-color: rgba(175, 93, 24, 0.2);
    border-color: rgba(175, 93, 24, 0.2);
    color: #af5d18;
}

/*===== votes =====*/
.votes {
    width: 100px;
    padding-right: 15px;
}

.votes .vote-counts {
    padding-right: 3px;
    flex-grow: 1;
    text-align: right;
}

.votes .vote-icon,
.votes .answer-icon {
    display: inline-block;
    width: 25px;
    vertical-align: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.votes .vote-icon {
    height: 12px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMyIDIyIj48cGF0aCBzdHJva2U9IiM3NzgwOEUiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTIgMjFsMTUtMjAgMTUgMjBoLTMweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
}

.votes .answer-icon {
    height: 19px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzOCI+PGcgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjNzc4MDhGIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0zMSAxNi4xNzhjMCAuNzg1LS41MDMgMy4zNDktMS4zMDQgNS43NjctMS4zMDQgMy45MjctNS44NyAxNS4wNTUtNS44NyAxNS4wNTVsLTMuMjYxLTYuNTQ1Yy0uODE1LjQxMi0yLjYwOS45MS00LjY5MS45MS04LjM0MSAwLTE1LjEyNy02LjgxNC0xNS4xMjctMTUuMTg2czYuNzg2LTE1LjE3OCAxNS4xMjctMTUuMTc4YzguMzQxIDAgMTUuMTI2IDYuODA3IDE1LjEyNiAxNS4xNzh6Ii8+PHRleHQgZmlsbD0iIzc3ODA4RiIgZm9udC1mYW1pbHk9IkhlbHZldGljYSBOZXVlIiBmb250LXNpemU9IjE2IiBmb250LXdlaWdodD0iNDIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48dHNwYW4geD0iOS4xNjQiIHk9IjIwLjcxMiI+QTwvdHNwYW4+PC90ZXh0PjwvZz48L3N2Zz4=);
}

.votes.answered-accepted .answer-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzOCI+PGcgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzRDOTA2NyIgc3Ryb2tlPSIjNEM5MDY3IiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0zMSAxNi4xNzhjMCAuNzg1LS41MDMgMy4zNDktMS4zMDQgNS43NjctMS4zMDQgMy45MjctNS44NyAxNS4wNTUtNS44NyAxNS4wNTVsLTMuMjYxLTYuNTQ1Yy0uODE1LjQxMi0yLjYwOS45MS00LjY5MS45MS04LjM0MSAwLTE1LjEyNy02LjgxNC0xNS4xMjctMTUuMTg2czYuNzg2LTE1LjE3OCAxNS4xMjctMTUuMTc4YzguMzQxIDAgMTUuMTI2IDYuODA3IDE1LjEyNiAxNS4xNzh6Ii8+PHRleHQgZmlsbD0iI2ZmZiIgZm9udC1mYW1pbHk9IkhlbHZldGljYSBOZXVlIiBmb250LXNpemU9IjE2IiBmb250LXdlaWdodD0iNDIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48dHNwYW4geD0iOSIgeT0iMjEiPkE8L3RzcGFuPjwvdGV4dD48L2c+PC9zdmc+);
}

/*===== votes-2 =====*/
.votes-2 {
    width: 86px;
    padding-right: 18px;
}

.answer-block {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px solid transparent;
}

.answer-block.answered {
    border-color: #45A163;
    color: #45A163;
}

.answer-block.answered-accepted {
    background-color: #45A163;
    color: #fff;
}

/*===== meta =====*/
.meta .author {
    color: #2d86eb;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.meta .author:hover {
    color: #0d233e;
}

/*====== tag-link =======*/
.tag-link {
    color: #6c727c;
    background-color: rgba(121, 127, 135, 0.1);
    margin-right: 2px;
    margin-bottom: 2px;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    padding: 3px 7px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    border: 1px solid rgba(121, 127, 135, 0.05);
}

.tag-link:hover {
    color: #0d233e;
    background-color: rgba(121, 127, 135, 0.2);
}

.tag-link.active {
    background-color: #2d86eb;
    border-color: #2d86eb;
    color: #fff;
    font-weight: 500;
}

/*====== tag-link-md =======*/
.tag-link-md {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 23px;
    padding: 2px 14px;
}

/*====== tag-link-blue =======*/
.tag-link-blue {
    background-color: rgba(45, 134, 235, 0.1);
    color: #2d86eb;
    border-color: rgba(45, 134, 235, 0.05);
}

.tag-link-blue:hover {
    background-color: rgba(45, 134, 235, 0.2);
    color: #2d86eb;
}

/*====== badge =======*/
.tag-stat {
    min-width: 40px;
    text-align: right;
}

/*====== badge =======*/
.badge {
    font-weight: 500;
    border: 1px solid transparent;
    position: relative;
}

.badge-md {
    font-size: 13px;
    line-height: 24px;
    padding: 2px 12px;
}

.badge-gray {
    background-color: rgba(128, 137, 150, 0.1);
    border-color: rgba(128, 137, 150, 0.1);
    color: #6c727c;
}

.badge-gray:hover {
    background-color: rgba(128, 137, 150, 0.2);
    color: #0d233e;
}

.badge-gold {
    background-color: rgba(249, 184, 81, 0.1);
    border-color: rgba(249, 184, 81, 0.1);
}

.badge-gold:hover {
    background-color: rgba(249, 184, 81, 0.2);
    color: #0d233e;
}

.badge-bronze {
    background-color: rgba(175, 93, 24, 0.1);
    border-color: rgba(175, 93, 24, 0.1);
}

.badge-bronze:hover {
    background-color: rgba(175, 93, 24, 0.2);
    color: #0d233e;
}

/*====== badge-stats =======*/
.badge-stats .badge .ball {
    position: absolute;
    left: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*====== ball =======*/
.ball {
    width: 6px;
    height: 6px;
    display: inline-block;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background-color: #af5d18;
    margin-right: 4px;
}

.ball-lg {
    width: 8px;
    height: 8px;
}

.gold {
    background-color: #f9b851;
}

.silver {
    background-color: #ceced0;
}

.red {
    background-color: #F4414F;
}

/*===== generic-pagination =====*/
.generic-pagination .page-link {
    border: 0;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    color: #6c727c;
    margin-left: 3px;
    font-weight: 500;
    font-size: 15px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.generic-pagination .page-link:hover {
    color: #2d86eb;
    background-color: transparent;
}

.generic-pagination .page-item:first-child .page-link {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.generic-pagination .page-item:last-child .page-link {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.generic-pagination .page-item.active .page-link {
    background-color: #2d86eb;
}

.generic-pagination .page-item.active .page-link:hover {
    color: #fff;
}

.generic--pagination .page-link {
    padding: 4px 8px;
    font-size: 14px;
}

/*===== qty =======*/
.quantity-item {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.qtyInput {
    width: 40px;
    border: 0;
    text-align: center;
    color: #0d233e;
    font-weight: 500;
    pointer-events: none;
    font-size: 17px;
}

.qtyBtn {
    line-height: 1;
    padding: 8px 10px;
    font-size: 17px;
    color: #0d233e;
    text-align: center;
    border: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: transparent;
}

.qtyBtn:hover {
    color: #2d86eb;
}

.qtyDec {
    border-right: 1px solid rgba(127, 136, 151, 0.1);
}

.qtyInc {
    border-left: 1px solid rgba(127, 136, 151, 0.1);
}

/*====== divider =======*/
.divider {
    background-color: rgba(127, 136, 151, 0.1);
    height: 1px;
}

.divider span {
    display: block;
    width: 50px;
    height: 1px;
    background-color: #2d86eb;
}

/*===== company-logo =====*/
.company-logo {
    position: relative;
    z-index: 2;
    width: 60px;
    height: 60px;
    background-color: #fff;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

.company-logo img {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    width: 100%;
}

/*===== company-details =====*/
.company-details strong {
    font-weight: normal;
    text-transform: uppercase;
    color: #6c727c;
    font-size: 11px;
    position: relative;
    display: block;
    margin-bottom: 15px;
}

.company-details strong span {
    background-color: #fff;
    display: inline-block;
    padding: 0 10px;
    position: relative;
    z-index: 2;
    line-height: 20px;
}

.company-details strong::before {
    content: '';
    border-top: 1px solid rgba(128, 137, 150, 0.1);
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
}

/*===== svg color =====*/
.svg-icon-color-white path {
    fill: #fff;
}

.svg-icon-color-gray path {
    fill: #6c727c;
}

.svg-icon-color-1 path {
    fill: #8C43FF;
}

.svg-icon-color-2 path {
    fill: #28d5a7;
}

.svg-icon-color-3 path {
    fill: #f9b851;
}

.svg-icon-color-4 path {
    fill: #CC08E9;
}

.svg-icon-color-5 path {
    fill: #2d86eb;
}

.svg-icon-color-6 path {
    fill: #307CF3;
}

.filter-gray {
    filter: grayscale(1);
}

/*===== upvotejs =====*/
div.upvotejs span.count {
    font-size: 17px !important;
}

div.upvotejs .upvote,
div.upvotejs .downvote {
    height: 22px;
    width: 22px;
    background-size: 23px;
}

div.upvotejs .upvote.upvote,
div.upvotejs .downvote.upvote {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMyIDIyIj48cGF0aCBzdHJva2U9IiM3NzgwOEUiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTIgMjFsMTUtMjAgMTUgMjBoLTMweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
    background-position: -1px 3px;
}

div.upvotejs .upvote.upvote.upvote-on,
div.upvotejs .downvote.upvote.upvote-on {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMyIDIyIj48cGF0aCBmaWxsPSIjNzc4MDhGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzc3ODA4RSIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMiAyMWwxNS0yMCAxNSAyMGgtMzB6Ii8+PC9zdmc+);
    background-position: -1px 3px;
}

div.upvotejs .upvote.downvote,
div.upvotejs .downvote.downvote {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMyIDIyIj48cGF0aCBzdHJva2U9IiM3NzgwOEUiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTIgMWwxNSAyMCAxNS0yMGgtMzB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
    background-position: -1px 3px;
}

div.upvotejs .upvote.downvote.downvote-on,
div.upvotejs .downvote.downvote.downvote-on {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMyIDIyIj48cGF0aCBmaWxsPSIjNzc4MDhGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzc3ODA4RSIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMiAxbDE1IDIwIDE1LTIwaC0zMHoiLz48L3N2Zz4=);
    background-position: -1px 3px;
}

div.upvotejs a.star {
    width: 30px;
    background-position: -5px -120px;
}

div.upvotejs a.star.star-on {
    background-position: -45px -120px;
}

div.upvotejs a.star.check {
    background-position: -5px -265px;
}

div.upvotejs a.star.check.star-on {
    background-position: -45px -265px;
}

/*======= custom-scrollbar-styled =======*/
.custom-scrollbar-styled::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}

.custom-scrollbar-styled::-webkit-scrollbar-track {
    background: transparent;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.custom-scrollbar-styled::-webkit-scrollbar-thumb {
    background: rgba(128, 137, 150, 0.2);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.custom-scrollbar-styled::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 137, 150, 0.4);
}

/*======= question-post-user-action =======*/
.question-post-user-action {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(128, 137, 150, 0.1);
    position: relative;
    z-index: 2;
    background-color: #fff;
}

.post-menu {
    background-color: #fff;
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
}

.post-menu .btn {
    font-size: 14px;
    color: #6c727c;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.post-menu .btn:hover {
    color: #2d86eb;
}

.post-menu .btn:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

/*======= question-highlight =======*/
.question-highlight {
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
    padding-bottom: 20px;
}

/*======= votes-styled =======*/
.votes-styled {
    background-color: rgba(128, 137, 150, 0.08);
    border-top: 1px solid rgba(128, 137, 150, 0.08);
    padding: 15px 13px;
}

/*======= question-post-body-wrap =======*/
.question-post-body-wrap,
.answer-body-wrap {
    padding: 15px 0 15px 15px;
    width: calc(100% - 60px);
}

@media (max-width: 375px) {
    .meta.text-right {
        text-align: left !important;
    }
}

/*======= question-post-body =======*/
.question-post-body p,
.answer-body p {
    margin-bottom: 15px;
    color: rgba(13, 35, 62, 0.8);
}

/*======= code-block =======*/
.code-block {
    background-color: #f6f6f6;
    padding: 12px 15px;
    overflow: auto;
    max-height: 300px;
    margin-bottom: 15px;
}

.code-block code {
    max-height: 300px;
}

.code-block-deleted {
    background-color: rgba(216, 39, 56, 0.04);
}

.code-block-edited {
    background-color: rgba(202, 232, 212, 0.3);
}

.code-string {
    color: #52750f;
}

.code-attr,
.code-keyword {
    color: teal;
}

.code-built-in,
.code-name {
    color: chocolate;
}

.diff-add {
    background-color: rgba(202, 232, 212, 0.8);
}

.diff-deleted {
    background-color: rgba(216, 39, 56, 0.1);
}

/*===== code =====*/
.code {
    background-color: rgba(128, 137, 150, 0.1);
    color: #6c727c;
    padding: 2px 6px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

/*===== subheader =====*/
.subheader {
    background-color: rgba(128, 137, 150, 0.1);
    border-top: 1px solid rgba(128, 137, 150, 0.1);
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
    padding: 10px 13px;
    position: relative;
}

/*===== tooltip =====*/
.tooltip-inner,
.popover {
    background-color: #fff;
    color: #0d233e;
    -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: #fff !important;
}

.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: #fff !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #fff !important;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: #fff !important;
}

/*=== popover ===*/
.popover {
    border-color: rgba(128, 137, 150, 0.1);
    max-width: 310px;
}

.popover .popover-body {
    padding: 15px;
}

.bs-popover-top > .arrow::before {
    border-top-color: rgba(128, 137, 150, 0.1);
}

.bs-popover-bottom > .arrow::before {
    border-bottom-color: rgba(128, 137, 150, 0.1);
}

/*===== jqte =====*/
.jqte {
    margin: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border-color: rgba(128, 137, 150, 0.2);
}

.jqte * {
    font-family: "Ubuntu", sans-serif !important;
}

.jqte ul,
.jqte dl,
.jqte ol {
    padding: 5px 5px 5px 16px;
}

.jqte ul {
    list-style: disc;
}

.jqte_toolbar {
    background-color: rgba(128, 137, 150, 0.1);
    border-bottom-color: rgba(128, 137, 150, 0.2);
}

.jqte_tool {
    padding: 3px;
}

.jqte_tool.jqte_tool_1 .jqte_tool_label {
    width: 80px;
    height: 24px;
    padding: 0;
    line-height: 24px;
}

.jqte_tool.jqte_tool_1 .jqte_tool_text {
    font-weight: 500;
}

.jqte_tool:hover {
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
}

.jqte_tool,
.jqte_tool_icon,
.jqte_tool_label {
    border-color: transparent !important;
}

.jqte_title {
    font-weight: 500;
}

.jqte_formats,
.jqte_fontsizes,
.jqte_cpalette,
.jqte_linktypes {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    border-color: rgba(128, 137, 150, 0.2);
}

.jqte_formats .jqte_format:hover,
.jqte_fontsizes .jqte_fontsize:hover,
.jqte_linktypes a:hover {
    background-color: rgba(128, 137, 150, 0.1);
}

.jqte_editor,
.jqte_source {
    min-height: 160px;
}

.jqte_placeholder {
    display: block;
}

.jqte_linktypeview {
    border-color: rgba(128, 137, 150, 0.4);
    padding: 4px 10px;
}

.jqte_linktypearrow {
    bottom: 13px;
    right: 13px;
}

.jqte_linkinput {
    border-color: rgba(128, 137, 150, 0.3);
    padding-top: 4px;
    padding-bottom: 4px;
    color: #0d233e;
}

.jqte_linkinput:focus, .jqte_linkinput:hover {
    border-color: rgba(128, 137, 150, 0.6);
}

.jqte_linkbutton {
    background-color: rgba(128, 137, 150, 0.1);
    border-color: rgba(128, 137, 150, 0.3);
    color: #0d233e;
    -webkit-box-shadow: inset 0 0;
    -moz-box-shadow: inset 0 0;
    box-shadow: inset 0 0;
    padding-top: 4px;
    padding-bottom: 4px;
}

.jqte_linkbutton:hover {
    background-color: rgba(128, 137, 150, 0.2);
}

/*=== profile-img ===*/
@media (max-width: 375px) {
    .edit-profile-photo {
        margin-bottom: 20px;
    }
}

/*=== profile-img ===*/
.profile-img {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 160px;
}

@media (max-width: 375px) {
    .profile-img {
        margin-bottom: 15px;
    }
}

/*=== social-icons ===*/
.social-icons li {
    display: inline-block;
}

.social-icons li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    background-color: #fff;
    display: block;
    color: #0d233e;
}

.social-icons li a:hover {
    color: #2d86eb;
}

.social-icons-sm li a {
    width: 27px;
    height: 27px;
    line-height: 27px;
}

/*====== copy-to-clipboard =======*/
.copy-to-clipboard {
    position: relative;
    z-index: 1;
}

/*====== text-success-message=======*/
.text-success-message {
    background-color: #45A163;
    color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 3px 6px;
    position: absolute;
    top: -10px;
    left: 50%;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    font-size: 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    font-weight: 500;
}

.text-success-message.active {
    top: -40px;
    opacity: 1;
    visibility: visible;
}

.text-success-message:after {
    position: absolute;
    content: "";
    bottom: -4px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    -moz-transform: translateX(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) rotate(45deg);
    -o-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    background-color: #45A163;
    width: 8px;
    height: 8px;
}

/*=== back-to-top ===*/
#back-to-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: #fff;
    color: #6c727c;
    font-size: 18px;
    z-index: 9999;
    width: 40px;
    height: 40px;
    line-height: 40px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
    -webkit-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.075);
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.075);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#back-to-top svg {
    fill: #0d233e;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#back-to-top:hover {
    background-color: #2d86eb;
    color: #fff;
}

#back-to-top:hover svg {
    fill: #fff;
}

.is-tilt {
    -webkit-animation: tilt 2s infinite alternate linear;
    -moz-animation: tilt 2s infinite alternate linear;
    animation: tilt 2s infinite alternate linear;
}

.is-scale {
    -webkit-animation: scale 2s infinite alternate linear;
    -moz-animation: scale 2s infinite alternate linear;
    animation: scale 2s infinite alternate linear;
}

.is-bounce {
    -webkit-animation: bounce 4s infinite alternate linear;
    -moz-animation: bounce 4s infinite alternate linear;
    animation: bounce 4s infinite alternate linear;
}

.is-swing {
    -webkit-animation: swing 4s 0.1s infinite alternate linear;
    -moz-animation: swing 4s 0.1s infinite alternate linear;
    animation: swing 4s 0.1s infinite alternate linear;
}

.is-spin {
    -webkit-animation: spin 2s infinite alternate linear;
    -moz-animation: spin 2s infinite alternate linear;
    animation: spin 2s infinite alternate linear;
}

.is-rotate {
    -webkit-animation: rotate 2s infinite linear;
    -moz-animation: rotate 2s infinite linear;
    animation: rotate 2s infinite linear;
}

/*====== owl-action-styled ======*/
.owl-action-styled .owl-nav {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 0;
    width: 100%;
    justify-content: space-between;
    display: flex;
}

.owl-action-styled .owl-nav [class*=owl-] {
    width: 60px;
    height: 60px;
    line-height: 60px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .owl-action-styled .owl-nav [class*=owl-] {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .owl-action-styled .owl-nav [class*=owl-] {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .owl-action-styled .owl-nav [class*=owl-] {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

.owl-action-styled .owl-nav [class*=owl-] svg {
    width: 30px;
    height: 30px;
    fill: #6c727c;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .owl-action-styled .owl-nav [class*=owl-] svg {
        width: 18px;
        height: 18px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .owl-action-styled .owl-nav [class*=owl-] svg {
        width: 18px;
        height: 18px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .owl-action-styled .owl-nav [class*=owl-] svg {
        width: 18px;
        height: 18px;
    }
}

.owl-action-styled .owl-nav [class*=owl-]:hover {
    background: #fff;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.owl-action-styled .owl-nav [class*=owl-]:hover svg {
    fill: #0d233e;
}

.owl-action-styled .owl-nav button.owl-prev, .owl-action-styled .owl-nav button.owl-next {
    background-color: #fff;
}

.owl-action-styled .owl-nav .owl-prev {
    margin-left: 50px;
}

.owl-action-styled .owl-nav .owl-next {
    margin-right: 50px;
}

/*====== owl--action-styled ======*/
.owl--action-styled .owl-nav {
    width: auto;
    margin-left: -460px;
    margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .owl--action-styled .owl-nav {
        margin-left: 0;
        margin-top: 0;
        position: inherit;
        top: auto;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        display: block;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .owl--action-styled .owl-nav {
        margin-left: 0;
        margin-top: 0;
        position: inherit;
        top: auto;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        display: block;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .owl--action-styled .owl-nav {
        margin-left: 0;
        margin-top: 0;
        position: inherit;
        top: auto;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        display: block;
    }
}

.owl--action-styled .owl-nav [class*=owl-] {
    width: 50px;
    height: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .owl--action-styled .owl-nav [class*=owl-] {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .owl--action-styled .owl-nav [class*=owl-] {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .owl--action-styled .owl-nav [class*=owl-] {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

.owl--action-styled .owl-nav .owl-prev {
    margin-left: 0;
}

.owl--action-styled .owl-nav .owl-next {
    margin-right: 0;
}

/*====== recruiting-carousel =======*/
.recruiting-carousel {
    margin-left: 450px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .recruiting-carousel {
        margin-left: 0;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .recruiting-carousel {
        margin-left: 0;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .recruiting-carousel {
        margin-left: 0;
    }
}

.recruiting-carousel .owl-stage {
    padding-top: 10px;
}

.recruiting-heading {
    position: absolute;
    top: 20px;
    left: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .recruiting-heading {
        position: inherit;
        top: auto;
        left: auto;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .recruiting-heading {
        position: inherit;
        top: auto;
        left: auto;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .recruiting-heading {
        position: inherit;
        top: auto;
        left: auto;
    }
}

.jobs-list .media {
    margin-bottom: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .jobs-list .media {
        flex-direction: column;
        text-align: center;
    }
}

.breadcrumb-list {
    font-size: 15px;
}

.breadcrumb-list li {
    display: inline-block;
}

.breadcrumb-list li span {
    position: relative;
    top: -1px;
}

.breadcrumb-list li a {
    color: #0d233e;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.breadcrumb-list li a:hover {
    text-decoration: underline;
}

.iti {
    display: block;
}

.iti__selected-flag {
    outline: none;
}

.svg-shape {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
}

/*===== filters ======*/
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .filters {
        flex-direction: column;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .filters {
        flex-direction: column;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .filters h3 {
        margin-right: auto;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .filters h3 {
        margin-right: auto;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .filter-option-box {
        width: 100% !important;
        margin-top: 10px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .filter-option-box {
        width: 100% !important;
        margin-top: 10px;
    }
}

/*===== blockquote-box ======*/
.blockquote-box {
    border-left: 7px solid rgba(128, 137, 150, 0.2);
    padding-left: 20px;
    position: relative;
}

.blockquote-box p {
    padding-right: 30px;
    font-style: italic;
    font-size: 16px;
}

.blockquote-box .la-quote-right {
    position: absolute;
    bottom: 10px;
    right: 5px;
    font-size: 50px;
    opacity: 0.2;
}

.img-box img:nth-child(2) {
    margin-left: -70px;
    margin-top: -70px;
    border: 10px solid #fff;
}

/*======= sidebar ========*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar.pt-45px {
        padding-top: 0 !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .sidebar.pt-45px {
        padding-top: 0 !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .sidebar.pt-45px {
        padding-top: 0 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar.pl-30px {
        padding-left: 0 !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .sidebar.pl-30px {
        padding-left: 0 !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .sidebar.pl-30px {
        padding-left: 0 !important;
    }
}

/*====== payment-tab =======*/
.payment-tab {
    background-color: #fff;
    max-height: 55px;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    width: 100%;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
}

.payment-tab.is-active {
    max-height: 400px;
}

.payment-tab.is-active .payment-tab-content {
    visibility: visible;
    opacity: 1;
}

/*====== payment-tab-content =======*/
.payment-tab-content {
    padding: 0 20px 20px 20px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

/*===== payment logo ======*/
.payment-logo {
    position: absolute;
    right: 20px;
    top: 21px;
}

/*===== payment-tab-toggle ======*/
.payment-tab-toggle {
    padding-right: 20px;
    padding-left: 20px;
}

.payment-tab-toggle > label {
    cursor: pointer;
    display: block;
    position: relative;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 30px;
    margin-bottom: 0;
    font-weight: 500;
    color: #0d233e;
}

.payment-tab-toggle > label:before {
    background-color: #fff;
    border: 2px solid #ceced0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    content: "";
    position: absolute;
    top: 18px;
    left: 0;
    width: 18px;
    height: 18px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.payment-tab-toggle > label:after {
    background-color: #ceced0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    content: "";
    position: absolute;
    left: 5px;
    top: 23px;
    width: 8px;
    height: 8px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}

.payment-tab-toggle > input {
    position: absolute;
    visibility: hidden;
}

.payment-tab-toggle > input:checked ~ label:before {
    border-color: #2d86eb;
}

.payment-tab-toggle > input:checked ~ label:after {
    background-color: #2d86eb;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

/*======= cart-totals ========*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cart-totals {
        width: 100% !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .cart-totals {
        width: 100% !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .cart-totals {
        width: 100% !important;
    }
}

/*======= Responsive column ========*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .responsive-column-half {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .responsive-column-half {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .responsive-column-half {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/*======== generic-tabs =======*/
.generic-tabs {
    border-bottom: 0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    background-color: #fff;
    padding-right: 20px;
    padding-left: 20px;
    position: relative;
}

.generic-tabs .nav-item {
    margin-bottom: 0;
    margin-right: 87px;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .generic-tabs .nav-item {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .generic-tabs .nav-item {
        margin-right: 20px;
    }
}

.generic-tabs .nav-item:first-child, .generic-tabs .nav-item:last-child {
    margin-right: 0;
}

.generic-tabs .nav-link {
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #6c727c;
    font-weight: 500;
    padding: 14px 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .generic-tabs .nav-link {
        border-bottom: 3px solid transparent;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .generic-tabs .nav-link {
        border-bottom: 3px solid transparent;
    }
}

.generic-tabs .nav-link.active {
    color: #2d86eb;
    border-bottom-color: #2d86eb;
}

/*======== generic--tabs =======*/
.generic--tabs {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0;
}

.generic--tabs .nav-item {
    margin-right: 40px;
}

/*======== generic--tabs-2 =======*/
.generic--tabs-2 .nav-item {
    margin-right: 40px;
}

.generic--tabs-2 .nav-item:first-child {
    margin-right: 40px;
}

.generic--tabs-2 .nav-link {
    position: relative;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .generic--tabs-2 .nav-link {
        border-bottom: 0;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .generic--tabs-2 .nav-link {
        border-bottom: 0;
    }
}

.generic--tabs-2 .nav-link::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #2d86eb;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}

.generic--tabs-2 .nav-link.active::after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

/*======== generic-tabs-layout-2 =======*/
.generic-tabs-layout-2 {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0;
}

.generic-tabs-layout-2 .nav-item {
    margin-right: 0;
}

.generic-tabs-layout-2 .nav-item:first-child .nav-link {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.generic-tabs-layout-2 .nav-item:last-child .nav-link {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.generic-tabs-layout-2 .nav-link {
    padding: 2px 12px;
    border: 1px solid rgba(128, 137, 150, 0.4);
    margin-left: -1px;
    font-size: 15px;
    font-weight: 400;
}

.generic-tabs-layout-2 .nav-link:hover {
    background-color: rgba(128, 137, 150, 0.06);
    border-color: rgba(128, 137, 150, 0.4);
}

.generic-tabs-layout-2 .nav-link.active {
    background-color: rgba(128, 137, 150, 0.2);
    color: #0d233e;
    border-color: rgba(128, 137, 150, 0.4);
}

/*======== generic--tabs-layout-2 =======*/
.generic--tabs-layout-2 .nav-item {
    margin-right: 10px;
}

.generic--tabs-layout-2 .nav-item:first-child {
    margin-right: 10px;
}

.generic--tabs-layout-2 .nav-item:first-child .nav-link {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.generic--tabs-layout-2 .nav-item:last-child .nav-link {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.generic--tabs-layout-2 .nav-link {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    border: 0;
}

.generic--tabs-layout-2 .nav-link:hover {
    background-color: rgba(128, 137, 150, 0.1);
}

.generic--tabs-layout-2 .nav-link.active {
    background-color: #2d86eb;
    color: #fff;
}

/*======== anim-bar =======*/
.anim-bar {
    display: inline-block;
    width: 30px;
    height: 3px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #2d86eb;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .anim-bar {
        display: none;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .anim-bar {
        display: none;
    }
}

.body-overlay {
    background: rgba(30, 30, 28, 0.9);
    left: 0;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    z-index: 1024;
}

.body-overlay.active {
    opacity: 1;
    visibility: visible;
}

.header-area {
    position: relative;
    z-index: 1025;
}

@media (max-width: 1199px) {
    .header-area {
        padding-top: 14px;
        padding-bottom: 14px;
    }
}

.header-area.fixed-top {
    position: fixed;
    -webkit-box-shadow: 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1);
    z-index: 1023;
}

@media (max-width: 1199px) {
    .header-area .col-lg-2 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/*====== logo-box ======*/
@media (max-width: 1199px) {
    .logo-box {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

/*====== menu-wrapper ======*/
.menu-wrapper {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

@media (max-width: 1199px) {
    .menu-wrapper {
        display: none;
    }
}

.menu-wrapper form {
    width: 300px;
}

/*===== menu-bar ======*/
.menu-bar > ul > li {
    display: inline-block;
    text-transform: capitalize;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
}

.menu-bar > ul > li.is-mega-menu {
    position: static;
}

.menu-bar > ul > li a {
    font-size: 15px;
    color: #6c727c;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    padding: 8px 16px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    display: block;
    line-height: 20px;
    font-weight: 500;
}

.menu-bar > ul > li a:hover {
    color: #2d86eb;
    background-color: rgba(45, 134, 235, 0.05);
}

.menu-bar > ul > li:last-child {
    margin-right: 0;
}

.menu-bar > ul > li .dropdown-menu-item {
    position: absolute;
    left: 0;
    top: 90px;
    width: 220px;
    background-color: #fff;
    padding: 15px 10px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    opacity: 0;
    visibility: hidden;
}

.menu-bar > ul > li .dropdown-menu-item li {
    margin-bottom: 2px;
    text-transform: capitalize;
}

.menu-bar > ul > li .dropdown-menu-item li:last-child {
    margin-bottom: 0;
}

.menu-bar > ul > li .dropdown-menu-item li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
}

.menu-bar > ul > li .dropdown-menu-item li .sub-menu {
    position: absolute;
    left: 95%;
    top: 0;
    width: 200px;
    background-color: #fff;
    padding: 15px 10px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    opacity: 0;
    visibility: hidden;
}

.menu-bar > ul > li .dropdown-menu-item li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    left: 100%;
}

.menu-bar > ul > li .mega-menu {
    width: 100%;
}

.menu-bar > ul > li .mega-menu li {
    border-right: 1px solid rgba(128, 137, 150, 0.1);
    margin-bottom: 0;
}

.menu-bar > ul > li .mega-menu li:last-child {
    border-right: 0;
}

.menu-bar > ul > li:hover .dropdown-menu-item {
    top: 80px;
    opacity: 1;
    visibility: visible;
}

/*===== menu--bar ======*/
.menu--bar > ul > li {
    padding-top: 14px;
    padding-bottom: 14px;
}

.menu--bar > ul > li .dropdown-menu-item {
    top: 70px;
}

.menu--bar > ul > li:hover .dropdown-menu-item {
    top: 60px;
}

/*===== menu-bar-white ======*/
.menu-bar-white > ul > li > a {
    color: rgba(255, 255, 255, 0.7);
}

.menu-bar-white > ul > li > a:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
}

/*======== nav-right-button ========*/
@media (max-width: 1199px) {
    .nav-right-button .btn {
        display: none;
    }
}

/*======== noti-dot ========*/
.noti-dot {
    position: absolute;
    right: 7px;
    top: 9px;
}

/*======== dropdown-toggle ========*/
.dropdown--toggle {
    color: #6c727c;
    font-size: 22px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.dropdown--toggle:hover {
    color: #2d86eb;
}

.dropdown--toggle::after {
    display: none;
}

/*======== dropdown--menu ========*/
.dropdown--menu {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    border: 0;
    width: 300px;
}

.dropdown--menu .dropdown-item {
    color: #6c727c;
    white-space: inherit;
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 14px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.dropdown--menu .dropdown-item .meta {
    color: #6c727c;
}

.dropdown--menu .dropdown-item .media-body h5 {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.dropdown--menu .dropdown-item:hover {
    background-color: transparent;
    color: #2d86eb;
}

.dropdown--menu .dropdown-item:hover .media-body h5 {
    color: #2d86eb;
}

.dropdown--menu::before {
    position: absolute;
    content: "";
    top: -8px;
    right: 20px;
    width: 14px;
    height: 14px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 1px solid rgba(128, 137, 150, 0.07);
    border-left: 1px solid rgba(128, 137, 150, 0.07);
}

.dropdown--menu-2 {
    border: 1px solid rgba(128, 137, 150, 0.05);
}

.dropdown--menu-2::before {
    right: auto;
    left: 30px;
}

/*======== user-dropdown ========*/
.user-dropdown .dropdown--menu {
    width: 190px;
}

.user-dropdown .dropdown--menu .dropdown-item {
    border-bottom: 0;
    padding-bottom: 4px;
    padding-top: 4px;
}

.user-dropdown .dropdown--menu .dropdown-item:hover {
    letter-spacing: 0.3px;
}

.user-dropdown .dropdown--menu::before {
    right: 50%;
    -webkit-transform: translateX(50%) rotate(45deg);
    -moz-transform: translateX(50%) rotate(45deg);
    -ms-transform: translateX(50%) rotate(45deg);
    -o-transform: translateX(50%) rotate(45deg);
    transform: translateX(50%) rotate(45deg);
}

/*====== off-canvas-menu-toggle =======*/
.search-menu-toggle,
.off-canvas-menu-toggle,
.user-off-canvas-menu-toggle {
    display: none;
    cursor: pointer;
}

@media (max-width: 1199px) {
    .search-menu-toggle,
    .off-canvas-menu-toggle,
    .user-off-canvas-menu-toggle {
        display: inline-block;
    }
}

.search-menu-toggle:hover,
.off-canvas-menu-toggle:hover,
.user-off-canvas-menu-toggle:hover {
    color: #2d86eb;
}

/*====== mobile-search-form ======*/
.mobile-search-form {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1030;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.mobile-search-form.active {
    opacity: 1;
    visibility: visible;
}

/*====== off-canvas-menu =======*/
.off-canvas-menu,
.user-off-canvas-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 100%;
    background-color: #fff;
    overflow-x: hidden;
    z-index: 1035;
    -webkit-transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
    -moz-transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
    -ms-transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
    -o-transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
    transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
}

.off-canvas-menu.active,
.user-off-canvas-menu.active {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

/*====== off-canvas-menu-list ========*/
.off-canvas-menu-list > li {
    margin-bottom: 0;
    font-size: 15px;
}

.off-canvas-menu-list > li > a {
    color: #0d233e;
    font-weight: 500;
    text-transform: capitalize;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 6px 20px;
}

.off-canvas-menu-list > li.active a {
    color: #2d86eb;
}

.off-canvas-menu-list > li .sub-menu {
    display: none;
    padding-left: 30px;
    text-transform: capitalize;
}

.off-canvas-menu-list > li .sub-menu li a {
    color: #6c727c;
}

.off-canvas-menu-list > li .sub-menu li a:hover {
    color: #2d86eb;
}

/*====== off-canvas-menu-close =======*/
.off-canvas-menu-close,
.user-off-canvas-menu-close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
}

.off-canvas-menu-close,
.user-off-canvas-menu-close,
.search-bar-close {
    cursor: pointer;
}

.off-canvas-menu-close .la,
.user-off-canvas-menu-close .la,
.search-bar-close .la {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.off-canvas-menu-close:hover .la,
.user-off-canvas-menu-close:hover .la,
.search-bar-close:hover .la {
    color: #2d86eb;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

/*====== sub-nav-toggler =======*/
.sub-nav-toggler {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #0d233e;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: none;
    outline: none;
    z-index: 2;
    background-color: #fff;
    -webkit-box-shadow: 0 0 0.35rem rgba(0, 0, 0, 0.085);
    -moz-box-shadow: 0 0 0.35rem rgba(0, 0, 0, 0.085);
    box-shadow: 0 0 0.35rem rgba(0, 0, 0, 0.085);
}

.sub-nav-toggler .la {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    color: #0d233e;
}

.sub-nav-toggler.active, .sub-nav-toggler:hover {
    color: #2d86eb;
}

.sub-nav-toggler.active .la, .sub-nav-toggler:hover .la {
    color: #2d86eb;
}

.sub-nav-toggler.active .la {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.hero-bg-1 {
    background-image: url(../imageshtml/hero-bg.jpg);
}

.hero-area {
    position: relative;
    z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-area .generic-img-box {
        display: none;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .hero-area .generic-img-box {
        display: none;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .hero-area .generic-img-box {
        display: none;
    }
}

.hero-list {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.hero-list-bg {
    background-image: url(../imageshtml/anonymousHeroBackground.svg);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-btn-box.text-right {
        text-align: left !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .hero-btn-box.text-right {
        text-align: left !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .hero-btn-box.text-right {
        text-align: left !important;
    }
}

/*====== search-form =======*/
.search-form {
    background-color: #fff;
    padding: 20px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    -moz-box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 10px rgba(82, 85, 90, 0.1);
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .search-form .form-group {
        margin-right: 0 !important;
    }
}

.search-form .km-select-wrap {
    position: absolute;
    right: 0;
    top: 50%;
    width: 140px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .search-form .km-select-wrap {
        display: none;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .search-form .km-select-wrap {
        display: none;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .search-form .km-select-wrap {
        display: none;
    }
}

.search-form .km-select-wrap .custom-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/*===== hero-content =====*/
.hero-content {
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content .section-title {
        font-size: 30px !important;
        line-height: 40px !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .hero-content .section-title {
        font-size: 30px !important;
        line-height: 40px !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .hero-content .section-title {
        font-size: 30px !important;
        line-height: 40px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content .section-title.fs-28 {
        font-size: 28px !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .hero-content .section-title.fs-28 {
        font-size: 28px !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .hero-content .section-title.fs-28 {
        font-size: 28px !important;
    }
}

.hero-content .svg-icon {
    position: absolute;
    top: 20px;
    right: 0;
    height: 140px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content .svg-icon {
        display: none;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .hero-content .svg-icon {
        display: none;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .hero-content .svg-icon {
        display: none;
    }
}

/*==== search-btn-box ====*/
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .search-btn-box {
        flex-grow: 1;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .search-btn-box {
        flex-grow: 1;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .search-btn-box .btn {
        width: 100%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .search-btn-box .btn {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-item {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .footer-item {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .footer-item {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .copyright-wrap {
        text-align: center;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .copyright-wrap {
        text-align: center;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .copyright-wrap {
        text-align: center;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .copyright-wrap .generic-list-item {
        text-align: center !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .copyright-wrap .generic-list-item {
        text-align: center !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .copyright-wrap .generic-list-item {
        text-align: center !important;
    }
}

.copyright-desc {
    color: rgba(255, 255, 255, 0.5);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .copyright-desc {
        text-align: center !important;
        padding-top: 10px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .copyright-desc {
        text-align: center !important;
        padding-top: 10px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .copyright-desc {
        text-align: center !important;
        padding-top: 10px;
    }
}

.copyright-desc a {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    color: #fff;
}

.copyright-desc a:hover {
    color: #2d86eb;
}

.copyright--desc {
    color: #6c727c;
}

.copyright--desc a {
    color: #6c727c;
}

