

        ::selection {
            color: #fff;
            background: #01a0fc
        }
        
        a,
        .btn-link {
            color: #01a0fc
        }
        
        .badge-default {
            background: #747879;
            color: #F7F7F7
        }
        
        .loader {
            color: #01a0fc
        }
        
        .badge-primary {
            background-color: #01a0fc
        }
        
        .btn-primary {
            background: #01a0fc;
            color: #fff;
            border-color: #01a0fc
        }
        
        .btn-info {
            background-color: #01a0fc;
            border-color: #01a0fc
        }
        
        .nav-tabs .nav-link.active {
            border-color: #01a0fc;
            color: #01a0fc
        }
        
        .form-control:focus {
            box-shadow: 0 0 0 0.2rem rgba(26, 80, 137, 0.25)
        }
        
        .card,
        .dropify-wrapper,
        .btn-primary {
            border: 0
        }
        
        .bg-primary,
        .bg-info,
        .bg-warning,
        .btn-success {
            background-color: #01a0fc !important;
            border: transparent
        }
        
        .bg-success,
        .bg-danger,
        .badge-success,
        .progress-bar,
        .btn-danger,
        .btn-warning {
            background-color: #01a0fc;
            border: transparent
        }
        
        .btn-outline-primary {
            color: #01a0fc;
            border-color: #01a0fc
        }
        
        .btn-outline-primary:hover {
            background-color: #01a0fc;
            border-color: #01a0fc
        }
        
        .custom-control-input:checked~.custom-control-label::before,
        .custom-radio .custom-control-input:disabled:checked~.custom-control-label::before,
        .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
            border-color: #333;
            background-color: #333
        }
        
        .custom-switch-input:checked~.custom-switch-indicator {
            background: #01a0fc
        }
        
        .selectgroup-input:checked+.selectgroup-button {
            border-color: #fff;
            color: #fff;
            background: rgba(42, 44, 53, 0.8)
        }
        
        .selectgroup-button {
            border: #fff;
            background: #f3f3f3;
        }
        
        .imagecheck-figure:before {
            color: #333 !important
        }
        
        .wizard>.steps .current a,
        .wizard>.steps .current a:hover,
        .wizard>.steps .current a:active,
        .wizard>.actions a,
        .wizard>.actions a:hover {
            background: #01a0fc
        }
        
        .wizard>.steps .done a,
        .wizard>.steps .done a:hover {
            background: rgba(26, 80, 137, 0.2);
            color: #01a0fc
        }
        
        .list-group-item,
        .modal-content {
            background-color: #fff
        }
        
        .table th {
            color: #747879
        }
        
        .table.table-custom td,
        .table.table-custom th {
            background: #F7F7F7
        }
        
        .table.table_custom tr {
            background: #fff
        }
        
        .right_chat li.online .status {
            background: #01a0fc
        }
        
        .bg-blue,
        .bg-azure,
        .bg-indigo,
        .bg-purple,
        .bg-red,
        .bg-orange {
            background-color: #01a0fc !important
        }
        
        .bg-pink,
        .bg-yellow,
        .bg-lime,
        .bg-green,
        .bg-teal,
        .bg-cyan {
            background-color: #01a0fc !important
        }
        
        .bg-light-blue,
        .bg-light-azure,
        .bg-light-indigo,
        .bg-light-lime,
        .bg-light-green,
        .bg-light-teal {
            background-color: rgba(26, 80, 137, 0.1);
            color: #333
        }
        
        .bg-light-pink,
        .bg-light-red,
        .bg-light-orange,
        .bg-light-yellow,
        .bg-light-cyan {
            background-color: rgba(226, 30, 50, 0.1);
            color: #333
        }
        
        .bg-light-purple {
            background-color: rgba(226, 30, 50, 0.1);
            color: #01a0fc
        }
        
        .bg-light-gray {
            background-color: rgba(102, 106, 109, 0.1);
            color: #666A6D
        }
        
        .avatar.avatar-blue {
            background-color: rgba(26, 80, 137, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-azure {
            background-color: rgba(26, 80, 137, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-indigo {
            background-color: rgba(26, 80, 137, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-purple {
            background-color: rgba(26, 80, 137, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-pink {
            background-color: rgba(26, 80, 137, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-red {
            background-color: rgba(26, 80, 137, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-orange {
            background-color: rgba(26, 80, 137, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-yellow {
            background-color: rgba(226, 30, 50, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-lime {
            background-color: rgba(226, 30, 50, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-green {
            background-color: rgba(226, 30, 50, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-teal {
            background-color: rgba(226, 30, 50, 0.1);
            color: #01a0fc
        }
        
        .avatar.avatar-cyan {
            background-color: rgba(226, 30, 50, 0.1);
            color: #01a0fc
        }
        
        body {
            background-color: #F7F7F7;
            color: #202121
        }
        
        body::before {
            background: #01a0fc;
        }
        
        .page-header {
            border-color: #F7F7F7;
        }
        
        .page-header .right .nav-pills .nav-link {
            color: #202121
        }
        
        #header_top .brand-logo {
            background: #01a0fc;
            width: 35px
        }
        
        .header {
            background: #01a0fc
        }
        
        .header .nav-tabs .nav-link {
            color: #fff
        }
        
        .header .nav-tabs .nav-link.active {
            border-color: #fff
        }
        
        .header .dropdown-menu .dropdown-item:hover,
        .header .dropdown-menu .dropdown-item.active {
            color: #01a0fc
        }
        
        .page .section-white,
        .page .section-body {
            background: #F7F7F7
        }
        
        #header_top .nav-link {
            color: #202121
        }
        
        .header_top.dark {
            background-color: #3B3D55 !important
        }
        
        .header_top.dark .nav-link {
            color: #F7F7F7 !important
        }
        
        .card {
            background-color: #fff
        }
        
        .card .card-options a {
            color: #2A2C35
        }
        
        .card .card-options a.btn {
            color: #fff
        }
        
        .card.card-fullscreen {
            background-color: #F7F7F7 !important
        }
        
        .card:hover {
            background: #fff;
            box-shadow: 0 3px 7px rgba(41, 43, 48, 0.1)
        }
        
        .card.bg-none:hover {
            box-shadow: none
        }
        
        .metismenu a {
            color: #202121
        }
        
        .metismenu a:hover {
            color: #2A2C35;
            border-color: #2A2C35
        }
        
        .metismenu .active>a {
            color: #2A2C35;
            border-color: #2A2C35
        }
        
        .metismenu .active ul .active a {
            color: #2A2C35;
            background: transparent
        }
        
        .metismenu.grid>li.active>a,
        .metismenu.grid>li>a:hover {
            background: rgba(42, 44, 53, 0.1);
            color: #2A2C35
        }
        
        .inbox .detail {
            background: #fff
        }
        
        .file_folder a {
            background: #fff;
            border-color: #F7F7F7
        }
        
        .gender_overview {
            background: #F7F7F7
        }
        
        .table-calendar-link {
            background: #01a0fc
        }
        
        .table-calendar-link:hover {
            background: #3B3D55
        }
        
        .table-calendar-link::before {
            background: #3B3D55
        }
        
        .theme_div {
            background: #fff
        }
        
        .ribbon .ribbon-box.green {
            background: #01a0fc
        }
        
        .ribbon .ribbon-box.green::before {
            border-color: #01a0fc;
            border-right-color: transparent
        }
        
        .ribbon .ribbon-box.orange {
            background: #01a0fc
        }
        
        .ribbon .ribbon-box.orange::before {
            border-color: #01a0fc;
            border-right-color: transparent
        }
        
        .ribbon .ribbon-box.pink {
            background: #01a0fc
        }
        
        .ribbon .ribbon-box.pink::before {
            border-color: #01a0fc;
            border-right-color: transparent
        }
        
        .ribbon .ribbon-box.indigo {
            background: #01a0fc
        }
        
        .ribbon .ribbon-box.indigo::before {
            border-color: #01a0fc;
            border-right-color: transparent
        }
        
        .text-orange {
            color: #01a0fc !important
        }
        
        .tag-info {
            background: #01a0fc
        }
        
        .tag-success,
        .tag-green {
            background: #01a0fc
        }
        
        .tag-orange {
            background: #01a0fc
        }
        
        .tag-danger {
            background: #01a0fc
        }
        
        .bg-success {
            background: #01a0fc !important
        }
        
        .bg-danger {
            background-color: #01a0fc !important
        }
        
        .auth .auth_left {
            background: white
        }
        
        .auth .auth_left .card {
            padding: 0;
            right: -150px;
            /* z-index: 99999 */
        }
        
        .auth .auth_right img {
            width: 500px;
        }
        
        .auth .auth_left {
        
            display: flex;
            align-items: center;
            min-height: 100vh;
            width: 450px;
            padding: 0 20px;
        }
        
        .auth .auth_right {
            width: calc(100% - 450px);
            text-align: center;
        }
        
@media only screen and (min-width: 768px) {
    .auth .auth_left {
        padding: 80px 0;
    }
}
        @media only screen and (max-width: 1024px) {
            .auth .auth_left .card {
                right: -50px
            }
        }
        
        @media only screen and (max-width: 992px) {
            .auth .auth_left .card {
                right: auto
            }
        }
        
        .page-item .page-link {
            color: #01a0fc
        }
        
        .page-item.active .page-link {
            background-color: #01a0fc;
            border-color: #01a0fc
        }
        
        #left-sidebar {
            background-color: #fff
        }
        
        .dark-mode .btn {
            color: #417082
        }
        
        .dark-mode .metismenu.grid>li>a {
            border-color: rgba(255, 255, 255, 0.2)
        }
        
        .dark-mode.sidebar_dark .sidebar .metismenu .active>a {
            color: #01a0fc
        }
        
        .dark-mode.sidebar_dark .sidebar .metismenu .active ul .active a {
            color: #01a0fc
        }
        
        .dark-mode.sidebar_dark .sidebar .metismenu a {
            color: #fff
        }
        
        .dark-mode .nav-tabs .nav-link.active {
            color: #01a0fc
        }
        
        .top_dark {
            background-color: #3B3D55 !important
        }
        
        .sidebar_dark #left-sidebar {
            background-color: #3B3D55 !important
        }
        
        .sidebar_dark #header_top .nav-link {
            color: #F7F7F7 !important
        }
        
        .sidebar_dark .sidebar .nav-tabs {
            border-color: rgba(255, 255, 255, 0.1)
        }
        
        .sidebar_dark .sidebar .nav-tabs .nav-link.active {
            border-color: #fff;
            color: #fff
        }
        
        .sidebar_dark .sidebar .metismenu .active>a {
            color: #01a0fc
        }
        
        .sidebar_dark .sidebar .metismenu .active ul .active a {
            color: #01a0fc
        }
        
        .iconcolor #header_top .nav-link {
            color: #01a0fc !important
        }
        
        .gradient .custom-switch-input:checked~.custom-switch-indicator {
            background: linear-gradient(45deg, #01a0fc, #01a0fc) !important
        }
        
        .gradient .metismenu.grid>li.active>a,
        .gradient .metismenu.grid>li>a:hover {
            background: linear-gradient(45deg, #01a0fc, #01a0fc) !important;
            color: #fff;
            border: 0
        }
        
        .gradient .btn-primary {
            background: linear-gradient(45deg, #01a0fc, #01a0fc) !important;
            border: 0
        }
        
        .gradient .btn-dark {
            background: linear-gradient(45deg, #808488, #333537) !important
        }
        
        .gradient .bg-success,
        .gradient .bg-danger,
        .gradient .badge-success,
        .gradient .progress-bar,
        .gradient .btn-danger {
            background: linear-gradient(45deg, #01a0fc, #01a0fc) !important
        }
        
        .gradient.sidebar_dark #left-sidebar {
            background: linear-gradient(45deg, #01a0fc, #01a0fc) !important
        }
        
        @media only screen and (min-width: 1200px) {
            .header_top>.container {
                border-right: 1px solid rgba(255, 255, 255, 0.07)
            }
        }
        
        /*ì¶”ê°€í•˜ëŠ”ë¶€ë¶„*/
        @import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
        
        .custom-control {
            font-size: 12px;
            color: #999;
            line-height: 2;
        }
        
        .auth .card {
            width: 100%;
            max-width: 450px;
        
            border: 0;
            margin: 0 auto;
        }
        
        .form-footer {
            margin-top: 1rem;
        }
        
        .card-title {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 5px;
        }
        
        .card-sub {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
            margin-bottom: 15px;
        }
        
        body {
            font-family: 'Noto Sans KR', sans-serif !important;
        }
        
        .card-top {
            background: #fff;
            /* background-image: url('../../../../public/images/img-login-ch.png'); */
            background-repeat: no-repeat;
            background-position: right;
            background-size: contain;
            height: 180px;
            padding: 20px;
            margin-bottom: 40px;
            border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
        }
        
        .logo_profile {
            border-radius: 70px;
            display: block;
            background-color: #fff;
            width: 70px;
            position: relative;
            margin-bottom: -55px;
            box-shadow: 3px 3px 5px rgb(0 0 0 / 20%);
        }
        
        .logo_profile .Phonebutton {
            border: 1px solid #ccc;
            border-radius: 50px;
            background: #fff;
            font-size: 16px;
            color: #aaa;
            position: absolute;
            height: 30px;
            width: 30px;
            text-align: center;
            line-height: 30px;
            right: 0;
            cursor: pointer;
            bottom: 0;
            margin-bottom: 0;
            margin-right: -5px;
        }
        
        .logo_profile .Phonebutton:focus {
            outline: none;
        }
        
        .logo_profile img {
            width: 70px !important;
            height: 70px !important;
            border-radius: 70px;
        }
        
        .Copyright {
            color: #999;
            font-size: 12px;
        }
        
        .registerGuide {
            font-size: 12px;
            color: #999;
        }
        
        .CountNum {
            font-size: 14px;
            color: #999;
            position: absolute;
            right: 64px;
            top: 6px;
        }
        
        .fs12 {
            font-size: 12px;
        }
        
        .btn-round-lg {
            border-radius: 22.5px;
        }
        
        .btn-round {
            border-radius: 17px;
        }
        
        .btn-round-sm {
            border-radius: 15px;
        }
        
        .btn-round-xs {
            border-radius: 11px;
            padding-left: 10px;
            padding-right: 10px;
        }
        
        .table td,
        .table th {
            vertical-align: middle !important;
            border-bottom: 1px solid #dee2e6 !important;
            border-top: none;
        }
        
        .btn-outline-default {
            color: #6c757d;
            border-color: #ccc;
        }
        
        .btn-outline-default:hover {
            color: #fff;
            ;
            background-color: #ccc;
            border-color: #ccc;
        }
        
        .page-title {
            font-size: 16px !important;
            font-weight: 600;
        }
        
        .brand-name {
            font-size: 16px !important;
            color: #01a0fc;
            font-weight: 600;
        }
        
        
        .carousel-control-next {
            width: auto;
        }
        
        .carousel-control-prev {
            width: auto;
        }
        
        .carousel-control-prev-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
        }
        
        .carousel-control-next-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
        }
        
        .card {
            word-break: keep-all;
        }
        
        #header_top .active i {
            color: #01a0fc;
        }
        
        .metismenu {
            display: none;
        }
        
        .view {
            display: block;
        }
        
        .table_tit {
            font-size: 14px;
            margin-top: 10px;
            margin-bottom: 15px;
            border-bottom: 3px solid #ddd;
            display: inline-block;
            line-height: 32px;
        }
        
        .width174 {
            width: 174px;
        }
        
        .listimg {
            width: 100%;
            max-width: 150px;
        }
        
        .listTxtTit {
            font-size: 14px;
            color: #333;
            position: relative;
            font-weight: 800;
        }
        
        .listTxtTit span {
            position: absolute;
            right: 150px;
            color: #999;
            font-weight: 400;
        }
        
        .listTxtTit span i {
            font-size: 16px;
            vertical-align: middle;
        }
        
        /* .listInfo01,
        .listInfo02 {
            display: inline-block;
            margin-top: 5px;
            color: #545454;
        }
        
        .listInfo01 {
            margin-right: 20%;
        }
        
        .listInfo01 p {
            margin-bottom: 0;
            margin-top: 5px;
        }
        
        .listInfo02 p {
            margin-bottom: 0;
            margin-top: 5px;
        }
        
        .listInfo01 p i {
            font-size: 16px;
            vertical-align: middle;
        }
        
        .listInfo02 p i {
            font-size: 16px;
            vertical-align: middle;
        } */
        
        .userImg {
            margin-bottom: 5px;
            font-size: 14px;
            color: #333;
        }
        
        .userImg img {
            vertical-align: middle;
        }
        
        .userInfo {
            color: #999;
            margin-top: 10px;
        }
        
        .userInfo span {
            margin-right: 10px;
        }
        
        .mobileMinWidth {
            min-width: 300px;
            padding-right: 50px;
        }
        
        .carouselItemArea {
            border-radius: 10px;
            position: relative;
            width: 100%;
        }
        
        .carouselItemArea img {
            height: 90px;
            border-radius: 10px;
        }
        
        .carouselItemArea .carousel-item-background {
            background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
            border-radius: 10px;
        }
        
        .carouselItemArea .carousel-caption {
            bottom: 0;
            padding-bottom: 5px;
        }
        
        .carouselItemArea h3 {
            font-size: 14px;
            color: #fff;
        }
        
        .width500 {
            width: 100%;
            max-width: 500px;
        }
        
        .width700 {
            width: 100%;
            max-width: 700px;
        }
        
        .quickMenuArea {
            width: 100%;
            max-width: 500px;
        }
        
        .quickMenuArea .my_sort_cut {
            padding-top: 5px;
        }
        
        .height100 {
            height: 100px;
        }
        
        .width700 .custom-switch {
            cursor: pointer;
        }
        
        .width700 .form-group .input-icon-addon {
            font-size: 18px;
            cursor: pointer;
            pointer-events: all;
        }
        
        .mainFlex {
            display: -ms-flexbox !important;
            display: flex !important;
        }
        
        .dashboard500 {
            width: 100%;
            max-width: 500px;
            margin-right: 20px;
        }
        
        .graphArea {
            width: 100%;
            max-width: 500px;
        }
        
        .graphArea h6 {
            line-height: 36px;
        }
        
        .rollingNumArea {
            margin-top: -45px;
        }
        
        .profileImgArea {
            border-top-left-radius: 0.1875rem;
            border-top-right-radius: 0.1875rem;
            width: 100%;
            height: 120px;
            display: flex;
            align-items: center;
            background-size: 100% !important;
        }
        
        .middleMargin {
            width: 30px;
            text-align: center;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            line-height: 28px;
            background: #f3f3f3;
            color: #ccc;
        }
        
        
        .profileImgArea .logo_profile {
            border-radius: 70px;
            display: block;
            background-color: #fff;
            width: 70px;
            position: relative;
            margin: 0 auto;
            margin-bottom: 0;
            box-shadow: 3px 3px 5px rgb(0 0 0 / 20%);
        }
        
        /* ë¼ì´íŠ¸ ìŠ¬ë¼ì´ë“œ ì˜ì—­ */
        #right-sidebar {
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            width: 400px;
            background-color: #fff;
            border-left: 1px solid #E6E9ED;
            border-top: 1px solid #E6E9ED;
            border-bottom: 1px solid #E6E9ED;
            margin-top: 80px;
            margin-right: -400px;
            padding: 15px 15px 20px 15px;
            height: 80vh;
            right: 0;
            overflow: hidden;
            position: fixed;
            top: 0;
            z-index: 99;
        }
        .chat-messenger-bottom-area{
            border-radius: 11px;
            margin-top: 3%;
            border: 1px solid rgb(230 222 222);
            opacity: 0.8;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
        #chat-sidebar {
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            width: 400px;
            background-color: #fff;
            border-left: 1px solid #E6E9ED;
            border-top: 1px solid #E6E9ED;
            border-bottom: 1px solid #E6E9ED;
            margin-bottom: 0px;
            margin-right: -400px;
            padding: 15px 15px 20px 15px;
            /* height: 60vh; */
            min-height:547px;
            max-height:65vh;
            /* bottom: 20px; */
            right: 0;
            overflow-y: scroll;
            /* position: fixed; */
            position:absolute;
            top: 92.8px;
            z-index: 99;
        }
        
        @media only screen and (max-width: 992px) {
            #chat-sidebar {
                top:82.8px;
            }
        }
        .chat-content-body {
            background-color: #E6F5F6;
        }
        #right-sidebar {
            background-color: #fff;
        }
        
        #chat-sidebar {
            /* background-color: #E6F5F6; */
            background-color: #fff;
        }
        
        .rightView {
            margin-right: 0 !important;
        }
        .bottomView {
            margin-right: 30px !important;
            border-radius: 10px;
        }
        #mainContents {
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
        }
        
        .sectionRight {
            margin-right: 410px;
        }
        
        .avatar {
            line-height: 0;
        }
        
        /* ê¸€ë‚´ìš©ë³´ê¸° ì˜ì—­ */
        #CateArea,
        #AreaArea,
        #PhotoArea {
            display: none;
        }
        
        .viewCon {
            display: block !important;
        }
        
        .viewCon h5 {
            font-size: 14px;
            font-weight: 600;
            color: #333;
        }
        
        .viewCon ul {
            margin: 0;
            padding: 0;
        }
        
        .viewCon ul li {
            list-style: none;
            margin-bottom: 10px;
            padding-left: 5px;
        }
        
        .viewCon .ReaultArea {
            margin-top: 20px;
            padding: 0;
            height: 56vh;
            overflow-y: scroll;
        }
        
        .viewCon .textArea {
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 12px;
            color: #545454;
        }
        
        .mainNoticeArea {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px !important;
            height: 126px;
            overflow-y: auto;
        }
        
        .viewContents {
            overflow-y: scroll;
            /* height: 64vh; */
            position: relative;
        }
        
        .viewContents h5 {
            font-size: 14px;
            font-weight: 600;
            color: #333;
        }
        
        .viewContents .textArea {
            margin-bottom: 10px;
            margin-top: 3px;
            color: #545454;
            line-height:24px;
        }
        
        .viewContents .photoArea {
            margin-bottom: 10px;
            width: 100%;
        }
        .viewContents .photoArea img{
            object-fit: cover;
        }
        .viewContents .photoArea img {
            width: 100%;
        }
        
        .viewContents .mvArea {
            margin-bottom: 10px;
            width: 100%;
        }
        
        .viewContents .mvArea video {
            width: 100%;
        }
        
        .viewContents .cateArea {
            margin-bottom: 0;
            line-height:26px;
        }
        
        .viewContents .avatar {
            position: absolute;
            left: 0;
            margin-top: 6px;
            overflow: hidden;
            font-size: 14px;
            color: #333;
        }
        
        .viewContents .avatar img {
            vertical-align: top;
        }
        
        .viewContents .userInfo {
            color: #545454;
            margin-top: 0px;
            padding-left: 50px;
        }
        .viewContents .userInfo p{
            line-height:24px;
        }
        .viewContents .userInfo p.list {
            /* margin-top: 2px; */
        }
        
        .viewContents .userInfo p.list span:first-child{
            position:relative;
            padding-right:10px;
        }
        
        .viewContents .userInfo p.list span:first-child:after{
            content:'';
            display:inline-block;
            position:absolute;
            top:50%;
            right:0;
            margin-top:-5px;
            width:1px;
            height:10px;
            background:#ccc;
        }
        
        .viewContents .userInfo p.list i{
            margin-right:3px;
        }
        
        .viewContents .userInfo p.list i.la-user{
            font-size:17px;
        }
        
        .noResult {
            font-size: 14px;
            text-align: center;
            margin: 50px 0;
        }
        
        .user_btn {
            margin-top: 20px;
        }
        
        #left-sidebar>.brand_name .brand-name {
            margin-top: 24px;
        }
        
        .page-title {
            margin-top: 28px;
        }
        
        .media-foot {
            padding: 36px 30px;
            /* margin: -20px; */
            border-left: 1px dashed #ddd;
        }
        
        
        /* ì±„íŒ…ê´€ë ¨ css */
        .chat_app {
            height: calc(100vh - 110px);
        }
        
        .chat_app .chat_list {
            height: calc(100vh - 110px);
        }
        
        .page .section-light {
            background: #fff;
        }
        
        .chat_app .chat_list {
            top: 82px;
            margin-left: 300px;
        }
        
        .chat_app .chat_list .right_chat {
            height: calc(100vh - 254px);
        }
        
        .chat_app .chat_windows ul {
            height: calc(100vh - 320px);
        }
        
        .card-options .time {
            font-size: 14px;
            line-height: 28px;
            color: #545454;
        }
        
        .card-options i {
            font-size: 16px;
            color: #545454;
        }
        
        .bgChatText {
            background: #fff !important;
            border: 1px solid #fff;
        }
        
        .chat_app .chat_windows .message .time {
            font-size: 11px;
            color: #aaa;
        }
        
        .right_chat li {
            margin-bottom: 0;
        }
        
        .carouselItemArea {
            cursor: pointer;
        }
        
        .addBoard .carousel-item-background {
            box-shadow: inset 0 0 0 4px #01a0fc;
        }
        
        .width220 {
            width: 220px;
        }
        
        .nav-tabs .nav-link {
            padding: .5rem 0;
        }
        
        .CounterArea h6 {
            font-size: 14px;
        }
        
        .CounterArea .text-danger {
            color: #01a0fc;
        }
        
        .imgNoProfile {
            width: 100%;
            max-width: 600px;
        }
        
        /* ì±—ë´‡ ì„¤ì • */
        
        .grid {
            list-style: none;
            margin-left: -40px;
        }
        
        .gc {
            box-sizing: border-box;
            display: inline-block;
            margin-right: -.25em;
            min-height: 1px;
            padding-left: 40px;
            vertical-align: top;
        }
        
        .gc--1-of-3 {
            width: 33.33333%;
        }
        
        .gc--1-of-2 {
            width: 25%;
        }
        
        .gc--2-of-4 {
            width: 75%;
        }
        
        .gc--2-of-3 {
            width: 66.66666%;
        }
        
        .naccs {
            position: relative;
        }
        
        
        .naccs .menu div {
            padding: 10px 20px 10px 20px;
            margin-bottom: 2px;
            color: #545454;
            background: #ddd;
            cursor: pointer;
            position: relative;
            vertical-align: middle;
            font-weight: 400;
            transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        
        .naccs .menu div:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        
        .naccs .menu div span.light {
            height: 10px;
            width: 10px;
            position: absolute;
            top: 24px;
            left: 15px;
            background-color: none;
            border-radius: 100%;
        
        }
        
        .naccs .menu div.active span.light {
            background-color: #333;
            left: 0;
            height: 100%;
            width: 3px;
            top: 0;
            border-radius: 0;
        }
        
        .naccs .menu div.active {
            color: #fff;
            background-color: #333;
            padding: 10px 20px 10px 20px;
        }
        
        
        ul.nacc {
            position: relative;
            height: 0px;
            list-style: none;
            margin: 0;
            padding: 0;
            transition: .5s all cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        
        ul.nacc li {
            opacity: 0;
            transform: translateX(50px);
            position: absolute;
            list-style: none;
            transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
            padding: 20px
        }
        
        ul.nacc li.active {
            transition-delay: .3s;
            z-index: 2;
            opacity: 1;
            transform: translateX(0px);
            background: #fff;
            width: 100%;
        }
        
        ul.nacc li p {
            margin: 0;
        }
        
        .naccs .chat_room {
            width: 100%;
            padding: 0;
        }
        
        .naccs .chat_windows .message {
            display: inline-block;
            vertical-align: middle;
            position: relative;
        }
        
        .naccs .chat_windows .message p {
            -webkit-border-radius: 20px 20px 20px 0;
            -moz-border-radius: 20px 20px 20px 0;
            border-radius: 20px 20px 20px 0;
            width: max-content;
            padding: 12px 15px;
        }
        
        .naccs .chat_windows .message .time {
            display: block;
            vertical-align: middle;
            color: #999;
        
        }
        
        .naccs .chat_windows .chat-message {
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            padding: 20px 10px;
            border: 1px solid #E8E9E9;
            margin-top: 30px;
        }
        
        .naccs .chat_windows .chat-message>a {
            padding: 10px 15px;
        }
        
        .naccs .ItemArea {
            background: #fff;
            margin-bottom: 20px;
        }
        
        .custom-control {
            font-size: 14px;
            color: #545454;
            line-height: 1.6;
        }
        
        
        
        /* í° css */
        .phone02 {
            width: 260px;
            height: 500px;
            border-radius: 28px;
            margin: 0 auto;
            position: relative;
            border: 8px solid #fff;
            box-shadow: 0 0 5px rgb(0 0 0 / 30%);
            background: #fff;
            overflow: hidden;
        }
        
        .phone02 .phone_over {
            border-radius: 20px;
            height: 480px;
            background: #fff;
            border: 1px solid #eee;
            overflow: hidden;
        }
        
        .drop-shadow {
            position: relative;
            padding: 10px;
            border: none;
            background: #fff;
            -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
        }
        
        .drop-shadow:before,
        .drop-shadow:after {
            content: "";
            position: absolute;
            z-index: -2;
        }
        
        /* ì•„ì´íŒ¨ë“œ */
        .ipad-wrapper {
            border: 2px solid #aaa;
            width: 100%;
            max-width: 800px;
            margin: auto;
            border-radius: 22px;
            position: relative;
            margin-top: 20px;
        }
        
        .ipad-wrapper::before {
            content: '';
            display: block;
            position: absolute;
            top: -3px;
            width: 15px;
            height: 2px;
            background-color: #ccc;
        }
        
        .ipad-wrapper::after {
            content: '';
            display: block;
            position: absolute;
            top: -3px;
            width: 15px;
            height: 2px;
            background-color: #ccc;
        }
        
        .ipad-wrapper::before {
            left: 30px;
        }
        
        .ipad-wrapper::after {
            left: 50px;
        }
        
        .ipad {
            width: 100%;
            background-color: white;
            border: 10px solid #f3f3f3;
            position: relative;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
            box-sizing: border-box;
            border-radius: 20px;
        
        }
        
        .ipad::after {
            content: '';
            display: block;
            position: absolute;
            height: 2px;
            border-radius: 10000px;
            width: 100px;
            transform: translateX(-50%);
            left: 50%;
            bottom: 4px;
            z-index: 10;
            background-color: rgba(255, 255, 255, 0.5);
        }
        
        /* .PcArea {
            border-radius: 10px;
            overflow: hidden;
        } */
        
        /* .PcBg {
            background-color: #cfd9e9;
        }
        
        .TalkPcTop img {
            width: 100%;
            height: auto;
        } */
        
        /* .PcBg .phone_over {
            height: 400px;
            width: 300px;
            margin: 0 auto;
            overflow-y: auto;
        } */
        
        /* .PcBg .PhoneArea {
            background: #cfd9e9;
            position: relative;
        } */
        
        /* .PcBg .PhoneArea .TackBtnArea {
            position: absolute;
            bottom: 0;
            margin-bottom: -40px;
            right: 10px;
            z-index: 99;
        } */
        
        /* .PcBg .PhoneArea .TackBtnArea img {
            border: 4px solid #333;
            vertical-align: middle;
        } */
        
        /* .PcBg .PhoneArea .TackBtnArea span {
            background: #333;
            color: #fff;
            font-size: 11px;
            border-radius: 10px 0 0 10px;
            padding: 2px 4px;
            margin-right: -4px;
        }
        
        .MainImgBg {
            background-color: #fff;
            border-radius: 10px;
            padding: 10px;
        }
        
        .PcBg .PhoneTop {
            background: #cfd9e9;
            padding: 10px;
            position: relative;
        }
        
        .PcBg .PhoneBody {
            background: #cfd9e9;
            padding: 10px;
            position: relative;
            height: auto;
        } */
        
        /* ëžœë”©íŽ˜ì´ì§€ ì±„íŒ… ë°°ê²½ìƒ‰ ì„¤ì • */
        .imagecheck-figure {
            border: none;
        }
        
        .CheckColor01 {
            width: 60px;
            height: 60px;
            background: #ffffff;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
            border-radius: 20px !important;
        }
        
        .CheckColor02 {
            width: 60px;
            height: 60px;
            background: #4eac6d;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
            border-radius: 20px !important;
        }
        
        .CheckColor03 {
            width: 60px;
            height: 60px;
            background: #50a5f1;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
            border-radius: 20px !important;
        }
        
        .CheckColor04 {
            width: 60px;
            height: 60px;
            background: #6153cc;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
            border-radius: 20px !important;
        }
        
        .CheckColor05 {
            width: 60px;
            height: 60px;
            background: #e83e8c;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
            border-radius: 20px !important;
        }
        
        .CheckColor06 {
            width: 60px;
            height: 60px;
            background: #797c8c;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
            border-radius: 20px !important;
        }
        
        .imagecheck:hover .imagecheck-image,
        .imagecheck-input:checked~.imagecheck-figure .imagecheck-image {
            border: 2px solid #333;
        }
        
        .imagecheck-figure:before {
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            display: block;
            vertical-align: middle;
        
            content: '\f14a';
            font-family: 'FontAwesome';
            position: absolute;
            top: 20px;
            left: 24px;
            width: 1rem;
            height: 1rem;
            pointer-events: none;
            user-select: none;
            color: #2185d0;
            z-index: 1;
            opacity: 0;
            transition: .3s opacity;
        }
        
        .PhoneArea {
            background: #787878;
            position: relative;
            height: 480px;
        }
        
        .PhoneArea .TackBtnArea {
            position: absolute;
            bottom: 36px;
            right: 10px;
            z-index: 99;
        }
        
        .PhoneArea .TackBtnArea img {
            border: 4px solid #333;
            vertical-align: middle;
        }
        
        .PhoneArea .TackBtnArea span {
            background: #333;
            color: #fff;
            font-size: 11px;
            border-radius: 10px 0 0 10px;
            padding: 2px 4px;
            margin-right: -4px;
        }
        
        .PhoneTop {
            background: #fff;
            padding: 20px;
            position: relative;
        }
        
        .PhoneTop small {
            color: #333;
        }
        
        .PhoneTop small img {
            width: 12px;
            display: inline-block;
        }
        
        .PhoneTop i {
            color: #333;
            font-size: 100%;
        }
        
        .PhoneBody {
            background: #cfd9e9;
            padding: 20px;
            position: relative;
            height: 100vh;
        }
        
        .PhoneBody .tabArea .tag-rounded {
            background: #fff !important;
            color: #999999;
            font-size: 11px;
        }
        
        .PhoneBody .tabArea .active {
            color: #000;
            font-weight: 900;
        }
        
        .PhoneBody .conArea .txtArea {
            background: #fff;
            border-radius: 10px;
            padding: 5px 10px;
            font-size: 10px;
            height: 80px;
            overflow-y: auto;
            color: #545454;
        }
        
        .PhoneBody .conArea .adressArea1 {
            background: #fff;
            border-radius: 10px;
            padding: 5px 10px;
            font-size: 10px;
            margin-top: 10px;
        }
        
        .PhoneBody .conArea .adressArea2 {
            background: #fff;
            border-radius: 10px;
            padding: 5px 10px;
            font-size: 10px;
            margin-top: 10px;
        }
        
        .PhoneBody .conArea .txtArea2 {
            background: #fff;
            border-radius: 10px;
            padding: 10px 10px;
            font-size: 10px;
            height: auto;
            color: #545454;
        }
        
        .PhoneBody .conArea .answerInfo {
            background: #fff;
            border-radius: 10px;
            padding: 10px 10px;
            font-size: 10px;
            margin-top: 10px;
        }
        
        .PhoneBody .answerInfo .tag-rounded {
            background: #fff !important;
            color: #333;
            font-size: 11px;
            border: 1px solid #ddd;
        }
        
        .PhoneBody .answerInfo small {
            font-weight: 800;
        }
        
        .PhoneBody .conArea .footlogo {
            text-align: center;
            padding: 5px 0;
        }
        
        .PhoneBody .conArea .footlogo img {
            width: 70px;
        }
        
        .MainImg {
            position: relative;
        }
        
        .MainImg .ChArea {
            position: absolute;
            width: 40px;
            bottom: 0;
            left: 10px;
        }
        
        .PhoneTop .ChArea {
            position: absolute;
            width: 40px;
            bottom: 0;
            right: 10px;
        }
        
        
        .textColor {
            line-height: 55px;
        }
        
        
        
        .whiteBg .PhoneTop {
            background: #fff
        }
        
        .whiteBg .PhoneBody {
            background: #cfd9e9 !important;
        }
        
        /* .PcBg .greenBg .PhoneTop {
            background: #cfd9e9 !important;
        }
        
        .PcBg .whiteBg .PhoneBody {
            background: #cfd9e9 !important;
        }
        
        .whiteBg .PcBg {
            background: #cfd9e9;
        }
        
        .PcBg .whiteBg .PhoneTop {
            background: #cfd9e9 !important;
        } */
        
        .whiteBg .MainImgBg {
            background: #fff;
        }
        
        
        
        
        .greenBg .PhoneTop {
            background: #4eac6d !important;
        }
        
        .greenBg .PhoneBody {
            background: #e2ebe5 !important;
        }
        
        .greenBg .TackBtnArea img {
            border: 4px solid #4eac6d !important;
        }
        
        .greenBg .TackBtnArea span {
            background: #4eac6d !important;
        }
        
        .greenBg .TackBtnArea img {
            border: 4px solid #4eac6d !important;
        }
        
        .greenBg .TackBtnArea span {
            background: #4eac6d !important;
        }
        
        .greenBg .PhoneTop small {
            color: #fff;
        }
        
        .greenBg .PhoneTop i {
            color: #fff;
        }
        
        /* .greenBg .PcBg {
            background: #e2ebe5;
        }
        
        .PcBg .greenBg .PhoneTop {
            background: #e2ebe5 !important;
        }
        
        .greenBg .MainImgBg {
            background: #4eac6d;
            color: #fff !important;
        }
        
        .PcBg .greenBg .PhoneTop small {
            color: #333;
        }
        
        .PcBg .greenBg .PhoneTop i {
            color: #333;
        }
        
        .PcBg .greenBg .PhoneBody .conArea .adressArea1 {
            background: #4eac6d;
            color: #fff !important;
        } */
        
        
        
        
        /* .blueBg .PhoneTop {
            background: #50a5f1 !important;
        } */
        
        .blueBg .PhoneBody {
            background: #e2eaf2 !important;
        }
        
        .blueBg .TackBtnArea img {
            border: 4px solid #50a5f1 !important;
        }
        
        .blueBg .TackBtnArea span {
            background: #50a5f1 !important;
        }
        
        .blueBg .TackBtnArea img {
            border: 4px solid #50a5f1 !important;
        }
        
        .blueBg .TackBtnArea span {
            background: #50a5f1 !important;
        }
        
        .blueBg .PhoneTop small {
            color: #fff
        }
        
        .blueBg .PhoneTop i {
            color: #fff
        }
        
        /* .blueBg .PcBg {
            background: #e2eaf2;
        }
        
        .PcBg .blueBg .PhoneTop {
            background: #e2eaf2 !important;
        }
        
        .blueBg .MainImgBg {
            background: #50a5f1;
            color: #fff !important;
        }
        
        .PcBg .blueBg .PhoneTop small {
            color: #333;
        }
        
        .PcBg .blueBg .PhoneTop i {
            color: #333;
        }
        
        .PcBg .blueBg .PhoneBody .conArea .adressArea1 {
            background: #50a5f1;
            color: #fff !important;
        } */
        
        
        .purpleBg .PhoneTop {
            background: #6153cc !important;
        }
        
        .purpleBg .PhoneBody {
            background: #e4e2ee !important;
        }
        
        .purpleBg .TackBtnArea img {
            border: 4px solid #6153cc !important;
        }
        
        .purpleBg .TackBtnArea span {
            background: #6153cc !important;
        }
        
        .purpleBg .TackBtnArea img {
            border: 4px solid #6153cc !important;
        }
        
        .purpleBg .TackBtnArea span {
            background: #6153cc !important;
        }
        
        .purpleBg .PhoneTop small {
            color: #fff;
        }
        
        .purpleBg .PhoneTop i {
            color: #fff;
        }
        
        /* .purpleBg .PcBg {
            background: #e4e2ee;
        }
        
        .PcBg .purpleBg .PhoneTop {
            background: #e4e2ee !important;
        }
        
        .purpleBg .MainImgBg {
            background: #6153cc;
            color: #fff !important;
        }
        
        .PcBg .purpleBg .PhoneTop small {
            color: #333;
        }
        
        .PcBg .purpleBg .PhoneTop i {
            color: #333;
        }
        
        .PcBg .purpleBg .PhoneBody .conArea .adressArea1 {
            background: #6153cc;
            color: #fff !important;
        } */
        
        .pinkBg .PhoneTop {
            background: #e83e8c !important;
        }
        
        .pinkBg .PhoneBody {
            background: #f1e0e8 !important;
        }
        
        .pinkBg .TackBtnArea img {
            border: 4px solid #e83e8c !important;
        }
        
        .pinkBg .TackBtnArea span {
            background: #e83e8c !important;
        }
        
        .pinkBg .TackBtnArea img {
            border: 4px solid #e83e8c !important;
        }
        
        .pinkBg .TackBtnArea span {
            background: #e83e8c !important;
        }
        
        .pinkBg .PhoneTop small {
            color: #fff;
        }
        
        .pinkBg .PhoneTop i {
            color: #fff;
        }
        
        /* .pinkBg .PcBg {
            background: #f1e0e8;
        }
        
        .PcBg .pinkBg .PhoneTop {
            background: #f1e0e8 !important;
        }
        
        .pinkBg .MainImgBg {
            background: #e83e8c;
            color: #fff !important;
        }
        
        .PcBg .pinkBg .PhoneTop small {
            color: #333;
        }
        
        .PcBg .pinkBg .PhoneTop i {
            color: #333;
        }
        
        .PcBg .pinkBg .PhoneBody .conArea .adressArea1 {
            background: #e83e8c;
            color: #fff !important;
        } */
        
        .grayBg .PhoneTop {
            background: #797c8c !important;
        }
        
        .grayBg .PhoneBody {
            background: #e6e6e8 !important;
        }
        
        .grayBg .TackBtnArea img {
            border: 4px solid #797c8c !important;
        }
        
        .grayBg .TackBtnArea span {
            background: #797c8c !important;
        }
        
        .grayBg .TackBtnArea img {
            border: 4px solid #797c8c !important;
        }
        
        .grayBg .TackBtnArea span {
            background: #797c8c !important;
        }
        
        .grayBg .PhoneTop small {
            color: #fff;
        }
        
        .grayBg .PhoneTop i {
            color: #fff;
        }
        
        /* .grayBg .PcBg {
            background: #e6e6e8;
        }
        
        .PcBg .grayBg .PhoneTop {
            background: #e6e6e8 !important;
        }
        
        .grayBg .MainImgBg {
            background: #797c8c;
            color: #fff !important;
        }
        
        .PcBg .grayBg .PhoneTop small {
            color: #333;
        }
        
        .PcBg .grayBg .PhoneTop i {
            color: #333;
        }
        
        .PcBg .grayBg .PhoneBody .conArea .adressArea1 {
            background: #797c8c;
            color: #fff !important;
        } */
        
        /* ì›í•˜ëŠ” ì£¼ì†Œ ìž…ë ¥ */
        .BasicAdress {
            color: #999;
            line-height: 32px;
            margin-right: 10px;
        }
        
        .custom-radio .custom-control-input:checked~.custom-control-label {
            font-weight: bold;
        }
        
        @media (max-width: 768px) {
            #right-sidebar {
                width: 100% !important;
                margin-right: -100%;
                margin-top: 55px;
                height: 100vh;
            }
        
            .viewCon .ReaultArea {
                height: 66vh;
            }
        
            .sectionRight {
                margin-right: 0 !important;
            }
        
            .listInfo01 {
                margin-right: 20px;
            }
        
            .listimg {
                width: 100vw;
                max-width: 200px;
            }
        
            .listimg img {
                height: 160px;
            }
        
            .listInfo01,
            .listInfo02 {
                width: 100%;
                display: block;
                margin-top: 5px;
                color: #545454;
            }
        
            .listTxtTit {
                position: unset;
            }
        
            .listTxtTit span {
                display: block;
                right: 20px;
                bottom: 20px;
            }
        
            .mainFlex {
                display: block !important;
            }
        
            .user_btn {
                margin-top: 0;
            }
        
            #left-sidebar>.brand_name .brand-name {
                margin-top: 10px;
            }
        
            .page-title {
                margin-top: 10px;
            }
        
            .media-foot {
                padding: 15px 0;
                padding-bottom: 0;
                margin: 0;
                border-left: none;
                margin-top: 20px;
                background: none;
                border-top: 1px dashed #ddd !important;
                width: 100%;
            }
        
            .quickMenuArea {
                margin-right: 0;
            }
        
            .graphArea {
                margin-right: 0;
            }
        
            .dashboard500 {
                margin-right: 0;
            }
        
            .carouselItemArea img {
                height: 132px;
            }
        
            .table.table_custom tr th:last-child,
            .table.table_custom tr td:last-child {
                border-radius: 0;
            }
        
            .table.table_custom tr th:first-child,
            .table.table_custom tr td:first-child {
                border-radius: 0;
            }
        
            .grid {
                margin: 0;
            }
        
            .gc--1-of-2 {
                width: 100%;
                padding: 0
            }
        
            .gc--2-of-4 {
                width: 100%;
                padding: 0
            }
        
            .border-right {
                border: none !important;
            }
        
            .phone02 {
                margin-bottom: 20px;
            }
        
            .ipad-wrapper {
                margin-top: 0;
            }
        
            .naccs .chat_windows .message p {
                width: auto;
            }
        
            .mobileArea {
                display: block !important;
            }
            
        }
        .noboard{border: none !important;}
        .noboard:hover{box-shadow: none;}
        .login-ch-area{width: 100%; text-align: center; padding: 40px 0;}
        .login-ch-area img{width: 100%; max-width: 217px;}
        .form-round-height{border-radius:10px ; font-size: 14px !important; height: 42px !important;}
        .btn-same-size{font-size: 14px; border-radius: 10px; width: 100px !important; padding: 0; letter-spacing: -1px;}
        .btn-login{width: 142px; font-size: 14px !important; margin: 0 auto; border-radius: 10px !important; padding: 10px 20px !important;}
        .nav .font-weight-bold{color: #01a0fc;}
        #chat-sidebar{border-radius: 20px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);}
        .sidebar-round{border-radius: 20px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);}
        .input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child), .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text{
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }